// @TODO: may eventually want to break this out into multiple utility files...like a utilities/ folder with files inside
/**
 * Ryan O'Dowd
 * 2018-04-11
 * © Copyright 2018 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import Globals, {
  firebase,
  firestore,
  platform,
} from '../Globals';
import {
  add as dateFnsAdd,
  format as dateFnsFormat,
} from 'date-fns';
import {
  deleteNotesForPerson,
  setLabels,
  setLastNotesSyncTimestamp,
  setNameTagNotes,
  setNameTags,
  setNotes,
  setPendingPeople,
  setPendingPersonNotes,
  setPeople,
  setPersonTidbits,
  setReminders,
  setTidbits,
  setUser,
} from '../actions';
import {
  PLAN_LIMITS,
} from '../containers/ManageSubscription/Globals';
import commonUtilities from '../common/utilities';
import importedUtilities from '../importedUtilities';
import oakGlobalObject from '../common/utilities/oakGlobalObject';
import platformUtilities from './platformUtilities';
import {
  store,
} from '../Store';

const PERIODIC_REMINDER_HOURS_OFFSET = 72; // @TODO: move to global

function savePlatformActivity() {
  const globalReduxStore = store.getState();

  const platformActivity = ((pf) => {
    switch (pf) {
    case 'ios':
      return {
        lastActiveIos: new Date().getTime(),
        lastActiveIosAppVersion: Globals.appVersionNumber,
      };
    case 'android':
      return {
        lastActiveAndroid: new Date().getTime(),
        lastActiveAndroidAppVersion: Globals.appVersionNumber,
      };
    case 'web':
      return {
        lastActiveWeb: new Date().getTime(),
        lastActiveWebAppVersion: Globals.appVersionNumber,
      };
    default:
      return {
        lastActiveUnknown: new Date().getTime(),
        lastActiveUnknownAppVersion: Globals.appVersionNumber,
      };
    }
  })(platform);
  // set email and displayName to what's in redux if the user object doesn't have it...this could be a login via email/phone auth
  const email = globalReduxStore.user?.email || globalReduxStore.signInInfo.email;
  const displayName = globalReduxStore.user?.displayName || `${globalReduxStore.signInInfo.firstName || ''} ${globalReduxStore.signInInfo.lastName || ''}`;
  const updatedUserDoc = {
    ...platformActivity,
    photoURL: globalReduxStore.user?.photoURL || '',
  };
  if (email) {
    updatedUserDoc.email = email;
  }
  if (displayName.trim()) {
    updatedUserDoc.displayName = displayName;
  }
  firestore.userDoc.set(updatedUserDoc, {merge: true});
}

function subscribeToUser() {
  return firestore.userDoc.onSnapshot((qs) => {
    if (!qs) {
      return;
    }

    const userDocData = qs.data();
    store.dispatch(setUser({
      ...userDocData,
      key: qs.id,
      uid: qs.id,
    }));
    if (['ios', 'android'].includes(platform)) {
      firebase.crashlytics().setAttribute('name', userDocData?.displayName || '<unknown>');
    }
  });
}

function subscribeToReminders() {
  const globalReduxStore = store.getState();
  return firestore.remindersCollection.onSnapshot((qs) => {
    const reminders = [];
    qs?.forEach((reminderDoc) => reminders.push({...reminderDoc.data(), key: reminderDoc.id}));
    /* @TODO:
    const notes = [].concat(...(this.props.notes.map(notesKeyedByPerson => { // @TODO: can't get notes this way...this.props.notes might not be ready yet
      return notesKeyedByPerson.notes.map(personNote => {
        return {
          ...personNote,
          personKey: notesKeyedByPerson.personKey,
        };
      });
    }).filter(x => x.length)));

    PushNotification.getScheduledLocalNotifications(notifications => {
      notifications?.forEach(notification => {
        if (notification.id.startsWith('noteReminder_')) { // @TODO: enum
          PushNotification.cancelLocalNotification(notification.id);
        }
      });
    });
    */

    if (globalReduxStore.settingsPeriodicReminderIsEnabled) {
      // @TODO: there's probably a better way to do this, but for now we're scheduling on every app open (but we're canceling first, so UX is okay, it's just weird to always schedule here i think)
      platformUtilities.schedulePeriodicReminders(dateFnsAdd(new Date(), {hours: PERIODIC_REMINDER_HOURS_OFFSET}));
    }

    const starredSessions = [];
    /* @TODO: set reminders for starred sessions
    Object.values(globalReduxStore.events)?.forEach((e) => {
      e.sessions?.forEach((s) => {
        if (e.starredSessionIds?.includes(s.id)) {
          starredSessions.push({
            sessionName: s.name,
            locationName: e.locations.filter((l) => s.locationIds?.includes(l.id)).map((l) => l.name).join(', '),
            startTimestamp: s.startTimestamp,
          });
        }
      });
    });
    */
    platformUtilities.scheduleAndSaveNoteReminders(qs, globalReduxStore.notes2, globalReduxStore.tidbits, globalReduxStore.personTidbits, globalReduxStore.people, starredSessions);

    store.dispatch(setReminders(reminders));
  });
}

function createReminder(key, initialDate, initialTime, initialInterval, isTidbit) {
  const nextWeek = dateFnsAdd(new Date(), {days: 7});
  firestore.remindersCollection.add({
    [isTidbit ? 'tidbitKey' : 'noteKey']: key,
    createdTimestamp: new Date().getTime(),
    isActive: true,
    startDate: initialDate || dateFnsFormat(nextWeek, Globals.FIREBASE_DATE_FORMAT),
    time: initialTime || dateFnsFormat(nextWeek, Globals.FIREBASE_TIME_FORMAT),
    repeatInterval: initialInterval || 'never',
  });
  firebase.analytics().logEvent('create_reminder');
}

function updateReminder(reminderKey, attributes) {
  firestore.remindersCollection.doc(reminderKey).update(attributes);
  firebase.analytics().logEvent('update_reminder', {
    attributes: Object.keys(attributes),
  });
}

function deleteReminder(reminderKey) {
  firestore.remindersCollection.doc(reminderKey).delete();
  firebase.analytics().logEvent('delete_reminder', {
    numNotes: 1,
  });
}

function subscribeToLabels() {
  return firestore.labelsCollection.onSnapshot((qs) => {
    const labels = [];
    qs?.forEach((labelDoc) => labels.push({...labelDoc.data(), key: labelDoc.id}));
    store.dispatch(setLabels(labels));

    // @TODO: only do this if labels is new (right now happening on every check/uncheck for a person): but maybe solution is that creating backgroundgeofence should work only on enter and not "enter or i'm running for the first time and am already at this place"
    platformUtilities.subscribeToLabelsCallback(labels);
  });
}

function createLabel(labelValue) {
  const newLabelKey = firestore.labelsCollection.doc().id;
  firestore.labelsCollection.doc(newLabelKey).set({
    value: labelValue || '',
    createdTimestamp: new Date().getTime(),
    lastUsedTimestamp: new Date().getTime(),
  });
  firebase.analytics().logEvent('create_label');

  return newLabelKey;
}

function updateLabel(labelKey, attributes) {
  firestore.labelsCollection.doc(labelKey).update(attributes);
  firebase.analytics().logEvent('update_label', {
    attributes: Object.keys(attributes),
  });
}

function deleteLabel(labelKey) {
  firestore.labelsCollection.doc(labelKey).delete();

  // delete from each person's labels if exists
  firestore.peopleCollection.get().then((querySnapshot) => {
    const batch = firebase.firestore().batch();
    querySnapshot?.forEach((personDoc) => {
      if (personDoc.data().labelIds) {
        const newLabelIds = personDoc.data().labelIds.filter((lid) => lid !== labelKey);
        batch.update(personDoc.ref, {
          labelIds: newLabelIds,
        });
      }
    });
    batch.commit();
  });

  firebase.analytics().logEvent('delete_label');
}

function subscribeToTidbits() {
  return firestore.tidbitsCollection.onSnapshot((qs) => {
    const tidbits = [];
    qs?.forEach((tidbitDoc) => tidbits.push({...tidbitDoc.data(), key: tidbitDoc.id}));
    store.dispatch(setTidbits(tidbits));
  });
}

function createTidbit(tidbitName) {
  const newTidbitKey = firestore.tidbitsCollection.doc().id;
  firestore.tidbitsCollection.doc(newTidbitKey).set({
    value: tidbitName,
    createdTimestamp: new Date().getTime(),
  });
  firebase.analytics().logEvent('create_tidbit');

  return newTidbitKey;
}

function updateTidbit(tidbitKey, attributes) {
  firestore.tidbitsCollection.doc(tidbitKey).update(attributes);
  firebase.analytics().logEvent('update_tidbit', {
    attributes: Object.keys(attributes),
  });
}

function deleteTidbit(tidbitKey) {
  const batch = firebase.firestore().batch();
  batch.delete(firestore.tidbitsCollection.doc(tidbitKey));

  // delete this from individual people
  const globalReduxStore = store.getState();
  Object.entries(globalReduxStore.personTidbits)?.forEach(([personKey, personTidbits]) => {
    Object.values(personTidbits)?.forEach((personField) => {
      if (personField.tidbitKey === tidbitKey) {
        // this is the field being deleted
        batch.delete(firestore.peopleCollection.doc(personKey).collection('tidbits').doc(personField.key));
        // also delete reminder for this tidbit if it exists
        const reminderKey = globalReduxStore.reminders[personField.key]?.key;
        if (reminderKey) {
          batch.delete(firestore.remindersCollection.doc(reminderKey));
        }
        // update timestamp for person so that details are refetched
        batch.update(firestore.peopleCollection.doc(personKey), {
          lastUpdatedTimestamp: new Date().getTime(),
        });
      }
    });
  });
  batch.commit();

  firebase.analytics().logEvent('specific_field_deleted');
}

function subscribeToPeople() {
  const globalReduxStore = store.getState();

  return firestore.peopleCollection.onSnapshot((qs) => { // @TODO: can we subscribe only to active people? would that help performance and not hurt functionality? we could do a get on the archived people
    const people = [];
    qs?.forEach((personDoc) => {
      people.push({...personDoc.data(), key: personDoc.id});

      // fetch notes only if the person has been updated since the last time we synced (so we'll fetch all on initial load and then only when the person is modified after that)
      const lastTimeNotesWereSyncedForThisPerson = globalReduxStore.lastNotesSyncTimestamps2[personDoc.id] || 0;
      if (lastTimeNotesWereSyncedForThisPerson < personDoc.data().lastUpdatedTimestamp) {
        _fetchNotesForPerson(personDoc.id);
      }
    });

    // remove notes for people who have been deleted
    const peopleKeysInRedux = globalReduxStore.people.map((p) => p.key);
    const peopleKeysInFirestore = people.map((p) => p.key);
    const peopleKeysToDelete = peopleKeysInRedux.filter((pk) => !peopleKeysInFirestore.includes(pk));
    peopleKeysToDelete?.forEach((pk) => store.dispatch(deleteNotesForPerson(pk)));

    store.dispatch(setPeople(people));
  });
}

async function _fetchNotesForPerson(personKey) {
  const notesSnapshot = await firestore.userDoc.collection(`people/${personKey}/notes`).get();
  const notes = [];
  notesSnapshot?.forEach((doc) => {
    notes.push({...doc.data(), key: doc.id});
  });
  store.dispatch(setNotes(notes, personKey));

  const personTidbitsSnapshot = await firestore.userDoc.collection(`people/${personKey}/tidbits`).get();
  const personTidbits = {};
  personTidbitsSnapshot?.forEach((doc) => {
    personTidbits[doc.id] = {...doc.data(), key: doc.id};
  });
  store.dispatch(setPersonTidbits(personTidbits, personKey));

  store.dispatch(setLastNotesSyncTimestamp(personKey));
}

function createPerson() {
  const newPersonKey = firestore.peopleCollection.doc().id;
  firestore.peopleCollection.doc(newPersonKey).set({
    createdTimestamp: new Date().getTime(),
    lastUpdatedTimestamp: new Date().getTime(),
    isActive: true,
    isPinned: false,
    labelIds: [],
    name: '',
    activeNotesCount: 0,
  });
  firebase.analytics().logEvent('create_person');

  return newPersonKey;
}

function deletePerson(personKey) {
  firestore.peopleCollection.doc(personKey).delete();
  firebase.analytics().logEvent('delete_people', {
    numPeople: 1,
  });
}

function searchFilteredPeople(people, allNotes, allLabels, selectedLabelIds, searchText) {
  if (!searchText && !selectedLabelIds.length) {
    return people;
  }

  searchText = searchText ? searchText.toLocaleLowerCase() : '';
  const searchTerms = searchText ? searchText.split(' ') : [];

  return people.filter((person) => {
    // filter on labels
    if (selectedLabelIds.length && !selectedLabelIds.every((lid) => person.labelIds.includes(lid))) {
      return false;
    }

    return !searchTerms.length || searchTerms.every((searchTerm) => {
      // filter on name
      const personName = person.name ? person.name.toLocaleLowerCase() : ''; // @TODO: https://githubmate.com/repo/facebook/hermes/issues/582
      if (personName.includes(searchTerm)) {
        return true;
      }

      // filter on notes
      if (allNotes[person.key]?.filter((n) => (n.value ? n.value.replace('’', "'").toLocaleLowerCase() : '').includes(searchTerm)).length > 0) { // @TODO: https://githubmate.com/repo/facebook/hermes/issues/582
        return true;
      }

      // filter on label
      const matchingLabelIds = allLabels.filter((l) => (l.value ? l.value.replace('’', "'").toLocaleLowerCase() : '').includes(searchTerm)).map((l) => l.key); // @TODO: https://githubmate.com/repo/facebook/hermes/issues/582
      if (person.labelIds.find((lid) => matchingLabelIds.includes(lid))) {
        return true;
      }

      return false;
    });
  });
}

function sortPeople(sortMethod, a, b) {
  switch (sortMethod) {
  case 'dateModified': {
    return b.lastUpdatedTimestamp - a.lastUpdatedTimestamp;
  }
  case 'alphabetically': {
    // @TODO: https://githubmate.com/repo/facebook/hermes/issues/582
    if (!a.name) {
      return -1;
    } else if (!b.name) {
      return 1;
    }
    return a.name?.localeCompare(b.name, {sensitivity: 'base'}) || -1;
  }
  case 'dateCreated': {
    return b.createdTimestamp - a.createdTimestamp;
  }
  default:
    // @TODO: commented out because of web for now: console.error(firebase.crashlytics().recordError(`no sort method \`${sortMethod}\``));
    return b.lastUpdatedTimestamp - a.lastUpdatedTimestamp;
  }
}

function createNote(personKey, noteText, index) {
  const newNoteKey = firestore.peopleCollection.doc(personKey).collection('notes').doc().id;
  firestore.peopleCollection.doc(personKey).collection('notes').doc(newNoteKey).set({
    createdTimestamp: new Date().getTime(),
    value: noteText,
    isActive: true,
    index,
  });
  // update lastUpdatedTimestamp for this person
  firestore.peopleCollection.doc(personKey).update({
    lastUpdatedTimestamp: new Date().getTime(),
  });
  firebase.analytics().logEvent('create_note', {
    noteLength: noteText.length,
  });

  return newNoteKey;
}

function updateNote(personKey, noteKey, attributes) {
  const batch = firebase.firestore().batch();
  batch.update(firestore.peopleCollection.doc(personKey).collection('notes').doc(noteKey), attributes);
  // make sure reminder is not active
  if (Object.keys(attributes).includes('isActive')) {
    const globalReduxStore = store.getState();
    const reminder = globalReduxStore.reminders[noteKey];
    if (reminder) {
      batch.update(firestore.remindersCollection.doc(reminder.key), {
        isActive: false,
      });
    }
  }
  // update lastUpdatedTimestamp for this person
  batch.update(firestore.peopleCollection.doc(personKey), {
    lastUpdatedTimestamp: new Date().getTime(),
  });
  batch.commit();
  firebase.analytics().logEvent('update_note', {
    attributes: Object.keys(attributes),
  });
}

function reorderNotes(personKey, dragData) {
  const batch = firebase.firestore().batch();
  dragData?.forEach((note, i) => {
    batch.update(firestore.peopleCollection.doc(personKey).collection('notes').doc(note.key), {index: dragData.length - i - 1});
  });

  // update lastUpdatedTimestamp for this person
  batch.update(firestore.peopleCollection.doc(personKey), {
    lastUpdatedTimestamp: new Date().getTime(),
  });
  batch.commit();
  firebase.analytics().logEvent('reorder_notes');
}

function deleteNote(personKey, noteKey) {
  const batch = firebase.firestore().batch();
  batch.delete(firestore.peopleCollection.doc(personKey).collection('notes').doc(noteKey));
  // delete reminder for this note if it exists
  firestore.remindersCollection.where('noteKey', '==', noteKey).get().then((qs) => { // @TODO: this can be done via redux instead of querying firestore
    qs.forEach((doc) => {
      // @NOTE: this isn't part of the batch because it's async, so the batch is exectued before this is added
      firestore.remindersCollection.doc(doc.id).delete();
    });
  });
  // update lastUpdatedTimestamp for this person
  batch.update(firestore.peopleCollection.doc(personKey), {
    lastUpdatedTimestamp: new Date().getTime(),
  });
  batch.commit();
  firebase.analytics().logEvent('delete_notes', {
    numNotes: 1,
  });
}

async function fetchPendingPeople() {
  const pendingPeopleSnapshot = await firestore.pendingPeopleCollection.get();

  const pendingPeople = [];
  pendingPeopleSnapshot?.forEach((pendingPersonDoc) => {
    pendingPeople.push({...pendingPersonDoc.data(), key: pendingPersonDoc.id});

    _fetchNotesForPendingPerson(pendingPersonDoc.id);
  });

  store.dispatch(setPendingPeople(pendingPeople));
}

function isPendingNameTagAlreadySaved(nameTagJson) {
  const globalReduxStore = store.getState();
  return !!(
    globalReduxStore.pendingPeople.map((p) => p.name).includes(nameTagJson.name) &&
    Object.values(globalReduxStore.pendingPeopleNotes).map((notes) => {
      return JSON.stringify(notes.map((n) => {
        return {value: n.value};
      }));
    })
  );
}

function createPendingPerson(name, photoUrl, notes) {
  const batch = firebase.firestore().batch();
  const newPendingPersonDoc = firestore.pendingPeopleCollection.doc();
  const newPerson = {
    createdTimestamp: new Date().getTime(),
    name,
  };
  if (photoUrl) {
    newPerson.photoUrl = photoUrl;
  }
  batch.set(newPendingPersonDoc, newPerson);
  (notes || []).forEach((note, i) => {
    const newNoteDoc = newPendingPersonDoc.collection('notes').doc();
    batch.set(newNoteDoc, {
      createdTimestamp: new Date().getTime(),
      value: note.value,
      index: note.index || (i + 1),
    });
  });
  firebase.analytics().logEvent('create_pending_person');
  batch.commit();

  fetchPendingPeople(); // @NOTE: must fetch again because we don't subscribe to this collection, so need to update redux manually
  return newPendingPersonDoc.key;
}

function deletePendingPerson(pendingPersonKey) {
  firestore.pendingPeopleCollection.doc(pendingPersonKey).delete();
  firebase.analytics().logEvent('delete_pendingPerson');
  fetchPendingPeople(); // @NOTE: must fetch again because we don't subscribe to this collection, so need to update redux manually
}

async function _fetchNotesForPendingPerson(pendingPersonKey) {
  const notesSnapshot = await firestore.userDoc.collection(`pendingPeople/${pendingPersonKey}/notes`).get();
  const notes = [];
  notesSnapshot?.forEach((doc) => {
    notes.push({...doc.data(), key: doc.id});
  });
  store.dispatch(setPendingPersonNotes(notes, pendingPersonKey));
}

function subscribeToNameTags() {
  return firestore.nameTagsCollection.onSnapshot((qs) => {
    const nameTags = [];
    qs?.forEach((nameTagDoc) => {
      nameTags.push({...nameTagDoc.data(), key: nameTagDoc.id});

      _fetchNotesForNameTag(nameTagDoc.id);
    });

    store.dispatch(setNameTags(nameTags));
  });
}

async function _fetchNotesForNameTag(nameTagKey) {
  const notesSnapshot = await firestore.userDoc.collection(`nameTags/${nameTagKey}/notes`).get();
  const notes = [];
  notesSnapshot?.forEach((doc) => {
    notes.push({...doc.data(), key: doc.id});
  });
  store.dispatch(setNameTagNotes(notes, nameTagKey));
}

function createNameTag() {
  const newNameTagKey = firestore.nameTagsCollection.doc().id;
  firestore.nameTagsCollection.doc(newNameTagKey).set({
    createdTimestamp: new Date().getTime(),
    lastUpdatedTimestamp: new Date().getTime(),
    name: '',
  });
  firebase.analytics().logEvent('create_name_tag');

  return newNameTagKey;
}

function deleteNameTag(nameTagKey) {
  firestore.nameTagsCollection.doc(nameTagKey).delete();
  firebase.analytics().logEvent('delete_nameTag');
}

function createNameTagNote(nameTagKey, noteText, index) {
  firestore.nameTagsCollection.doc(nameTagKey).collection('notes').add({
    createdTimestamp: new Date().getTime(),
    value: noteText,
    index,
  });
  // update lastUpdatedTimestamp for this name tag
  firestore.nameTagsCollection.doc(nameTagKey).update({
    lastUpdatedTimestamp: new Date().getTime(),
  });
  firebase.analytics().logEvent('create_name_tag_note', {
    noteLength: noteText.length,
  });
}

function updateNameTagNote(nameTagKey, noteKey, attributes) {
  const batch = firebase.firestore().batch();
  batch.update(firestore.nameTagsCollection.doc(nameTagKey).collection('notes').doc(noteKey), attributes);
  // make sure reminder is not active
  // update lastUpdatedTimestamp for this name tag
  batch.update(firestore.nameTagsCollection.doc(nameTagKey), {
    lastUpdatedTimestamp: new Date().getTime(),
  });
  batch.commit();
  firebase.analytics().logEvent('update_name_tag_note', {
    attributes: Object.keys(attributes),
  });
}

function reorderNameTagNotes(nameTagKey, dragData) {
  const batch = firebase.firestore().batch();
  dragData?.forEach((note, i) => {
    batch.update(firestore.nameTagsCollection.doc(nameTagKey).collection('notes').doc(note.key), {index: dragData.length - i - 1});
  });

  // update lastUpdatedTimestamp for this name tag
  batch.update(firestore.nameTagsCollection.doc(nameTagKey), {
    lastUpdatedTimestamp: new Date().getTime(),
  });
  batch.commit();
  firebase.analytics().logEvent('reorder_name_tag_notes');
}

function deleteNameTagNote(nameTagKey, noteKey) {
  const batch = firebase.firestore().batch();
  batch.delete(firestore.nameTagsCollection.doc(nameTagKey).collection('notes').doc(noteKey));
  // update lastUpdatedTimestamp for this name tag
  batch.update(firestore.nameTagsCollection.doc(nameTagKey), {
    lastUpdatedTimestamp: new Date().getTime(),
  });
  batch.commit();
  firebase.analytics().logEvent('delete_name_tag_notes', {
    numNotes: 1,
  });
}

async function exportAsJson() {
  const globalReduxStore = store.getState();
  const jsonToExport = {
    people: [],
  };
  globalReduxStore.people.filter((person) => person.isActive)?.forEach((person) => {
    jsonToExport.people.push({
      name: person.name,
      labels: globalReduxStore.labels.filter((label) => person.labelIds.includes(label.key)).map((label) => label.value),
      notes: globalReduxStore.notes2[person.key]?.map((personNotes) => {
        return personNotes.filter((personNote) => personNote.isActive).map((personNote) => personNote.value);
      }) || [],
    });
  });

  const filename = `tibbits_export_${dateFnsFormat(new Date(), 'yyyyMMdd')}.json`;
  const contentType = 'application/json;charset=utf-8;';
  const a = document.createElement('a');
  a.download = filename;
  a.href = `data:${contentType},${encodeURIComponent(JSON.stringify(jsonToExport, null, 2))}`;
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  return true;
}

async function exportLabelToCsv(labelKey) {
  const globalReduxStore = store.getState();
  const dataToExport = [];
  const labelName = globalReduxStore.labels.find((label) => label.key === labelKey)?.value || '';
  globalReduxStore.people.filter((person) => person.isActive && person.labelIds.includes(labelKey))?.forEach((person) => {
    dataToExport.push({
      name: person.name,
      labels: globalReduxStore.labels.filter((label) => person.labelIds.includes(label.key)).map((label) => label.value.replaceAll('’', "'")) || [],
      notes: globalReduxStore.notes2[person.key]?.filter((note) => note.isActive).map((note) => note.value.replaceAll(',', ';').replaceAll('\n', '.').replaceAll('’', "'")) || [], // @TODO: better solution than these replaces for commas and newlines
    });
  });
  const maxNotes = Math.max(...dataToExport.map((d) => d.notes.length));
  const notesHeaders = [];
  for (let i = 1; i <= maxNotes; i++) {
    notesHeaders.push(`Note ${i}`);
  }

  // @TODO: console.log(dataToExport);
  let csvData = dataToExport.map((d) => [d.name, d.labels.join('; '), ...d.notes]);
  csvData.unshift(`Name,Labels,${notesHeaders.join(',')}`);
  csvData = csvData.join('\n');

  const filename = `tibbits_export_${labelName}_${dateFnsFormat(new Date(), 'yyyyMMdd')}.csv`;
  const contentType = 'text/csv';
  const a = document.createElement('a');
  a.download = filename;
  a.href = `data:${contentType},${encodeURIComponent(csvData)}`;
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  return true;
}

function filterAndSortNotes(notes, showArchivedNotes) {
  if (!notes?.length) {
    return [];
  }

  return Object.values(notes).filter((n) => n.isActive !== showArchivedNotes).sort((a, b) => {
    if (a.index !== undefined && b.index !== undefined) {
      return b.index - a.index;
    }
    return b.createdTimestamp - a.createdTimestamp;
  });
}

async function isAtLimit(limit, personKey, nameTagKey) {
  const globalReduxStore = store.getState();
  const limitNum = PLAN_LIMITS.find((l) => (l.name ? l.name.toLocaleLowerCase() : '').replaceAll(' ', '') === (limit ? limit.toLocaleLowerCase() : '').replaceAll(' ', ''))?.[globalReduxStore.subscriptionInfo.isActive ? 'premium' : 'free'];

  let limitHit = false;

  if (limit === 'people') {
    limitHit = globalReduxStore.people.length >= limitNum;
  } else if (limit === 'nameTags') {
    limitHit = globalReduxStore.nameTags.length >= limitNum;
  } else if (limit === 'nameTagNotes') {
    limitHit = globalReduxStore.nameTagNotes[nameTagKey]?.length >= limitNum;
  } else if (limit === 'notes') {
    limitHit = globalReduxStore.notes2[personKey]?.length >= limitNum;
  } else if (limit === 'reminders') {
    let numRemindersForPerson = 0;
    const personNoteKeys = globalReduxStore.notes2[personKey]?.map((n) => n.key) || [];
    const personTidbitKeys = Object.keys(globalReduxStore.personTidbits[personKey]) || [];
    Object.entries(globalReduxStore.reminders)?.forEach(([noteOrTidbitKey, reminder]) => {
      if (reminder.isActive && (personNoteKeys.includes(noteOrTidbitKey) || personTidbitKeys.includes(noteOrTidbitKey))) {
        numRemindersForPerson++;
      }
    });
    // @TODO: console.log(globalReduxStore.subscriptionInfo);
    // @TODO: console.log(limitNum);
    // @TODO: console.log(numRemindersForPerson);
    limitHit = numRemindersForPerson >= limitNum;
  } else if (limit === 'labels') {
    limitHit = globalReduxStore.labels.length >= limitNum;
  } else if (limit === 'labelGeofences') {
    limitHit = globalReduxStore.labels.filter((l) => l.locationRemindersActive).length >= limitNum;
  } else if (limit === 'tidbits') {
    limitHit = Object.values(globalReduxStore.tidbits).length >= limitNum;
  } else if (limit === 'photos') {
    let personTidbitPhotosCount = 0;
    const photoTidbitKeys = Object.values(globalReduxStore.tidbits).filter((t) => t.datatype === 'photo').map((t) => t.key);
    Object.values(globalReduxStore.personTidbits)?.forEach((personTidbits) => {
      Object.values(personTidbits)?.forEach((personTidbit) => {
        if (photoTidbitKeys.includes(personTidbit.tidbitKey)) {
          personTidbitPhotosCount++;
        }
      });
    });
    limitHit = (globalReduxStore.people.filter((p) => p.photoUrl).length + personTidbitPhotosCount) >= limitNum;
  } else {
    // @TODO: error handling
    limitHit = false;
  }

  if (limitHit && !globalReduxStore.user.gracePeriodStartTimestamp) {
    firestore.userDoc.update({gracePeriodStartTimestamp: new Date().getTime()});
  }

  return limitHit;
}

function syncUsageStats() {
  const globalReduxStore = store.getState();

  let numTidbitPhotos = 0;
  const photoTidbitKeys = Object.values(globalReduxStore.tidbits).filter((t) => t.datatype === 'photo').map((t) => t.key);
  Object.values(globalReduxStore.personTidbits)?.forEach((personTidbits) => {
    Object.values(personTidbits)?.forEach((personTidbit) => {
      if (photoTidbitKeys.includes(personTidbit.tidbitKey)) {
        numTidbitPhotos++;
      }
    });
  });

  firestore.userDoc.set({usageStats: {
    people: globalReduxStore.people.length,
    notes: Object.values(globalReduxStore.notes2).map((personNotes) => personNotes.length).reduce((acc, curr) => acc + curr, 0),
    reminders: Object.values(globalReduxStore.reminders).length,
    labels: globalReduxStore.labels.length,
    tidbits: Object.values(globalReduxStore.tidbits).length,
    photos: globalReduxStore.people.filter((p) => p.photoUrl).length + numTidbitPhotos,
  }}, {merge: true});
}

export default oakGlobalObject({
  subscribeToUser,
  savePlatformActivity,

  subscribeToReminders,
  createReminder,
  updateReminder,
  deleteReminder,

  subscribeToLabels,
  createLabel,
  updateLabel,
  deleteLabel,
  setLabelAssignment: platformUtilities.setLabelAssignment,

  configureBackgroundGeolocation: platformUtilities.configureBackgroundGeolocation,

  subscribeToPeople,
  createPerson,
  updatePerson: importedUtilities.updatePerson,
  deletePerson,
  searchFilteredPeople,
  sortPeople,

  createNote,
  updateNote,
  reorderNotes,
  deleteNote,

  subscribeToTidbits,
  createTidbit,
  updateTidbit,
  deleteTidbit,
  updateTidbitForPerson: importedUtilities.updateTidbitForPerson,

  subscribeToNameTags,
  createNameTag,
  updateNameTag: importedUtilities.updateNameTag,
  deleteNameTag,

  isPendingNameTagAlreadySaved,
  fetchPendingPeople,
  createPendingPerson,
  deletePendingPerson,

  createNameTagNote,
  updateNameTagNote,
  reorderNameTagNotes,
  deleteNameTagNote,

  subscribeToNotifications: platformUtilities.subscribeToNotifications,

  exportAsJson,
  exportLabelToCsv,
  filterAndSortNotes,

  isAtLimit,

  uploadPhoto: platformUtilities.uploadPhoto,

  syncSubscriptionInfo: platformUtilities.syncSubscriptionInfo,
  syncUsageStats,

  schedulePeriodicReminders: platformUtilities.schedulePeriodicReminders,

  useBackButton: platformUtilities.useBackButton || (() => {}),

  camelCaseToHumanReadable: commonUtilities.camelCaseToHumanReadable,
  memoAreEqual: commonUtilities.memoAreEqual,
  useI18n: commonUtilities.useI18n,
  getHeaderHeight: commonUtilities.getHeaderHeight,
  getStatusBarHeight: commonUtilities.getStatusBarHeight,
  isIphoneWithCutout: commonUtilities.isIphoneWithCutout || (() => {}),
  linkUserAndCredential: commonUtilities.linkUserAndCredential,
  isSecondOlderOrSameAsFirst: commonUtilities.isSecondOlderOrSameAsFirst,
  rebuildEStyleSheet: commonUtilities.rebuildEStyleSheet,

  useStyles: commonUtilities.useStyles,
});
