/**
 * Ryan O'Dowd
 * 2023-01-24
 * © Copyright 2023 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  FORM_WIDTH,
} from '../styles';
import Globals from '../../../../Globals';

const styles = {
  container: {
    flex: 1,
  },
  authFormContainerWrapper: {
    flex: 1,
  },
  authFormContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  formInput: {
    // @TODO: flex: 1,
    width: FORM_WIDTH,
    justifyContent: 'center',
    // @TODO: maxHeight: 48,
    margin: 4,
    padding: 12,
    borderRadius: 5,
    overflow: 'hidden',
    color: Globals.colors.black,
  },
  formText: {
    width: FORM_WIDTH,
    marginBottom: 24,
    fontSize: 16,
    color: Globals.colors.white,
  },
  textInput: {
    width: '100%',
    textAlign: 'left',
    fontSize: 18,
    // @TODO: minHeight: 48,
    // @TODO: maxHeight: 48,
    color: Globals.colors.black,
    backgroundColor: Globals.colors.white,
    padding: 4,
  },
  nameInputWrapper: {
    flexDirection: 'column',
  },
  buttonLoadingWrapper: {
    flexDirection: 'row',
  },
  loadingIndicator: {
    display: 'flex',
    justifySelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 8,
  },
};

export default styles;
