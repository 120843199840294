/*
 * Ryan O'Dowd
 * 2019-06-02
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';
import Globals, {
  firebase,
  firestore,
} from '../../Globals';
import {
  MuiThemeProvider,
  createTheme as createMuiTheme,
} from '@material-ui/core/styles';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import Event from '../Event';
import FsiForm from '../FsiForm';
import Help from '../Help';
import Home from '../Home';
// @TODO: import LoadAppData from './LoadAppData';
import LandingPage from '../Home/LandingPage';
import {
  Paper,
} from '@material-ui/core';
import ReactGA from 'react-ga4';
import {
  StyleRoot,
} from 'radium';
import styles from './styles';
import utilities from '../../utilities';

ReactGA.initialize('164098353');

const App = (props) => {
  const [_isUserLoggedIn, setIsUserLoggedIn] = useState(undefined);

  const dispatch = useDispatch();

  const lightDarkMode = useSelector((state) => state.settingsLightDarkMode);
  const theme = React.useMemo(() => createMuiTheme({
    palette: {
      primary: Globals.themePrimary,
      secondary: Globals.themeAccent,
      // @TODO: error: Globals.themeError,
      type: lightDarkMode,
      // @TODO: explore tonaloffset, contrastThreshold, contrastText, and others
      text: {
        primary: Globals.colors[`${lightDarkMode}Theme`].text,
      },
      background: {
        paper: Globals.colors[`${lightDarkMode}Theme`].background,
      },
    },
    overrides: {
      /* @TODO:
      MuiButton: {
        flatSecondary: {
          color: Globals.colors[`${lightDarkMode}Theme`].light,
        },
      },
      */
    },
  }), [lightDarkMode]);

  useEffect(() => {
    if (firestore.userDoc) {
      const unsubscribe = utilities.subscribeToUser();

      return () => unsubscribe();
    }
  }, [_isUserLoggedIn]);

  useEffect(() => {
    const authUnsubscribe = firebase.auth().onAuthStateChanged(async (authUser) => {
      if (authUser) {
        firestore.setUser(authUser.uid);
        setIsUserLoggedIn(true); // @NOTE: this is redundant (because we set this outside of the `if` below), but we're doing it anyways so that the welcome screen doesn't get rendered briefly if user is logged in)

        firestore.userDoc.set({
          // @TODO: display name if auth provider gives it
          joinTimestamp: new Date(authUser.metadata.creationTime).getTime(),
          providerIds: authUser.providerData.map((d) => d.providerId),
          isAnonymous: authUser.isAnonymous,
        }, {merge: true});
        // @TODO: firebase.crashlytics().setUserId(authUser.uid);

        /* @TODO:
        try {
          await Purchases.logIn(authUser.uid);
          utilities.syncSubscriptionInfo((...args) => dispatch(setSubscriptionInfo(...args)));
        } catch (e) {
          firebase.crashlytics().log(`Error logging in RevenueCat user: \`${e.message}\``);
        }

        utilities.syncUsageStats();
        */
      }

      setIsUserLoggedIn(!!authUser);

      return () => authUnsubscribe();
    });
  }, [dispatch]);

  return (
    <StyleRoot>
      <MuiThemeProvider theme={theme}>
        <Paper
          elevation={0}
          square={true}
          style={styles.container}
        >
          <BrowserRouter>
            <div style={styles.containerInnerWrapper}>
              {/* @TODO: don't hardcode app version number */}
              {/* @TODO:
              {(props.user && firestore.userDoc) && <LoadAppData appVersionNumber={'0.2.0'} platform={'web'} />}
              */}
              {/* @TODO: add GAWrapper like nabc */}
              <div style={styles.containerInner}>
                <Switch>
                  {/* @TODO:
                  <Route component={Event} path='/events/:eventCode' exact={true} />
                  */}
                  <Route component={FsiForm} path='/fsi/:event/:exhibitor' exact={true} />
                  <Route component={Help} path='/help' exact={true} />
                  <Route component={_isUserLoggedIn ? Home : LandingPage} path='/' exact={false} />
                  <Route component={LandingPage} path='/' exact={false} />
                </Switch>
              </div>
            </div>
          </BrowserRouter>
        </Paper>
      </MuiThemeProvider>
    </StyleRoot>
  );
};

export default App;
