/*
 * Ryan O'Dowd
 * 2020-12-29
 * © Copyright 2020 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  Chip,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const Label = (props) => {
  // @TODO: add onClick options
  return (
    <Chip
      key={props.label.key}
      style={{...styles.container, ...(props.extraStyle || {})}}
      label={props.label.value}
      onDelete={props.onDelete || null}
      deleteIcon={props.deleteIcon || null}
    />
  );
};

Label.propTypes = {
  label: PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,

  deleteIcon: PropTypes.object,
  extraStyle: PropTypes.object,
  onDelete: PropTypes.func,
};

export default Label;
