/*
 * Ryan O'Dowd
 * 2019-06-08
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: Globals.colors.primary,
    backgroundImage: `linear-gradient(
      ${Globals.colors.primaryLight} 0%,
      ${Globals.colors.primary} 100%
    )`,

    color: Globals.colors.white,
  },
  headerInner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 56,

    [Globals.mediaQueries.sm]: {
      justifyContent: 'flex-start',
      paddingLeft: 8,
      borderBottom: `2px solid ${Globals.colors.accent}`,
    },
  },
  logoAndName: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'initial',
    color: 'white',
  },
  logo: {
    width: 42,
    height: 42,
    marginRight: 8,
  },
  appTitle: {
    fontSize: 32,
    fontWeight: '800',
    fontFamily: 'Montserrat',
  },
  betaText: {
    alignSelf: 'flex-start',
    fontSize: 12,
    paddingTop: 8,
    fontWeight: '800',
    cursor: 'pointer',
  },
  buttons: {
    position: 'absolute',
    right: 8,
  },
  loginButton: {
    color: 'white',
  },
  userImage: {
    width: 60,
    marginRight: 12,
  },
  accountText: {
    fontStyle: 'italic',
  },
  emailText: {
    marginTop: -6,
    fontSize: '0.75rem',
  },
  planText: {
    fontSize: '0.9rem',
  },
  iconMenuItemText: {
    marginLeft: 8,
  },
};

export default styles;
