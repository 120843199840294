/*
 * Ryan O'Dowd
 * 2019-06-08
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
// @TODO: combine with Setup.js (and rename to index.js probably)...just be careful that Setup.js is used in ./src/../index.js
import './index.css';
import App from './containers/App';
import {
  PersistGate,
} from 'redux-persist/integration/react';
import React from 'react';
import {
  Provider as StoreProvider,
} from 'react-redux';
import configureStore from './Store';
import {
  createRoot,
} from 'react-dom/client';

const reduxStuff = configureStore();

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <StoreProvider store={reduxStuff.store}>
      <PersistGate
        loading={null}
        persistor={reduxStuff.persistor(() => {})}
      >
        <App />
      </PersistGate>
    </StoreProvider>
  </React.StrictMode>
);
