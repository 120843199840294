/**
 * Ryan O'Dowd
 * 2018-11-05
 * © Copyright 2018 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import oakGlobalObject from '../../common/utilities/oakGlobalObject';

export const ALL_APPS = (platform) => [
  {
    verticalDividerAfter: true,
    name: 'Website',
    platforms: ['ios', 'android', 'web'],
    link: 'https://oakwoodsc.com/',
    image: require('./img/oakwood.png'),
  },
  /* @TODO:
  {
    name: 'Tibbits Web',
    platforms: ['ios', 'android'],
    link: 'https://tibbits.me',
    image: require('./img/tibbits.png'), // @TODO: modified logo for web?
  },
  */
  {
    name: 'Tibbits',
    platforms: ['ios'],
    link: 'https://itunes.apple.com/us/app/id1332548860?mt=8&ls=1',
    image: require('./img/tibbits.png'),
  },
  {
    name: 'Tibbits',
    platforms: ['android'],
    link: 'https://play.google.com/store/apps/details?id=com.oakwoodsc.tibbits',
    image: require('./img/tibbits.png'),
  },
  {
    name: 'Wrinkly',
    platforms: ['ios'],
    link: 'https://itunes.apple.com/us/app/id1615885499?mt=8&ls=1',
    image: require('./img/wrinkly.png'),
  },
  {
    name: 'Wrinkly',
    platforms: ['android'],
    link: 'https://play.google.com/store/apps/details?id=com.oakwoodsc.wrinkly',
    image: require('./img/wrinkly.png'),
  },
  {
    verticalDividerBefore: true,
    name: 'Legal',
    platforms: ['ios', 'android', 'web'],
    link: 'https://tibbits.me/#terms_and_privacy',
    iconName: 'scale-balance',
  },
  /* @TODO:
  {
    name: 'Licenses',
    platforms: ['ios', 'android', 'web'],
    link: 'https://oakwoodsc.com/', // @TODO: different link for each app (content generated by ci)
    iconName: 'account-card-details',
  },
  */
  // @NOTE: last one intentionally left blank to avoid linear graient
  {
    isEmpty: true,
    name: 'empty',
    platforms: ['ios', 'android'],
    link: '',
    iconName: '',
  },
].filter((app) => app.platforms.includes(platform));

const i18n = (platform) => {
  let storeName = 'App Store or Play Store';
  if (platform === 'ios') {
    storeName = 'App Store';
  } else if (platform === 'android') {
    storeName = 'Play Store';
  }
  return oakGlobalObject({
    aboutGeneral: oakGlobalObject({
      title: 'Support',
      p1: 'Thank you so much for using Tibbits.  Building apps that you love is our passion, and your support means so much to us.',
      p2: `We aim to build our apps in a way that facilitates meaningful relationships with those around you.  If Tibbits has been beneficial to you, please consider supporting us by rating Tibbits on the ${storeName} and sharing with friends and family.`,
    }),

    whoWeAreSection: oakGlobalObject({
      title: 'Who we are',
      content: 'Oakwood Software Consulting, Inc. is a Christ-centered organization built on biblical principles. As such, we strive to honor God in all that we do, act with integrity to deliver to our customers, and work to the best of our abilities. We are passionate about our projects and take pride in our products.',
      rodowd: "Ryan is the founder of Oakwood Software Consulting, Inc.  When he's not working on Tibbits, he's probably running a triathlon or competing at a whistling convention.",
      btraut: 'Brandon is a passionate Android developer.  He enjoys completing house projects and spending time with his family.',
    }),

    shareOptions: oakGlobalObject({
      storeLink: 'https://oakwoodsc.com/store-link/tibbits',
      title: 'Remember names',
      message: 'Tibbits makes it easier to remember names and important things about people in your life.',
      dialogTitle: 'Share Tibbits',
    }),
  });
};

export default i18n;
