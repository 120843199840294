/*
 * Ryan O'Dowd
 * 2019-06-08
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  // @TODO: Button,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import {
  logout,
  setLightDarkMode,
  setUser,
} from '../../actions';
import About from '../../containers/About';
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
// @TODO: import Brightness6Icon from 'mdi-react/Brightness6Icon'; // @TODO: maybe use ThemeLightDarkIcon instead?
// @TODO: import DotsVerticalIcon from 'mdi-react/DotsVerticalIcon';
import DownloadLinks from '../DownloadLinks';
import ExportIcon from 'mdi-react/ExportIcon';
// @TODO: import FeedbackChat from '../../containers/FeedbackChat';
// @TODO: import ForumIcon from 'mdi-react/ForumIcon';
import HelpCircleIcon from 'mdi-react/HelpCircleIcon';
// @TODO: import InformationIcon from 'mdi-react/InformationIcon';
import {
  Link,
} from 'react-router-dom';
import OakAuth from '../../common/containers/OakAuth';
import PlansDialog from './PlansDialog';
import PropTypes from 'prop-types';
import Radium from 'radium';
import React from 'react';
import {
  bindActionCreators,
} from 'redux';
import {
  connect,
} from 'react-redux';
import {
  firestore,
} from '../../Globals';
import styles from './styles';
import utilities from '../../utilities';

class Header extends React.Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
    lightDarkMode: PropTypes.string.isRequired,
    setLightDarkMode: PropTypes.func.isRequired,
    setUser: PropTypes.func.isRequired,

    hideLogo: PropTypes.bool,
    title: PropTypes.string,
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      loginDialogIsOpen: ['/finish-email-sign-in', '/export'].includes(window.location.pathname) && !this.props.user,
      feedbackChatIsOpen: false,
      planDialogIsOpen: false, // @TODO: have this open by default on login only
      aboutDialogIsOpen: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.setState({loginDialogIsOpen: false});
    }
  }

  render() {
    return (
      <div style={styles.container}>
        <div style={styles.headerInner}>
          <a
            style={styles.logoAndName}
            href={this.props.title ? '#' : '/'}
          >
            {!this.props.hideLogo && <img style={styles.logo} src='/img/logo.png' alt='logo' />}
            <h1 style={styles.appTitle}>{this.props.title || 'Tibbits'}</h1>
          </a>
          {/* @TODO:
          <span
            style={styles.betaText}
            onClick={() => this.setState({planDialogIsOpen: true})}
          >
            beta
          </span>
          */}
          {!this.props.hideButtons &&
            <div style={styles.buttons}>
              <Tooltip title='Help'>
                <IconButton
                  component={Link}
                  to='/help'
                >
                  <HelpCircleIcon color='white' />
                </IconButton>
              </Tooltip>
              {this.props.user
                ? (
                  <React.Fragment>
                    {/* @TODO:
                    <Tooltip title='Feedback chat'>
                      <IconButton
                        onClick={() => {
                          this.setState((prevState) => {
                            return {feedbackChatIsOpen: !prevState.feedbackChatIsOpen};
                          });
                        }}
                      >
                        <ForumIcon color='white' />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title='About'>
                      <IconButton onClick={() => this.setState({aboutDialogIsOpen: true})}>
                        <InformationIcon color='white' />
                      </IconButton>
                    </Tooltip>
                    */}
                    {/* @TODO:
                    <span>
                      <Tooltip title='More options'>
                        <IconButton
                          aria-controls='more-options-menu'
                          aria-haspopup='true'
                          onClick={(event) => this.setState({moreMenuAnchorEl: event.currentTarget})}
                        >
                          <DotsVerticalIcon color='white' />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        id='more-options-menu'
                        anchorEl={this.state.moreMenuAnchorEl}
                        open={!!this.state.moreMenuAnchorEl}
                        onClose={() => this.setState({moreMenuAnchorEl: null})}
                      >
                        <MenuItem
                          key='lightDarkMode'
                          value='toggleLightDarkMode'
                          onClick={() => {
                            this.props.setLightDarkMode(this.props.lightDarkMode === 'dark' ? 'light' : 'dark');
                            this.setState({moreMenuAnchorEl: null});
                          }}
                        >
                          <Brightness6Icon /><span style={styles.iconMenuItemText}>{' Toggle light/dark mode'}</span>
                        </MenuItem>
                      </Menu>
                    </span>
                    */}
                    <span>
                      <Tooltip title='Account'>
                        <IconButton
                          aria-controls='account-options-menu'
                          aria-haspopup='true'
                          onClick={(event) => this.setState({accountMenuAnchorEl: event.currentTarget})}
                        >
                          <AccountCircleIcon color='white' />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        id='account-options-menu'
                        anchorEl={this.state.accountMenuAnchorEl}
                        keepMounted
                        open={!!this.state.accountMenuAnchorEl}
                        onClose={() => this.setState({accountMenuAnchorEl: null})}
                      >
                        {/* @TODO:
                        <MenuItem
                          key='account'
                          onClick={() => this.setState({planDialogIsOpen: true})}
                        >
                          <img style={styles.userImage} src={this.props.user.photoURL || '/img/avatar.png'} alt='profile' />
                          <span style={{display: 'flex', flexDirection: 'column'}}>
                            <span>{this.props.user.displayName}</span>
                            <span style={styles.emailText}><em>{this.props.user.email}</em></span>
                            <span style={styles.planText}>Plan:&nbsp;<strong>beta</strong></span>
                          </span>
                        </MenuItem>
                        <MenuItem
                          key='export'
                          value='export'
                          onClick={() => {
                            utilities.exportAsJson();
                            this.setState({moreMenuAnchorEl: null});
                          }}
                        >
                          <ExportIcon /><span style={styles.iconMenuItemText}>{' Export all data (.json)'}</span>
                        </MenuItem>
                        <MenuItem
                          key='export'
                          value='export'
                          onClick={() => {
                            utilities.exportLabelToCsv(); // @TODO: csv (pick labels too)
                            this.setState({moreMenuAnchorEl: null});
                          }}
                        >
                          <ExportIcon /><span style={styles.iconMenuItemText}>{' Export all data (.csv)'}</span>
                        </MenuItem>
                        */}
                        <MenuItem
                          key='logout'
                          onClick={() => {
                            this.props.logout();
                            this.setState({accountMenuAnchorEl: null});
                          }}
                        >
                          Sign out
                        </MenuItem>
                        <MenuItem
                          key='download'
                          value='download'
                        >
                          <DownloadLinks />
                        </MenuItem>
                      </Menu>
                    </span>
                    {/* @TODO:
                    {this.state.feedbackChatIsOpen && <FeedbackChat onClose={() => this.setState({feedbackChatIsOpen: false})} />}
                    */}
                  </React.Fragment>
                ) : (
                  null
                  /* @TODO:
                  <Button
                    style={styles.loginButton}
                    onClick={() => this.setState({loginDialogIsOpen: true})}
                  >
                    Sign in
                  </Button>
                  */
                )
              }
              {this.state.loginDialogIsOpen && (
                <OakAuth
                  onClose={() => this.setState({loginDialogIsOpen: false})}
                  appName='Tibbits'
                  skipDescription={true}
                  isOpen={true}
                  signInUser={(user) => {
                    firestore.setUser(user.uid);
                    this.props.setUser(user);
                    this.setState({loginDialogIsOpen: false});
                    window.location = '/';
                  }}
                />
              )}
              {/* @TODO: add faq button */}
              <PlansDialog
                open={this.state.planDialogIsOpen}
                onClose={() => this.setState({planDialogIsOpen: false})}
              />
            </div>
          }
        </div>
        {this.state.aboutDialogIsOpen && <About onClose={() => this.setState({aboutDialogIsOpen: false})} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    lightDarkMode: state.settingsLightDarkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    logout,
    setLightDarkMode,
    setUser,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Radium(Header));
