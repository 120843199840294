/*
 * Ryan O'Dowd
 * 2020-11-29
 * © Copyright 2020 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  // @TODO: Button,
  // @TODO: Dialog,
  // @TODO: DialogActions,
  // @TODO: DialogContent,
  // @TODO: DialogTitle,
  // @TODO: Drawer,
  IconButton,
  // @TODO: Menu,
  // @TODO: MenuItem,
  // @TODO: Paper,
  // @TODO: Select,
  TextField,
  // @TODO: Tooltip,
} from '@material-ui/core';
/* @TODO:
import {
  DatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
*/
// @TODO: import {
// @TODO:   PEOPLE_SORT_METHODS,
// @TODO:   setPeopleSortMethod,
  // @TODO: fetchWhatsNew,
// @TODO: } from '../../actions';
// @TODO: import AccountPlusIcon from 'mdi-react/AccountPlusIcon';
// @TODO: import ArchiveIcon from 'mdi-react/ArchiveIcon';
import React, {
  // @TODO: useCallback,
  useEffect,
  // @TODO: useState,
} from 'react';
// @TODO: import BellIcon from 'mdi-react/BellIcon';
// @TODO: import BellOffIcon from 'mdi-react/BellOffIcon';
// @TODO: import CloseIcon from 'mdi-react/CloseIcon';
// @TODO: import DateFnsUtils from '@date-io/date-fns';
// @TODO: import DeleteIcon from 'mdi-react/DeleteIcon';
// @TODO: import Details from '../Details';
// @TODO: import {
// @TODO:   DndProvider,
// @TODO: } from 'react-dnd';
import DownloadIcon from 'mdi-react/DownloadIcon';
// @TODO: import DownloadLinks from '../../components/DownloadLinks';
import Footer from '../../common/components/OakFooter';
import Globals from '../../Globals';
// @TODO: import {
// @TODO:   HTML5Backend,
// @TODO:} from 'react-dnd-html5-backend';
import Header from '../../components/Header';
import Label from '../../components/Label';
// @TODO: import LabelIcon from 'mdi-react/LabelIcon';
// @TODO: import MenuIcon from 'mdi-react/MenuIcon';
// @TODO: import Note from '../../components/Note';
// @TODO: import Photo from '../../components/Photo';
// @TODO: import PinIcon from 'mdi-react/PinIcon';
// @TODO: import PropTypes from 'prop-types';
import Radium from 'radium';
// @TODO: import ReminderIcon from 'mdi-react/ReminderIcon';
// @TODO: import SortIcon from 'mdi-react/SortIcon';
// @TODO: import {
// @TODO:   format as dateFnsFormat,
// @TODO: } from 'date-fns';
import styles from './styles';
import {
  // @TODO: useDispatch,
  useSelector,
} from 'react-redux';
import utilities from '../../utilities';

// @TODO: export const NEVER = 'never';
// @TODO: const YEARLY = 'yearly';
// @TODO: const MONTHLY = 'monthly';
// @TODO: const WEEKLY = 'weekly';
// @TODO: const DAILY = 'daily';
// @TODO: const REPEAT_INTERVAL_OPTIONS = [NEVER, YEARLY, MONTHLY, WEEKLY, DAILY];

const Home = (props) => {
  // @TODO: const dispatch = useDispatch();
  // @TODO: const lightDarkMode = useSelector((state) => state.settingsLightDarkMode);
  /* @TODO:
  const allNotes = useSelector((state) => Object.entries(state.notes2).map(([personKey, notes]) => {
    return notes.map((n) => {
      return {
        ...n,
        personKey,
        hasReminder: !!state.reminders[n.key],
      };
    });
  })).flat();
  */
  // @TODO: const people = useSelector((state) => state.people.sort((a, b) => utilities.sortPeople(state.settingsPeopleSortMethod, a, b)));
  // @TODO: const reminders = useSelector((state) => state.reminders);
  // @TODO: const peopleSortMethod = useSelector((state) => state.settingsPeopleSortMethod);
  // @TODO: const subscriptionInfo = useSelector((state) => state.subscriptionInfo);
  // @TODO: const user = useSelector((state) => state.user);
  const userLabels = useSelector((state) => state.labels.sort((a, b) => {
    if (!a.value) {
      return -1;
    } else if (!b.value) {
      return 1;
    }
    return a.value.localeCompare(b.value, {sensitivity: 'base'});
  }));

  /* @TODO:
  const [_showArchivedPeople, setShowArchivedPeople] = useState(false);
  const _getFirstPersonKey = useCallback(() => {
    const activePeople = people.filter((p) => p.isActive !== _showArchivedPeople).sort((a, b) => {
      if (b.isPinned && !a.isPinned) {
        return 1;
      } else if (!b.isPinned && a.isPinned) {
        return -1;
      }
      return 0;
    });
    return activePeople[0] ? activePeople[0].key : null;
  }, [_showArchivedPeople, people]);

  const [_searchText, setSearchText] = useState('');
  const [_allLabelsDialogIsOpen, setAllLabelsDialogIsOpen] = useState(false); // @TODO: implement
  const [_allRemindersDialogIsOpen, setAllRemindersDialogIsOpen] = useState(false); // @TODO: implement
  const [_selectedPersonKey, setSelectedPersonKey] = useState(_getFirstPersonKey());
  const [_selectedLabelIds, setSelectedLabelIds] = useState([]);
  const [_sortMenuAnchorEl, setSortMenuAnchorEl] = useState(null);
  const [_isPeopleSideDrawerOpen, setIsPeopleSideDrawerOpen] = useState(false);
  */
  /* @TODO:
  const handleClick = (store) => {
    ReactGA.event({
      category: 'Download button clicked',
      action: `${store} visited`,
    });
  };
  */

  useEffect(() => {
    utilities.savePlatformActivity();
    // @TODO: dispatch(fetchWhatsNew());

    const mainSubscriptions = [
      utilities.subscribeToPeople(),
      utilities.subscribeToNotifications(), // @TODO: this should be subscribed to only on in feedback chat. however, a get here would be nice so that the badge show up in the side drawer?
      utilities.subscribeToLabels(),
      utilities.subscribeToReminders(),
      utilities.subscribeToTidbits(),
    ];

    return () => {
      mainSubscriptions?.forEach((s) => s());
    };
  }, []);

  /* @TODO:
  useEffect(() => {
    // update person in details view if either archive-people button is toggled
    // @TODO: or if a person is archived, unarchived, or deleted
    setSelectedPersonKey(_getFirstPersonKey());
  }, [_showArchivedPeople, _getFirstPersonKey]);
  */

  /* @TODO: total height on mbp is a few pixels off */

  /* @TODO:
  const _getLabelNameByKey = (labelKey) => {
    const [label] = userLabels.filter((l) => l.key === labelKey);
    return label ? label.value : '';
  };

  const _renderSortedPersonLabels = (personLabelIds) => {
    return personLabelIds.map((labelKey) => {
      return [labelKey, _getLabelNameByKey(labelKey)];
    }).sort((a, b) => {
      if (!a[1]) {
        return -1;
      } else if (!b[1]) {
        return 1;
      }
      return a[1].localeCompare(b[1], {sensitivity: 'base'});
    }).map(([labelKey, labelName]) => {
      return (
        // @TODO: convert to material-ui Chip
        // @TODO: horizontal scroll for labels only
        <span
          key={labelKey}
          style={styles.personLabel}
          onClick={() => {
            if (_selectedLabelIds.includes(labelKey)) {
              setSelectedLabelIds((curr) => curr.filter((lid) => lid !== labelKey));
            } else {
              setSelectedLabelIds((curr) => [...curr, labelKey]);
            }
          }}
        >
          {labelName}
        </span>
      );
    });
  };

  const _renderPersonPreview = (person) => {
    return (
      <div
        style={styles.nameAndPinWrapper}
        key={person.key}
      >
        <div
          style={{...styles.personPreviewWrapper, ...(person.key === _selectedPersonKey ? styles.selectedPersonPreviewWrapper : {})}}
          onClick={() => {
            // @TODO: ask for confirmation to not save new note if this.state.newNoteText !== ''
            console.log(person.key);
            console.log(_selectedPersonKey);
            setSelectedPersonKey(person.key);
            // @TODO: setNewNoteText('');
            setIsPeopleSideDrawerOpen(false);
          }}
        >
          <div style={styles.nameCardWrapper}>
            <div style={styles.photoWrapper}>
              <Photo personKey={person.key} name={person.name} photoUrl={person.photoUrl} />
            </div>
            <div style={styles.nonPhotoWrapper}>
              <span style={styles.personNameWrapper}>
                <Tooltip title={person.name}>
                  <span style={styles.personName}>{person.name}</span>
                </Tooltip>
              </span>
              <span style={styles.personLabelsWrapper}>
                <div style={styles.personLabels}>
                  {_renderSortedPersonLabels(person.labelIds)}
                </div>
              </span>
            </div>
          </div>
        </div>
        {person.isPinned && <PinIcon style={{...styles.smallIcon, ...styles.personPin}} size={12} />}
      </div>
    );
  };

  const _renderAllManageLabelsDialog = () => {
    return (
      <Dialog
        open={true}
        style={styles.dialog}
        aria-labelledby='alert-dialog-title-todo'
        aria-describedby='alert-dialog-description-todo'
        fullScreen={Globals.isMobile}
        onClose={() => setAllLabelsDialogIsOpen(false)}
      >
        <DialogTitle id='alert-dialog-title-todo'>
          Manage labels
        </DialogTitle>
        <DialogContent>
          {userLabels.map((label) => {
            // @TODO: these should be ordered in a user-defined order
            return (
              <div
                key={label.key}
                style={styles.labelRow}
              >
                <TextField
                  key={label.key}
                  placeeholder='Label name'
                  defaultValue={label.value || null}
                  onBlur={(e) => {
                    if (label.value !== e.target.value) {
                      utilities.updateLabel(label.key, {value: e.target.value});
                    }
                  }}
                  margin='dense'
                />
                {/* @TODO: add create/updated timestamps and other meta data here (maybe how many people are using the label and stuff like that) /}
                {/* @TODO: add location to labels /}
                <IconButton
                  onClick={() => {
                    // @TODO: ask for confirmation
                    utilities.deleteLabel(label.key);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button
            color='secondary'
            variant='contained'
            autoFocus={false}
            onClick={() => utilities.createLabel()}
          >
            Create new label
          </Button>
          <Button
            color='primary'
            variant='contained'
            autoFocus={false}
            onClick={() => setAllLabelsDialogIsOpen(false)}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const _renderAllRemindersDiloag = () => {
    // @TODO: abstract all of these dialogs to new components
    // @TODO: add filters and stuff to this to make it easier to find reminders (for example, don't show ones without an interval). and maybe add a button for deleting past reminders that don't repeat...or this could be a filter that's on be default...hide past reminders
    // @TODO: this dialog causes whole page to scroll vertically

    return (
      <Dialog
        open={true}
        style={styles.dialog}
        aria-labelledby='alert-dialog-title-todo'
        aria-describedby='alert-dialog-description-todo'
        fullScreen={Globals.isMobile}
        onClose={() => setAllRemindersDialogIsOpen(false)}
      >
        <DialogTitle id='alert-dialog-title-todo'>
          All reminders
        </DialogTitle>
        <DialogContent>
          <strong>Please note: reminders currently are available only on our mobile platforms.</strong>
          {/* @TODO: abstract details to _renderOption like on mobile...need to add labels to fields /}
          <div style={styles.allRemindersWrapper}>
            {/* @TODO: wish i didn't need the dnd context...can't conditionally render hooks though, and they need a context /}
            <DndProvider backend={HTML5Backend}>
              {/* need to filter on notes that exist...deleting a note used to not delete the reminder, so there could be some orphaned reminders /}
              {Object.values(reminders).filter((reminder) => allNotes[reminder.noteKey]).map((reminder) => {
              // @TODO: add note text
              // @TODO: this exists in Details as well...abstract with that
                return (
                  <div key={reminder.key} style={styles.reminderDetailsWrapper}>
                    <Note
                      personKey={_selectedPersonKey}
                      note={allNotes[reminder.noteKey]}
                      isEditable={false}
                      ignoreSmallIcons={true}
                      allRemindersNoteContainer={true}
                      overrideNoteActionsJsx={
                        <div style={styles.reminderDetailRow}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            {/* @TODO: be careful of timezone when sending emails /}
                            <DatePicker
                              value={new Date(reminder.startDate)}
                              inputProps={{style: styles.whiteTextField}}
                              InputLabelProps={{style: styles.whiteTextField}}
                              onChange={(selectedDate) => utilities.updateReminder(reminder.key, {startDate: dateFnsFormat(new Date(selectedDate), Globals.FIREBASE_DATE_FORMAT)})}
                              variant='inline'
                            />
                            <KeyboardTimePicker
                              value={new Date(2021, 1, 1, ...reminder.time.split(':'))}
                              inputProps={{style: styles.whiteTextField}}
                              InputLabelProps={{style: styles.whiteTextField}}
                              onChange={(selectedTime) => utilities.updateReminder(reminder.key, {time: dateFnsFormat(new Date(selectedTime.setSeconds(0)), Globals.FIREBASE_TIME_FORMAT)})}
                            />
                          </MuiPickersUtilsProvider>
                          {/* @TODO: all underlines, icons, and text should be white /}
                          <Select
                            labelId='demo-simple-select-label-todo'
                            id='demo-simple-select'
                            value={reminder.repeatInterval}
                            inputProps={styles.whiteTextField}
                            onChange={(event) => utilities.updateReminder(reminder.key, {repeatInterval: event.target.value})}
                          >
                            {REPEAT_INTERVAL_OPTIONS.map((o) => <MenuItem key={o} value={o}>{o.replace(/^\w/, (c) => c.toUpperCase())}</MenuItem>)}
                          </Select>
                          <IconButton
                            onClick={() => utilities.updateReminder(reminder.key, {isActive: !reminder.isActive})}
                          >
                            {reminder.isActive
                              ? <BellIcon color='white' />
                              : <BellOffIcon color='white' />
                            }
                          </IconButton>
                          <IconButton
                            autoFocus={false}
                            onClick={() => {
                            // @TODO: ask for confirmation
                              utilities.deleteReminder(reminder.key);
                            }}
                          >
                            <DeleteIcon color='white' />
                          </IconButton>
                        </div>
                      }
                    />
                  </div>
                );
              })}
            </DndProvider>
          </div>
        </DialogContent>
        <DialogActions>
          <DownloadLinks />
          <Button
            color='primary'
            variant='contained'
            autoFocus={false}
            onClick={() => setAllRemindersDialogIsOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  */

  // @TODO: const selectedPerson = people.find((p) => p.key === _selectedPersonKey);

  // @TODO: const personNotes = allNotes.filter((n) => n.personKey === _selectedPersonKey) || [];

  // @TODO: const personLabels = selectedPerson ? userLabels.filter((l) => selectedPerson.labelIds.includes(l.key)) : []; // @TODO: does this need to be faster? when going from home to details and back, labels change order from recency to alphabetical, and it's confusing for the user; actually, it might not be recency, just race condition?

  // @TODO: combine this logic with mobile?
  // @TODO: const pinnedPeopleToDisplay = utilities.searchFilteredPeople(people.filter((person) => person.isActive !== _showArchivedPeople && person.isPinned), allNotes, userLabels, _selectedLabelIds, _searchText);
  // @TODO: const unpinnedPeopleToDisplay = utilities.searchFilteredPeople(people.filter((person) => person.isActive !== _showArchivedPeople && !person.isPinned && (person.name || person.activeNotesCount)), allNotes, userLabels, _selectedLabelIds, _searchText);

  // @TODO: const numPeople = pinnedPeopleToDisplay.length + unpinnedPeopleToDisplay.length;

  return (
    <div style={styles.outerContainer}>
      <Header />
      <div style={styles.container}>
        <p>Choose a label to export to a CSV.</p>
        <div style={styles.labelsForExport}>
          {userLabels.map((label) => {
            // @TODO: these should be ordered in a user-defined order
            return (
              <Label
                key={label.key}
                label={label}
                onDelete={() => utilities.exportLabelToCsv(label.key)}
                deleteIcon={<DownloadIcon color='white' />}
              />
            );
          })}
        </div>
        {/* @TODO:
        <Paper
          style={{...styles.containerInner,
            ...(lightDarkMode === 'dark' && !Globals.isMobile ? {border: `2px solid ${Globals.colors.gray}`} : {})}}
        >
          <div style={{...styles.headerBar, ...(_showArchivedPeople ? styles.archivedHeaderBar : {})}}>
            <div style={styles.searchWrapper}>
              {!!people.length &&
                <React.Fragment>
                  <TextField
                    InputProps={{style: styles.whiteTextField}}
                    InputLabelProps={{style: styles.whiteTextField}}
                    label='Search'
                    value={_searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    margin='dense'
                  />
                  <span style={styles.peopleCountText}>{`${numPeople} ${numPeople === 1 ? 'person' : 'people'}`}</span>
                  {!!_selectedLabelIds.length && (
                    <span style={styles.selectedLabelWrapper}>
                      <span
                        style={{...styles.personLabel, ...styles.selectedPersonLabel}}
                        onClick={() => setSelectedLabelIds([])}
                      >
                        {_selectedLabelIds.map((lid) => _getLabelNameByKey(lid)).join()} <CloseIcon color='white' size={16} />
                      </span>
                    </span>
                  )}
                </React.Fragment>
              }
            </div>
            {/* @TODO: checkable options for labels /}
            <div style={styles.actions}>
              {!_showArchivedPeople &&
                <React.Fragment>
                  <Tooltip title='Add new person'>
                    <IconButton
                      onClick={() => {
                        const newPersonKey = utilities.createPerson();
                        setSelectedPersonKey(newPersonKey);
                      }}
                    >
                      <AccountPlusIcon color='white' />
                    </IconButton>
                  </Tooltip>
                  {!!people.length &&
                    <React.Fragment>
                      <Tooltip title='See all reminders'>
                        <IconButton
                          onClick={() => setAllRemindersDialogIsOpen(true)}
                        >
                          <ReminderIcon color='white' />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='See all labels'>
                        <IconButton
                          onClick={() => setAllLabelsDialogIsOpen(true)}
                        >
                          <LabelIcon color='white' />
                        </IconButton>
                      </Tooltip>
                    </React.Fragment>
                  }
                </React.Fragment>
              }
              {!!people.length &&
                <React.Fragment>
                  {!_showArchivedPeople
                    ? (
                      <Tooltip title='See archived people'>
                        <IconButton
                          onClick={() => setShowArchivedPeople(true)}
                        >
                          <ArchiveIcon color='white' />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title='Close archived people'>
                        <IconButton
                          onClick={() => setShowArchivedPeople(false)}
                        >
                          <CloseIcon color='white' />
                        </IconButton>
                      </Tooltip>
                    )
                  }
                  <span>
                    <Tooltip title='Sort options'>
                      <IconButton
                        aria-controls='sort-options-menu'
                        aria-haspopup='true'
                        onClick={(event) => setSortMenuAnchorEl(event.currentTarget)}
                      >
                        <SortIcon color='white' />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      id='sort-options-menu'
                      anchorEl={_sortMenuAnchorEl}
                      keepMounted
                      open={!!_sortMenuAnchorEl}
                      onClose={() => setSortMenuAnchorEl(null)}
                    >
                      {PEOPLE_SORT_METHODS.map((sortMethod) => {
                        const displayName = utilities.camelCaseToHumanReadable(sortMethod);
                        return (
                          <MenuItem
                            key={sortMethod}
                            value={sortMethod}
                            onClick={() => {
                              dispatch(setPeopleSortMethod(sortMethod));
                              setSortMenuAnchorEl(null);
                            }}
                          >
                            {peopleSortMethod === sortMethod
                              ? <strong>{displayName}</strong>
                              : <span>{displayName}</span>
                            }
                          </MenuItem>
                        );
                      })}
                    </Menu>
                  </span>
                </React.Fragment>
              }
            </div>
          </div>
          <div style={styles.infoWrapper}>
            {Globals.isMobile
              ? (
                <Drawer
                  open={_isPeopleSideDrawerOpen}
                  onClose={() => setIsPeopleSideDrawerOpen(false)}
                >
                  <div style={styles.namesWrapper}>{/* @TODO: abstract with non-mobile view /}
                    {pinnedPeopleToDisplay.map((person) => _renderPersonPreview(person))}
                    {unpinnedPeopleToDisplay.map((person) => _renderPersonPreview(person))}
                  </div>
                </Drawer>
              ) : (
                <div style={styles.namesWrapper}>{/* @TODO: this should have a fixed width where only the labels scroll to the left, not the whole people list /}
                  {pinnedPeopleToDisplay.map((person) => _renderPersonPreview(person))}
                  {unpinnedPeopleToDisplay.map((person) => _renderPersonPreview(person))}
                </div>
              )}
            <Details
              actionButton={!Globals.isMobile ? null : (
                <IconButton
                  onClick={() => setIsPeopleSideDrawerOpen(true)}
                >
                  <MenuIcon color='white' />
                </IconButton>
              )}
              selectedPerson={selectedPerson}
              showArchivedPeople={_showArchivedPeople}
              notes={personNotes}
              reminders={reminders}
              personLabels={personLabels}
              userLabels={userLabels}
              lightDarkMode={lightDarkMode}
            />
          </div>
          {_allRemindersDialogIsOpen && _renderAllRemindersDiloag()}
          {_allLabelsDialogIsOpen && _renderAllManageLabelsDialog()}
        </Paper>
      */}
      </div>
      <Footer backgroundColor={Globals.colors.primary} />
    </div>
  );
};

Home.propTypes = {
};

export default Radium(Home);
