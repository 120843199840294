/*
* Ryan O'Dowd
* 2021-04-09
* © Copyright 2021 Oakwood Software Consulting, Inc.  All Rights Reserved.
*/
// @TODO: compat needs to be removed before next major release: https://firebase.google.com/docs/web/modular-upgrade
import 'firebase/compat/analytics';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import importedFirebase from 'firebase/compat/app';

export default {
  importedFirebase,
  platform: 'web',
  appVersionNumber: '0.3.0', // @TODO: pull this dynamically from something...git tag? something else?
  env: ['tibbits.me'].includes(window.document.location.host) ? 'prod' : (window.document.location.hostname === 'localhost' ? 'local' : 'dev'),
};
