/*
 * Ryan O'Dowd
 * 2019-05-28
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Legal from './Legal';
import PropTypes from 'prop-types';
import React from 'react';
import {
  format as dateFnsFormat,
} from 'date-fns';
import styles from './styles';

const Footer = (props) => {
  return (
    // @TODO: footer should be a minimum of stuck to the bottom of the window (shouldn't be any space after it)
    <footer
      style={styles.container}
      onClick={() => {
        props.onClick?.();
        window.location = 'https://oakwoodsc.com';
      }}
    >
      <a style={{...styles.footerInner, ...(props.backgroundColor ? {backgroundColor: props.backgroundColor} : {})}}>
        <img style={styles.footerImage} src={require('./assets/oakwood_logo_small.png')} alt='oakwood logo' />
        Powered by Oakwood Software Consulting, Inc. © {dateFnsFormat(new Date(), 'yyyy')}
      </a>
      <span>
        <Legal isOpen={window.location.hash === '#terms_and_privacy'} />
      </span>
    </footer>
  );
};

Footer.propTypes = {
  backgroundColor: PropTypes.string,
  onClick: PropTypes.func,
};

export default Footer;
