/*
 * Ryan O'Dowd
 * 2020-12-30
 * © Copyright 2020 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import DownloadLinks from '../../DownloadLinks';
import Globals from '../../../Globals';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const PlansDialog = (props) => {
  return (
    <Dialog
      open={props.open}
      style={styles.container}
      aria-labelledby='alert-dialog-title-todo'
      aria-describedby='alert-dialog-description-todo'
      fullScreen={Globals.isMobile}
      onClose={props.onClose}
    >
      <DialogTitle id='alert-dialog-title-todo'>
        Plan information
      </DialogTitle>
      <DialogContent>
        <p>{"We're committed to bringing you the best possible experience and are working hard to bring the web version of Tibbits up to speed with the mobile apps for iOS and Android."}</p>
        <span style={styles.downloadLinks}><DownloadLinks /></span>
        <p><strong>While Tibbits for web is in beta, it is free to use for all users.</strong>  (Standard/Premium limits still apply.)</p>
        <p>{"If you notice anything that doesn't work as expected, or if you have an idea for a new feature or any general feedback on your experience, we'd love to hear about it!  Please use the feedback-chat feature to drop us a line."}</p>{/* @TODO: make "feedback-chat feature" clickable */}
        <p>{"Thank you so much for using Tibbits.  We're a small company with a passion for developing tools that help to connect people in the real world.  Your use of Tibbits and word-of-mouth advertising from users who love Tibbits is what keeps us motivated to write the best possible software.  We sincerely appreciate your support.  Thanks for choosing Tibbits!"}</p>
        {/* @TODO: more content here */}
        {/* @TODO: monthly/annual plan options */}
        {/* @TODO: should be able to change plans, pay, etc. here */}
      </DialogContent>
      <DialogActions>
        {/* @TODO: be consistent about button variants site-/app-wide */}
        <Button
          color='primary'
          autoFocus={false}
          onClick={props.onClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PlansDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default PlansDialog;
