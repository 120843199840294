/*
 * Ryan O'Dowd
 * 2021-12-16
 * © Copyright 2021 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  Button,
  TextField,
} from '@material-ui/core';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  fetchFsiExhibitors,
  saveFsiAnalytic,
  saveFsiContact,
} from '../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import Footer from '../../common/components/OakFooter';
import Globals from '../../Globals';
import Header from '../../components/Header';
import ProjectCard from './ProjectCard';
import PropTypes from 'prop-types';
import Radium from 'radium';
import styles from './styles';

const FsiForm = (props) => {
  const dispatch = useDispatch();

  const [_firstNameText, setFirstNameText] = useState('');
  const [_lastNameText, setLastNameText] = useState('');
  const [_emailText, setEmailText] = useState('');
  const [_titleText, setTitleText] = useState('');
  const [_phoneText, setPhoneText] = useState('');
  const [_schoolNameText, setSchoolNameText] = useState('');
  // @TODO: const [_messageText, setMessageText] = useState('');
  const [_formSubmitted, setFormSubmitted] = useState(false);

  const exhibitor = useSelector((state) => state.fsiExhibitors.find((e) => e.slug === props.match.params.exhibitor));

  useEffect(() => {
    dispatch(fetchFsiExhibitors());
    // @TODO: dispatch(fetchExhibitorInfo(props.match.params.event, props.match.params.exhibitor));
  }, [dispatch]);

  useEffect(() => {
    if (exhibitor) {
      dispatch(saveFsiAnalytic(exhibitor.id, 'page_view'));
    }
  }, [dispatch, exhibitor]);

  if (!exhibitor) {
    return (
      <div style={styles.outerContainer}>
        <Header
          hideLogo={true}
          hideButtons={true}
          title='FSi Charlotte'
        />
        <div style={styles.container}>
          <div style={styles.exhibitorMetadata}>
            <span style={styles.exhibitorName}>Loading...</span>
          </div>
        </div>
        <Footer backgroundColor={Globals.colors.primary} />
      </div>
    );
  }

  return (
    <div style={styles.outerContainer}>
      <Header
        hideLogo={true}
        hideButtons={true}
        title='FSi Charlotte'
      />
      <div style={styles.container}>
        <div style={styles.exhibitorMetadata}>
          {exhibitor.website_url
            ? (
              <span
                style={{...styles.exhibitorName, ...styles.link}}
                onClick={() => {
                  dispatch(saveFsiAnalytic(exhibitor.id, 'exhibitor_website_click'));
                  window.location = exhibitor.website_url;
                }}
              >
                {exhibitor.name}
              </span>
            ) : <span style={styles.exhibitorName}>{exhibitor.name}</span>
          }
        </div>

        <div style={styles.formWrapper}>
          {_formSubmitted
            ? (
              <div>
                <p style={styles.thanksText}>{`Your info has been saved and will be sent to ${exhibitor.name}.`}</p>
                <div style={styles.oakwoodAd}>
                  {Math.random() < 1.0 // @TODO: should tibbits ever be shown
                    ? (
                      <ProjectCard
                        name='Wrinkly'
                        url='https://wrink.ly'
                        tagline='Bible memory.'
                        imgUrl='/img/wrinkly.png'
                        comingSoon={true}
                        iosLink={Globals.apps.wrinkly.ios.storeUrl}
                        androidLink={Globals.apps.wrinkly.android.storeUrl}
                        exhibitor={exhibitor}
                        // @TODO: comingSoon={true}
                      >
                        <p style={styles.description}>Wrinkly aims to make Scripture memory as effective and engaging as possible.  Using multiple strategies for learning and memorization, Wrinkly is a fun and powerful tool.</p>
                      </ProjectCard>
                    ) : (
                      <ProjectCard
                        name='Tibbits'
                        url='https://tibbits.oakwoodsc.com'
                        tagline='Remember names and other tidbits.'
                        imgUrl='/img/tibbits.png'
                        comingSoon={true}
                        iosLink={Globals.apps.tibbits.ios.storeUrl}
                        androidLink={Globals.apps.tibbits.android.storeUrl}
                        exhibitor={exhibitor}
                      >
                        <p style={styles.description}>{"Be more mindful of new relationships and strengthen existing ones. Quickly jot down a person's name and a few notes about your conversation, and assign labels to stay organized. Need to pull up a person's info on the fly? Search by name, label, or notes to find someone lickety-split!"}</p>
                      </ProjectCard>
                    )
                  }
                </div>
              </div>
            ) : (
              <React.Fragment>
                <div style={styles.row}>
                  <TextField
                    style={styles.formField}
                    label='First name'
                    variant='outlined'
                    required={true}
                    value={_firstNameText}
                    onChange={(e) => setFirstNameText(e.target.value)}
                  />
                  <TextField
                    style={styles.formField}
                    label='Last name'
                    variant='outlined'
                    required={true}
                    value={_lastNameText}
                    onChange={(e) => setLastNameText(e.target.value)}
                  />
                </div>
                <div style={styles.row}>
                  <TextField
                    style={styles.formField}
                    label='Email address'
                    type='email'
                    variant='outlined'
                    required={true}
                    value={_emailText}
                    onChange={(e) => setEmailText(e.target.value)}
                  />
                </div>
                <div style={styles.row}>
                  <TextField
                    style={styles.formField}
                    label='School name'
                    variant='outlined'
                    required={true}
                    value={_schoolNameText}
                    onChange={(e) => setSchoolNameText(e.target.value)}
                  />
                </div>
                <div style={styles.row}>
                  <TextField
                    style={styles.formField}
                    label='Title'
                    variant='outlined'
                    required={true}
                    value={_titleText}
                    onChange={(e) => setTitleText(e.target.value)}
                  />
                </div>
                <div style={styles.row}>
                  <TextField
                    style={styles.formField}
                    label='Phone number'
                    variant='outlined'
                    required={false}
                    value={_phoneText}
                    onChange={(e) => setPhoneText(e.target.value)}
                  />
                </div>
                {/* @TODO:
                <div style={styles.row}>
                  <TextField
                    style={styles.formField}
                    label='Message'
                    variant='outlined'
                    multiline={true}
                    required={false}
                    minRows={4}
                    value={_messageText}
                    onChange={(e) => setMessageText(e.target.value)}
                  />
                </div>
                */}
                <div style={styles.row}>
                  <Button
                    style={styles.formField}
                    variant='contained'
                    color='primary'
                    disabled={!(_firstNameText && _lastNameText && _emailText && _schoolNameText && _titleText)}
                    onClick={() => {
                      // @TODO: form validation for required fields (all optional for v1)
                      dispatch(saveFsiContact(exhibitor.id, _firstNameText, _lastNameText, _emailText, _schoolNameText, _titleText, _phoneText));
                      dispatch(saveFsiAnalytic(exhibitor.id, 'form_submit'));
                      setFormSubmitted(true);
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </React.Fragment>
            )
          }
        </div>
      </div>
      <Footer
        backgroundColor={Globals.colors.primary}
        onClick={() => dispatch(saveFsiAnalytic(exhibitor.id, 'footer_clicked'))}
      />
    </div>
  );
};

FsiForm.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Radium(FsiForm);
