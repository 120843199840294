/**
 * Ryan O'Dowd
 * 2021-09-16
 * © Copyright 2021 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  firebase,
  firestore,
} from './Globals';
import oakGlobalObject from './common/utilities/oakGlobalObject';

// @NOTE: the functions in this file are here because they're used in PlatformUtilities and cause require cycles if they stay in utilities.js @TODO: get rid of the need for this file...should be able to put in normal utilities.js

function updateTidbitForPerson(personKey, personTidbitKey, attributes) {
  if (!personTidbitKey) {
    personTidbitKey = firestore.peopleCollection.doc(personKey).collection('tidbits').doc().id;
  }

  if (attributes.value === '') {
    // if value is null, this should delete the tidbit for this person instead of updating the value
    firestore.peopleCollection.doc(personKey).collection('tidbits').doc(personTidbitKey).delete();
    const globalReduxStore = require('./Store').store.getState();
    const reminderKey = globalReduxStore.reminders[personTidbitKey]?.key;
    if (reminderKey) {
      firestore.remindersCollection.doc(reminderKey).delete();
    }
    // @TODO: if this is a photo, delete it from firebase storage
  } else {
    firestore.peopleCollection.doc(personKey).collection('tidbits').doc(personTidbitKey).set({
      ...attributes,
      lastUpdatedTimestamp: new Date().getTime(),
    }, {merge: true});
  }

  // update lastUpdatedTimestamp for this person
  firestore.peopleCollection.doc(personKey).update({
    lastUpdatedTimestamp: new Date().getTime(),
  });

  firebase.analytics().logEvent('change_person_tidbit_value');
}

function updatePerson(personKey, attributes) {
  const batch = firebase.firestore().batch();
  batch.update(firestore.peopleCollection.doc(personKey), {
    ...attributes,
    lastUpdatedTimestamp: new Date().getTime(),
  });

  if (Object.keys(attributes).includes('isActive')) {
    // disable all reminders for this person
    const globalReduxStore = require('./Store').store.getState();
    const personNotes = globalReduxStore.notes2[personKey] || [];
    personNotes?.forEach((note) => {
      const reminder = globalReduxStore.reminders[note.key];
      if (reminder) {
        batch.update(firestore.remindersCollection.doc(reminder.key), {
          isActive: false,
        });
      }
    });
    const personTidbits = globalReduxStore.personTidbits[personKey] || [];
    Object.values(personTidbits)?.forEach((tidbit) => {
      const reminder = globalReduxStore.reminders[tidbit.key];
      if (reminder) {
        batch.update(firestore.remindersCollection.doc(reminder.key), {
          isActive: false,
        });
      }
    });
  }

  // @TODO: if this is photoUrl that is null, delete from firebase storage

  batch.commit();
  firebase.analytics().logEvent('update_person', {
    attributes: Object.keys(attributes),
  });
}

function updateNameTag(nameTagKey, attributes) {
  // @TODO: if this has photoUrl that is null, delete from firebase storage
  firestore.nameTagsCollection.doc(nameTagKey).update({
    ...attributes,
    lastUpdatedTimestamp: new Date().getTime(),
  });

  firebase.analytics().logEvent('update_name_tag', {
    attributes: Object.keys(attributes),
  });
}

export default oakGlobalObject({
  updateNameTag,
  updatePerson,
  updateTidbitForPerson,
});
