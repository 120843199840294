/*
 * Ryan O'Dowd
 * 2021-03-06
 * © Copyright 2021 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga4';
import styles from './styles';

const DownloadLinks = (props) => {
  const _handleClick = (store) => {
    ReactGA.event({
      category: 'Download button clicked',
      action: `${store} visited`,
    });
  };

  return (
    <div style={{...styles.links, ...(props.size === 'small' ? {width: 210} : {})}}>
      <a
        style={styles.appStoreLink}
        href='https://itunes.apple.com/us/app/tibbits/id1332548860'
        onClick={() => _handleClick('app store')}
      >
        <img alt='iOS download' src='/img/apple_app_store_icon.svg' width={props.size === 'small' ? '101' : '135'} height={props.size === 'small' ? '30' : '40'} />
      </a>
      <a
        style={styles.playStoreLink}
        href='https://play.google.com/store/apps/details?id=com.oakwoodsc.tibbits'
        onClick={() => _handleClick('play store')}
      >
        <img alt='Android download' src='/img/google_play_store_icon.png' width={props.size === 'small' ? '101' : '134'} height={props.size === 'small' ? '30' : '40'} />
      </a>
      {/* @TODO:
      <Link
        style={styles.link}
        to={this.props.webLink}
        onClick={() => { _handleClick(); }}
      >
        <img alt='Web download' src='./img/web_app_icon.png' /> // @TODO: web vs. desktop...
      </Link>
      */}
    </div>
  );
};

DownloadLinks.propTypes = {
  size: PropTypes.string,
};

export default DownloadLinks;
