/*
 * Ryan O'Dowd
 * 2019-05-14
 * © Copyright 2019 NursingABC, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  outerContainer: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 800,
    height: 'calc(100vh - 123px)', // @TODO: dynamic, not hardcoded
    maxWidth: '100%',
    maxHeight: '100%',
    margin: 'auto',
    padding: 16,

    [Globals.mediaQueries.sm]: {
      margin: 0,
      height: 'calc(100vh - 35px - 58px)', // footer and header heights // @TODO: remove calc
    },
  },
  containerInner: {
    minHeight: '100%',
    maxHeight: '100%',
    width: '100%',
  },
  tutorialSteps: {
    marginBottom: 64,
  },
  sectionHeader: {
    margin: 50,
  },
  searchWrapper: {
    display: 'flex',
    paddingLeft: 8,

    [Globals.mediaQueries.sm]: {
      flexDirection: 'column',
      minWidth: 175,
      maxWidth: 175,
    },
  },
  peopleCountText: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: 8,
    color: Globals.colors.white,
    fontSize: 12,
    minWidth: 60,
  },
  image: {
    width: 240,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  imageFade: {
    display: 'block',
    position: 'relative',
    backgroundImage: `linear-gradient(
      rgba(255, 255, 255, 0.0) 0%,
      rgba(255, 255, 255, 0.0) 70%,
      rgba(255, 255, 255, 0.7) 80%,
      rgba(255, 255, 255, 0.9) 90%,
      rgba(255, 255, 255, 1.0) 100%
    )`,
    marginTop: -150,
    height: 150,
    width: '100%',
  },
  bigHr: {
    border: `1px solid ${Globals.colors.accent}`,
    width: '75%',
    maxWidth: 350,
  },
  smallHr: {
    border: `1px solid ${Globals.colors.accent}`,
    width: '35%',
    maxWidth: 270,
  },
  link: {
    margin: 5,
  },

  headerBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Globals.colors.primary,
    color: Globals.colors.white,
  },
  archivedHeaderBar: {
    backgroundColor: Globals.colors.accent,
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'row',
    height: 'calc(100% - 57px)', // @TODO: don't hardcode this...57 is the header height

    [Globals.mediaQueries.sm]: {
      height: 'calc(100% - 35px - 57px)', // footer and header heights // @TODO: remove calc
    },
  },
  namesWrapper: {
    overflowY: 'auto',
    width: 220, // @TODO: dynamic for small screens?
    minWidth: 220, // @TODO: dynamic for small screens?
    maxWidth: 220, // @TODO: dynamic for small screens?
    borderRight: `4px solid ${Globals.colors.primary}`, // @TODO: update

    [Globals.mediaQueries.sm]: {
      borderRight: 'none',
    },
  },
  nameAndPinWrapper: {
    position: 'relative',
  },
  personPreviewWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
    cursor: 'pointer',
    height: 72,
    borderBottom: `1px solid ${Globals.colors.lightGray}`, // @TODO: update
  },
  selectedPersonPreviewWrapper: {
    backgroundColor: `${Globals.colors.primary}44`, // @TODO: make this pop more than it does
  },
  personPin: {
    position: 'absolute',
    top: 4,
    right: 4,
  },
  nameCardWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  photoWrapper: {
    display: 'flex',
    height: 53,
    marginLeft: 8,
  },
  nonPhotoWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  personNameWrapper: {
    display: 'flex',
    width: '100%',
  },
  personName: {
    textAlign: 'left',
    fontWeight: '600',
    fontFamily: Globals.fontFamilies.secondary,
    paddingLeft: 8,
    paddingRight: 8,
    marginBottom: 4,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
    textOverflow: 'ellipsis',
  },
  selectedLabelWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  selectedPersonLabel: {
    padding: 8,
    cursor: 'pointer',
    borderRadius: 16,
  },
  personLabelsWrapper: {
    display: 'flex',
    width: '100%',
  },
  personLabels: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    paddingLeft: 8,
    paddingRight: 8, // @TODO: end of list doesn't have padding
    overflowX: 'auto', // @TODO: hide the scrollbar
    height: 24,
  },
  personLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 4,
    marginRight: 4,
    borderRadius: 12,
    fontSize: 12,
    whiteSpace: 'nowrap',
    color: Globals.colors.white,
    backgroundColor: Globals.colors.accent,
  },
  labelRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dialog: {
    textAlign: 'left',
  },
  reminderDetailsWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  allRemindersWrapper: {
  },
  reminderDetailRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: Globals.colors.primaryLight,
    color: Globals.colors.white,
    paddingLeft: 8,
    paddingRight: 8,
    borderBottomRightRadius: 4, // @TODO: same as paper
    borderBottomLeftRadius: 4, // @TODO: same as paper
  },
  whiteTextField: {
    color: Globals.colors.white,
    // @TODO: border too
  },
};

export default styles;
