/*
 * Ryan O'Dowd
 * 2019-06-08
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals, {
  firebase,
  firestore,
} from '../../../Globals';
import React, {
  useEffect,
} from 'react';
import ContactForm from '../../../common/components/ContactForm';
import DownloadLinks from '../../../components/DownloadLinks';
import Footer from '../../../common/components/OakFooter';
import Grid from '@material-ui/core/Grid';
import Header from '../../../components/Header';
import PropTypes from 'prop-types';
import {
  setUser,
} from '../../../actions';
import styles from './styles';
import {
  useDispatch,
} from 'react-redux';

const TutorialStep = (props) => {
  return (
    <Grid item xs={12} md={4} style={styles.tutorialStep}>
      <img style={{...styles.image, ...(props.height ? {height: props.height} : {})}} src={props.src} alt={props.alt} />
      <span style={styles.imageFade} />
      <p>{props.caption}</p>
      <hr style={styles.bigHr} />
      <hr style={styles.smallHr} />
    </Grid>
  );
};

TutorialStep.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,

  height: PropTypes.number,
};

const LandingPage = (props) => {
  const dispatch = useDispatch();

  useEffect(() => { // @TODO: usnsubscribe? should we return from this useEffect
    firebase.auth().getRedirectResult().then((result) => {
      if (result.user) {
        firebase.analytics().logEvent('login', { // @TODO: abstract with other calls to robots
          displayName: result.user.displayName,
          authType: result.credential.providerId.replace('.com', ''), // google.com, facebook.com
        });
        firestore.setUser(result.user.uid);
        dispatch(setUser(result.user));
      }
    }).catch((error) => {
      // @TODO: better error handling
      console.error(error); // eslint-disable-line no-console
    });
  }, [dispatch]);

  return (
    <div style={styles.outerContainer}>
      <Header />
      <div style={styles.container}>
        <h1 style={styles.sectionHeader}>Remember names and other tidbits.</h1>

        <div style={styles.tutorialSteps}>
          <Grid container>
            <TutorialStep
              src='/img/home.png'
              alt='names'
              caption='Enter names of people you meet.'
            />
            <TutorialStep
              src='/img/labels.png'
              alt='labels'
              caption='Add a label or two describing where you met.'
            />
            <TutorialStep
              src='/img/notes.png'
              alt='notes'
              caption='Save notes of conversations to remember later.'
            />
          </Grid>
        </div>

        <p>Be more mindful, starting today.</p>
        <span style={styles.dowloadLinks}>
          <DownloadLinks />
        </span>

        <hr />

        <h1 style={styles.sectionHeader}>Conferences and events</h1>

        <div style={styles.tutorialSteps}>
          <Grid container>
            <TutorialStep
              src='/img/sessions.png'
              alt='sessions'
              caption='View event schedules, speaker bios, and exhibitor information.'
              height={450}
            />
            <TutorialStep
              src='/img/name_tags.png'
              alt='name tags'
              caption='Create your own digital business cards, and swap information faster.'
              height={450}
            />
            <TutorialStep
              src='/img/qr.png'
              alt='QR scans'
              caption='Scan name tags of attendees and exhibitors for more efficient networking.'
              height={450}
            />
          </Grid>
        </div>

        <Grid container style={styles.contactWrapper}>
          <Grid item xs={12} md={6} style={styles.contactWrapperLeft}>
            <ContactForm
              leadingText='For more information, including pricing, please send us a message.'
              textStyles={styles.contactText}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <span style={styles.contactDowloadLinks}>
              <DownloadLinks />
            </span>
          </Grid>
        </Grid>

      </div>
      <Footer backgroundColor={Globals.colors.primary} />
    </div>
  );
};

export default LandingPage;
