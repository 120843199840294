/*
 * Ryan O'Dowd
 * 2022-12-26
 * © Copyright 2022 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import oakGlobalObject from '../oakGlobalObject';

function recordError(error) {
  // @TODO: implement
  console.error(error);
}

function linkUserAndCredential() {
  // @TODO: implement
}

function isIphoneWithCutout() {
  // @TODO: implement
}

function getHeaderHeight() {
  // @TODO: implement
}

function getStatusBarHeight() {
  // @TODO: implement
}

function rebuildEStyleSheet() {
  // @TODO: implement
}

// @MARK: hooks
function useBackButton() {
  // @TODO: implement
}

function useStyles() {
  // @TODO: implement
}

export default oakGlobalObject({
  getHeaderHeight,
  getStatusBarHeight,
  isIphoneWithCutout,
  linkUserAndCredential,
  recordError,
  useBackButton,
  rebuildEStyleSheet,
  useStyles,
});
