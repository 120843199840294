/**
 * Ryan O'Dowd
 * 2018-02-24
 * © Copyright 2018 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  DELETE,
  GET,
  PATCH,
  POST,
  fetchChatMessages,
  fetchWhatsNew,
  getFetchTypes,
  getRequestHeaders,
  logout,
  markAllMessagesRead, saveFcmToken,
  saveInfoToRedux,
  sendChatMessage,
  sendMessage,
  setFeedbackChatDraftText,
  setLightDarkMode,
  setWhatsNewLastReadTimestamp,
  setWhatsNewLastReadVersion,
  updateLoadingState,
} from './common/actions';
import Globals from './Globals';
import {
  RSAA,
} from 'redux-api-middleware';

// action types
export const SET_USER = 'SET_USER';
export const SET_OAK_USER = 'SET_OAK_USER';
export const SET_PEOPLE = 'SET_PEOPLE';
export const SET_NAME_TAGS = 'SET_NAME_TAGS';
export const SET_NAME_TAG_NOTES = 'SET_NAME_TAG_NOTES';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_PENDING_PEOPLE = 'SET_PENDING_PEOPLE';
export const SET_PENDING_PEOPLE_NOTES = 'SET_PENDING_PEOPLE_NOTES';
export const SET_LABELS = 'SET_LABELS';
export const SET_MESSAGES = 'SET_MESSAGES';
export const SET_NOTES = 'SET_NOTES';
export const SET_PERSON_TIDBITS = 'SET_PERSON_TIDBITS';
export const SET_EVENTS = 'SET_EVENTS';
export const SET_TIDBITS = 'SET_TIDBITS';
export const DELETE_NOTES_FOR_PERSON = 'DELETE_NOTES_FOR_PERSON';
export const SET_PEOPLE_SORT_METHOD = 'SET_PEOPLE_SORT_METHOD';
export const SET_REMINDERS_SORT_METHOD = 'SET_REMINDERS_SORT_METHOD';
export const SET_PERIODIC_REMINDER_ENABLED = 'SET_PERIODIC_REMINDER_ENABLED';
export const SET_ACCOUNT_PROTECTION_ENABLED = 'SET_ACCOUNT_PROTECTION_ENABLED';
export const SET_REMINDERS = 'SET_REMINDERS';
export const SET_LAST_NOTES_SYNC_TIMESTAMP = 'SET_LAST_NOTES_SYNC_TIMESTAMP';
export const SET_SUBSCRIPTION_INFO = 'SET_SUBSCRIPTION_INFO';
export const SET_TIMESTAMP_LAST_SHOWN_PREMIUM_DIALOG = 'SET_TIMESTAMP_LAST_SHOWN_PREMIUM_DIALOG';
export const SET_FSI_EXHIBITORS = 'SET_FSI_EXHIBITORS';

// action enums
export const PEOPLE_SORT_METHODS = [
  'dateCreated',
  'dateModified',
  'alphabetically',
];
export const REMINDERS_SORT_METHODS = [
  'upcoming',
  'person',
];

// navigation routes
export const FEEDBACK_CHAT_ROUTE = 'FeedbackChat';
export const DETAILS_ROUTE = 'Details';
export const APP_ROUTE = 'App';
export const ABOUT_ROUTE = 'About';
export const HELP_ROUTE = 'Help';
export const SETTINGS_ROUTE = 'Settings';
export const MANAGE_LABELS_ROUTE = 'ManageLabels';
export const LINK_ACCOUNT_ROUTE = 'LinkAccount';
export const REMINDERS_ROUTE = 'Reminders';
export const LABELS_ROUTE = 'ManageLabels';
export const TIDBITS_ROUTE = 'ManageTidbits';
export const EDIT_NOTE_ROUTE = 'EditNote';
export const SUBSCRIPTION_ROUTE = 'ManageSubscription';
export const EVENTS_ROUTE = 'Events';
export const EVENT_ROUTE = 'Event';
export const NAME_TAGS_ROUTE = 'NameTags';
export const WHATS_NEW_ROUTE = 'WhatsNew';

// @MARK: firestore
export function setUser(user) {
  return {
    type: SET_USER,
    user,
  };
}

export function setNameTags(nameTags) {
  return {
    type: SET_NAME_TAGS,
    nameTags,
  };
}

export function setNameTagNotes(notes, nameTagKey) {
  return {
    type: SET_NAME_TAG_NOTES,
    notes,
    nameTagKey,
  };
}

export function setPendingPeople(pendingPeople) {
  return {
    type: SET_PENDING_PEOPLE,
    pendingPeople,
  };
}

export function setPendingPersonNotes(notes, pendingPersonKey) {
  return {
    type: SET_PENDING_PEOPLE_NOTES,
    notes,
    pendingPersonKey,
  };
}

export function setPeople(people) {
  return {
    type: SET_PEOPLE,
    people,
  };
}

export function setNotifications(notifications) {
  return {
    type: SET_NOTIFICATIONS,
    notifications,
  };
}

export function setLabels(labels) {
  return {
    type: SET_LABELS,
    labels,
  };
}

export function setTidbits(tidbits) {
  return {
    type: SET_TIDBITS,
    tidbits,
  };
}

export function setPersonTidbits(personTidbits, personKey) {
  return {
    type: SET_PERSON_TIDBITS,
    personTidbits,
    personKey,
  };
}

export function setNotes(notes, personKey) {
  return {
    type: SET_NOTES,
    notes,
    personKey,
  };
}

export function deleteNotesForPerson(personKey) {
  return {
    type: DELETE_NOTES_FOR_PERSON,
    personKey,
  };
}

export function setReminders(reminders) {
  return {
    type: SET_REMINDERS,
    reminders,
  };
}

// @MARK: settings
export function setPeopleSortMethod(sortMethod) {
  return {
    type: SET_PEOPLE_SORT_METHOD,
    sortMethod,
  };
}

export function setRemindersSortMethod(sortMethod) {
  return {
    type: SET_REMINDERS_SORT_METHOD,
    sortMethod,
  };
}

export function setPeriodicReminderEnabled(isEnabled) {
  return {
    type: SET_PERIODIC_REMINDER_ENABLED,
    isEnabled,
  };
}

export function setAccountProtectionEnabled(isEnabled) {
  return {
    type: SET_ACCOUNT_PROTECTION_ENABLED,
    isEnabled,
  };
}

export function setLastNotesSyncTimestamp(documentKey) {
  return {
    type: SET_LAST_NOTES_SYNC_TIMESTAMP,
    documentKey,
  };
}

export function setSubscriptionInfo(isActive, isGracePeriod, productIdentifier, expirationDate, willRenew, isPromotional) {
  return {
    type: SET_SUBSCRIPTION_INFO,
    isActive,
    isGracePeriod,
    productIdentifier,
    expirationDate,
    willRenew,
    isPromotional,
  };
}

export function setTimestampLastShownPremiumDialog(timestamp) {
  return {
    type: SET_TIMESTAMP_LAST_SHOWN_PREMIUM_DIALOG,
    timestamp,
  };
}

// @MARK: api calls
export function fetchUser(displayName) { // @TODO: put in common/actions?
  const ACTION_CREATOR_NAME = 'fetchUser';
  let getArgs = '';
  if (displayName) {
    getArgs = `?display_name=${displayName}`;
  }
  const endpoint = `${Globals.apiUrl}/user${getArgs}`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_OAK_USER),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function fetchFsiExhibitors() {
  const ACTION_CREATOR_NAME = 'fetchFsiExhibitors';
  const endpoint = `${Globals.apiUrl}/fsi/exhibitors`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_FSI_EXHIBITORS),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function saveFsiContact(fsiExhibitorId, firstName, lastName, email, schoolName, title, phone) {
  const endpoint = `${Globals.apiUrl}/fsi/contacts`;
  const formData = new FormData();
  formData.append('fsi_exhibitor_id', fsiExhibitorId);
  formData.append('first_name', firstName);
  formData.append('last_name', lastName);
  formData.append('email', email);
  formData.append('school_name', schoolName);
  formData.append('title', title);
  formData.append('phone', phone);
  return async (dispatch, getState) => {
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, ''),
      },
    });
  };
}

export function saveFsiAnalytic(fsiExhibitorId, eventType) {
  const endpoint = `${Globals.apiUrl}/fsi/analytics`;
  const formData = new FormData();
  formData.append('fsi_exhibitor_id', fsiExhibitorId);
  formData.append('event_type', eventType);
  return async (dispatch, getState) => {
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, ''),
      },
    });
  };
}

export function joinEvent(joinCode, callback) {
  const endpoint = `${Globals.apiUrl}/events`;
  const formData = new FormData();
  formData.append('join_code', joinCode);
  return async (dispatch, getState) => {
    const response = await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_EVENTS),
      },
    });
    callback(response.error, response.payload.message);
  };
}

export function deleteEvent(eventId) {
  const endpoint = `${Globals.apiUrl}/events/${eventId}`;
  return async (dispatch, getState) => {
    dispatch({
      [RSAA]: {
        endpoint,
        method: DELETE,
        body: null,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_EVENTS),
      },
    });
  };
}

export function fetchEvents() {
  const endpoint = `${Globals.apiUrl}/events`;
  return async (dispatch, getState) => {
    dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_EVENTS),
      },
    });
  };
}

export function toggleSessionStarredStatus(eventId, sessionId, star) {
  const endpoint = `${Globals.apiUrl}/events/${eventId}/sessions/${sessionId}/starred`;
  return async (dispatch, getState) => {
    dispatch({
      [RSAA]: {
        endpoint,
        method: star ? PATCH : DELETE,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_EVENTS),
      },
    });
  };
}

export {
  fetchChatMessages,
  fetchWhatsNew,
  logout,
  markAllMessagesRead,
  saveFcmToken,
  saveInfoToRedux,
  sendChatMessage,
  sendMessage,
  setFeedbackChatDraftText,
  setLightDarkMode,
  setWhatsNewLastReadTimestamp,
  setWhatsNewLastReadVersion,
};
