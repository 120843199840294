/**
 * Ryan O'Dowd
 * 2022-12-14
 * © Copyright 2022 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import en from 'date-fns/locale/en-US'; // @TODO: support more englishes?
import es from 'date-fns/locale/es';

const i18n = (lang) => { // @TODO: this should be an object that throws an error if a key is missing (one of the supported languages)
  const words = {
    feedbackEmptyScreen: {
      en: "Have an idea for the app? Something not working correctly? Send us a message; we'd love to chat!",
    },
    feedbackChatDisabled: {
      en: 'Live support is currently unavailable. Please contact support@tibbits.me for questions or feedback.',
    },
    newMessage: {
      en: 'New message',
    },
    whatsNew: {
      en: "What's new",
    },
    feedbackChat: {
      en: 'Feedback chat',
    },
    signInWith: {
      en: 'Sign in with',
    },
    linkWith: {
      en: 'Link with',
    },
    email: {
      en: 'Email',
    },
    phoneNumberButton: {
      en: 'Phone Number',
    },
    guestConfirmation: {
      en: 'Guest confirmation',
    },
    areYouSureGuest: {
      en: 'Are you sure you want to continue as a guest?  If you sign in with another provider, your data will be synced across all your devices.',
    },
    takeMeBack: {
      en: 'Take me back',
    },
    continueAsGuest: {
      en: 'Continue as guest',
    },
    getStarted: {
      en: 'Get started',
    },
    weRespect: {
      en: 'We respect your privacy.  Linking an account is used for authentication purposes and not for remarketing of any kind.',
    },
    privacyAndTerms: {
      en: 'Privacy & Terms of Service',
    },
    dataNotSynced: {
      en: 'Your data will not be synced between devices while signed in as a guest.',
    },
    sendLinkToInbox: {
      en: 'Send link to inbox',
    },
    sendSignInLink: {
      en: 'Send sign-in link',
    },
    noNeedToRemeberPassword: {
      en: "No need to remember a password; we'll send a login link to your inbox!",
    },
    confirmCode: {
      en: 'Confirm code',
    },
    sendConfirmationCode: {
      en: 'Send confirmation code',
    },
    firstName: {
      en: 'First name',
    },
    lastName: {
      en: 'Last name',
    },
    emailAddress: {
      en: 'Email address',
    },
    phoneNumber: {
      en: 'Phone number',
    },
    unknownError: {
      en: 'An unknown error occurred; please try again.',
    },
    invalidLink: {
      en: 'Invalid link; please be sure to click on the most recent link in your inbox and that the link has not already been used.',
    },
    invalidEmailError: {
      en: 'Invalid email address',
    },
    incompleteFormError: {
      en: 'Please fill out all fields and try again.',
    },
    invalidCodeError: {
      en: 'Incorrect code; please try again.',
    },
    networkError: {
      en: 'Network error; please ensure you are connected to the internet and try again.',
    },
    credentialError: {
      en: 'Could not sign in with credential provided.  Please try another authentication provider or try again.',
    },
    alreadyLinkedError: {
      en: "Account already linked to an identity provider; can't link to a second one.",
    },
    updateNow: {
      en: 'Update now',
    },
  };

  const localeWords = {}; // @TODO: oak frozen object?
  Object.entries(words)?.forEach(([key, value]) => {
    localeWords[key] = value[lang];
    /* @TODO:
    const styles = {
      debug: {
        color: 'teal',
        backgroundColor: 'lightgreen',
      },
    };

    if (['usernameOrDisplayName', 'newMessage', 'nameOfGoal', 'deleteGoalTitle', 'deleteGoalBody', 'deleteGoalConfirm', 'deleteGoalCancel', 'book', 'notesOptional', 'code'].includes(key)) {
      // these values have to be strings (probably TextInput placeholder prop or for Alert)
      localeWords[key] = value[lang];
    } else if (typeof value[lang] === 'function') {
      localeWords[key] = (...args) => <Text style={styles.debug}>{value[lang](...args)}</Text>;
    } else if (typeof value[lang] === 'object') {
      const newValues = {};
      Object.entries(value[lang])?.forEach(([k, v]) => {
        newValues[k] = <Text style={styles.debug}>{v}</Text>;
      });
      localeWords[key] = newValues;
    } else {
      localeWords[key] = <Text style={styles.debug}>{value[lang]}</Text>; // @TODO: debug only (functions don't work here)
    }
    */
  });

  switch (lang) {
  case 'es': {
    localeWords.locale = es;
    break;
  }
  default: {
    localeWords.locale = en;
  }
  }

  return localeWords;
};

export default i18n;
