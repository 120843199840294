/**
 * Ryan O'Dowd
 * 2021-01-03
 * © Copyright 2020 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import i18nImport, {
  ALL_APPS,
} from './Globals';
import DownloadLinks from '../../components/DownloadLinks';
import Globals from '../../Globals';
// @TODO: import LinearGradient from 'react-native-linear-gradient';
import PropTypes from 'prop-types';
import React from 'react';
import {
  format as dateFnsFormat,
} from 'date-fns';
import styles from './styles';

const i18n = i18nImport('web');

export default class About extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    /* @TODO: abstract share and explore to remove duplicate code
    */
    this.shareButtons = this.getShareOptions().map((shareOption) => {
      return (
        <React.Fragment key={shareOption.name}>
          {shareOption.verticalDividerBefore && <span style={styles.verticalDivider} />}
          <span style={styles.buttonAndTextWrapper}>
            {/* @TODO:
            <IconButton
              style={styles.buttonImageWrapper}
              onClick={shareOption.onPress}
            >
              {/ @TODO: share via facebook, twitter, etc. but just copy platform-agnostic url to clipboard so they can share manually (have message about how we don't want to spam) /}
              {shareOption.image
                ? (
                  <Image
                    style={styles.buttonImage}
                    resizeMode='cover'
                    source={shareOption.image}
                  />
                ) : (
                  <MaterialCommunityIcon
                    style={[styles.buttonImage, styles.buttonIcon, shareOption.color && {color: shareOption.color}]}
                    name={shareOption.iconName}
                  />
                )
              }
            </IconButton>
            */}
            <p
              style={styles.buttonNameText}
              numberOfLines={1}
            >
              {shareOption.name}
            </p>
          </span>
          {shareOption.verticalDividerAfter && <span style={styles.verticalDivider} />}
        </React.Fragment>
      );
    });

    this.otherAppButtons = ALL_APPS('web').map((app) => {
      /* @TODO:
      let buttonChildren = null;
      if (!app.isEmpty) {
        buttonChildren = (app.image
          ? (
            <Image
              style={styles.buttonImage}
              resizeMode='cover'
              source={app.image}
            />
          ) : (
            <MaterialCommunityIcon
              style={[styles.buttonImage, styles.buttonIcon]}
              name={app.iconName}
            />
          )
        );
      }
      */

      return (
        <React.Fragment key={app.name}>
          {app.verticalDividerBefore && <span style={styles.verticalDivider} />}
          <span style={[styles.buttonAndTextWrapper, app.isEmpty && styles.buttonAndTextWrapperEmpty]}>
            {/* @TODO:
            <TouchableOpacity
              style={styles.buttonImageWrapper}
              onPress={() => {/* @TODO: !app.isEmpty && Linking.openURL(app.link) /}}
            >
              {buttonChildren}
            </TouchableOpacity>
            <p
              style={styles.buttonNameText}
              numberOfLines={1}
            >
              {!app.isEmpty && app.name}
            </p>
            */}
          </span>
          {app.verticalDividerAfter && <span style={styles.verticalDivider} />}
        </React.Fragment>
      );
    });
  }

  getShareOptions() {
    return [
      {
        name: 'Share',
        onPress: () => {
          // @TODO: analytics
          // @TODO: update text (and copy to oakwoodsc.com too)...put in Globals.js
          /* @TODO:
          Share.share({
            message: i18n.shareOptions.message,
            url: i18n.shareOptions.storeLink,
            title: i18n.shareOptions.title,
          }, {
            // Android only:
            dialogTitle: i18n.shareOptions.dialogTitle,
          });
          */
        },
        iconName: 'share',
      },
      {
        name: 'Copy Link',
        onPress: () => {
          // @TODO: indicate that the text is copied (toast or something...)
          // @TODO: analytics
          // @TODO: Clipboard.setString(i18n.shareOptions.storeLink);
        },
        iconName: 'link-variant',
      },
      {
        name: 'Rate',
        onPress: () => {
          // @TODO: Linking.openURL(i18n.shareOptions.storeLink);
        },
        // @TODO: analytics
        iconName: 'star',
        color: '#FFC125',
      },
    ];
  }

  render() {
    return (
      <Dialog
        open={true}
        style={styles.dialog}
        aria-labelledby='alert-dialog-title-todo'
        aria-describedby='alert-dialog-description-todo'
        fullScreen={Globals.isMobile}
        onClose={() => this.props.onClose()}
      >
        <DialogTitle id='alert-dialog-title-todo'>
          About Us
        </DialogTitle>
        <DialogContent>
          {/* @TODO: about tibbits */}
          {/* @TODO: faqs/tutorial */}
          <div style={styles.aboutContainer}>
            <h3 style={{...styles.headerText, ...styles.firstHeader}}>{i18n.whoWeAreSection.title}</h3>
            <div style={{...styles.imageTextWrapper, ...styles.rightBorder}}>
              <span>{i18n.whoWeAreSection.content}</span>
              <img src='./img/oakwood_logo.png' alt='oakwood' style={styles.logo} />
            </div>
          </div>
          <div style={{...styles.imageTextWrapper, ...styles.leftBorder}}>
            <span style={styles.userImageWrapper}>
              <img src='/img/rodowd_web.jpg' alt='rodowd' style={styles.userImage} />
            </span>
            <span style={styles.developerText}>{i18n.whoWeAreSection.rodowd}</span>
          </div>
          <div style={{...styles.imageTextWrapper, ...styles.leftBorder}}>
            <span style={styles.userImageWrapper}>
              <img src='./img/btraut_web.jpg' alt='btraut' style={styles.userImage} />
            </span>
            <span style={styles.developerText}>{i18n.whoWeAreSection.btraut}</span>
          </div>

          <h3 style={styles.headerText}>{i18n.aboutGeneral.title}</h3>
          <p>{i18n.aboutGeneral.p1}</p>
          <p>{i18n.aboutGeneral.p2}</p>

          <h3 style={styles.headerText}>Tibbits mobile downloads</h3>
          <DownloadLinks />

          {/* @TODO: abstract share and explore to remove duplicate code
          <div>
            <h3 style={styles.headerText}>Share</h3>
            <div
              style={styles.actions}
              horizontal={true}
            >
              {/* @TODO: shouldn't ahve to specify width here... /}
              {/* @TODO: implement
              <div style={{width: 3 * 68, flexDirection: 'row'}}>
                {this.shareButtons}
              </div>
              /}
            </div>
          </div>

          <div>
            <h3 style={styles.headerText}>Explore</h3>
            {/* @TODO: convert to cards
            <ScrollView
              contentContainerStyle={styles.actions}
              horizontal={true}
            >
              {this.otherAppButtons}
            </ScrollView>
            /}
            {/* @TODO: too hard to find the percent finished, so no first one for now:
            <LinearGradient
              style={[styles.linearGradient, styles.linearGradientLeft]}
              start={{x: 0, y: 0}}
              end={{x: 1, y: 0}}
              colors={[Globals.colors.primary, '#ffffff00']}
            />
            /}
            {/* @TODO: add right chevron on top of gradient /}
            {/* @TODO:
            <LinearGradient
              style={[styles.linearGradient, styles.linearGradientRight]}
              start={{x: 0, y: 0}}
              end={{x: 1, y: 0}}
              colors={[
                `${Globals.colors.primary}00`,
                Globals.colors.primary,
                Globals.colors.primary,
              ]}
            />
            /}
          </div>
          */}

          {/* @TODO: fade out gradient as you get close to the end of the list */}
          {/* @TODO: add this to the front of the list when scrolling away */}

          {/* @TODO: add "tip the developers" section */}

          <h3 style={styles.headerText}>Info</h3>
          <div style={styles.otherInfoWrapper}>
            <span>App version: 0.1.0</span>{/* @TODO: get from globals? something that gitlab can add automatically? */}
            <span>{'\u00A9'} {dateFnsFormat(new Date(), 'yyyy')} Oakwood Software Consulting, Inc.</span>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            variant='contained'
            autoFocus={false}
            onClick={() => this.props.onClose()}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
