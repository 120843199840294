/**
 * Ryan O'Dowd
 * 2021-08-22
 * © Copyright 2021 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const PremiumBadge = (props) => {
  return (
    <img
      style={{...styles.container, ...props.style}}
      src='/img/premium_gold_trim.png'
    />
  );
};

PremiumBadge.propTypes = {
  style: PropTypes.object,
};

export default PremiumBadge;
