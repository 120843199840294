/*
 * Ryan O'Dowd
 * 2019-06-08
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */

const styles = {
  container: {
    textAlign: 'left',
  },
  downloadLinks: {
    display: 'flex',
    justifyContent: 'center',
  },
};

export default styles;
