/**
 * Ryan O'Dowd
 * 2021-07-18
 * © Copyright 2021 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
// @TODO: add "contact us" dialog for hitting premium limit instead of showing hte same dialog (also ad analytics for this...i want to be notified as soon as that happens)
export const PLAN_LIMITS = [
  {
    name: 'People',
    free: 30,
    premium: 1000,
    enterprise: 'contact us',
  },
  {
    name: 'Notes',
    isPerPerson: true,
    free: 15,
    premium: 150,
  },
  {
    name: 'Labels',
    free: 5,
    premium: 200,
  },
  {
    name: 'Reminders',
    isPerPerson: true,
    free: 2,
    premium: 10,
  },
  {
    name: 'Tidbits',
    free: 3,
    premium: 30,
  },
  {
    name: 'Photos',
    free: 10,
    premium: 1000,
  },
  {
    name: 'Name Tags',
    free: 2,
    premium: 10,
  },
  {
    name: 'Name Tag Notes',
    isPerPerson: true,
    free: 5,
    premium: 20,
  },
  {
    name: 'Label Geofences',
    free: 1,
    premium: 10,
  },
  // @TODO: share people/notes/photos with others
  {
    name: 'Subscription',
    free: '-',
    premium: ['$2.99/month', '$29.99/year'],
  },
];
