/**
 * Ryan O'Dowd
 * 2021-02-16
 * © Copyright 2018 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import Globals from '../../../Globals';

export const FORM_WIDTH = 300;

const styles = {
  container: { // @TODO: should probably be in commonstyles (actually, header and container should be a component to wrap all pages)
    flex: 1,
    width: '100%',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
    maxWidth: 400,
    paddingBottom: 0,
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    color: Globals.colors.primaryExtraDark, // @TODO: this should be global css
    marginBottom: 16,
  },
  descriptionLine: {
    marginBottom: 16,
  },
  signInOptionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
  },
  providerButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    elevation: 3,
    width: 220,
    backgroundImage: `linear-gradient(
      ${Globals.colors.primary} 0%,
      ${Globals.colors.primaryDark} 100%
    )`,
    color: Globals.colors.primaryExtraLight,
    padding: 8,
    borderRadius: 8,
    cursor: 'pointer',
  },
  buttonIcon: {
    marginRight: 16,
  },
  disclaimerWrapper: {
    color: Globals.colors.primaryExtraDark,
    marginTop: 32,
    textAlign: 'center',
  },
  disclaimerText: {
    fontSize: 12,
  },

  nameInputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textInput: {
    width: 250,
    marginTop: 24,
  },
};

export default styles;
