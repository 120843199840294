/**
 * Ryan O'Dowd
 * 2018-02-05
 * © Copyright 2018 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  applyMiddleware,
  createStore,
} from 'redux';
import {
  // @TODO: createMigrate,
  persistReducer,
  persistStore,
} from 'redux-persist';
import {
  apiMiddleware,
} from 'redux-api-middleware';
import {
  platform,
} from './Globals';
import rootReducer from './reducers';
import thunk from 'redux-thunk';

let storage = {};
if (platform === 'web') {
  storage = require('redux-persist/lib/storage').default;
} else {
  // this is native (`platform` is `ios` or `android`)
  storage = require('@react-native-async-storage/async-storage').default;
}

/* @TODO:
const migrations = {
  0: (state) => {
    // not sure what part of the store is causing crashes...seems like android only, so just make everyone refresh their entire store
    return undefined;
  },
};
*/

const persistConfig = {
  key: 'root',
  // @TODO: version: 0, // @NOTE: only change this when the store should be wiped before rehydrated (or there's a migration); incrementing this is better than having users need to uninstall and reinstall because the reducer shape has changed
  storage,
  blacklist: ['fetches'],
  // @TODO: migrate: createMigrate(migrations, {debug: false}),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, {}, applyMiddleware(thunk, apiMiddleware));
const configureStore = () => {
  const persistor = (callback) => persistStore(store, null, callback); // @TODO: this callback is causing the `Warning: Cannot update a component from inside the function body of a different component.` error. i think this is what i want to do though...wish i could supress the warning, but i need to be able to keep light/dark mode accurate

  // @NOTE: uncomment when store needs reset; IMPORTANT: do NOT leave uncommented during a deployment
  // persistor(null).purge();

  return {
    store,
    persistor,
  };
};

export default configureStore;
