// @TODO: shouldn't need to be its own file
/**
 * Ryan O'Dowd
 * 2022-12-27
 * © Copyright 2022 Oakwood Software Consulting, Inc. All Rights Reserved.
 */

function recordError(error) { // @TODO: also in common but circular dep
  // @TODO: implement
  console.error(error);
}

function getOperatingSystemLocaleCode() {
  // @TODO: implement
  return 'en';
}

async function logout() {
  // @TODO: implement
}

export default {
  getOperatingSystemLocaleCode,
  logout,
  recordError,
};
