/*
 * Ryan O'Dowd
 * 2019-06-08
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  containerInnerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',

    [Globals.mediaQueries.sm]: {
      height: '100%',
      // @TODO: minHeight: 'calc(100vh - 35px - 58px)', // footer and header heights // @TODO: remove calc
      // @TODO: maxHeight: 'calc(100vh - 35px - 58px)', // footer and header heights // @TODO: remove calc
    },
  },
  containerInner: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    minHeight: '100%',
    maxHeight: '100%',
    overflowY: 'auto',
    backgroundColor: Globals.colors.white,

    /* @TODO: don't apply this if it's an event webview
    [Globals.mediaQueries.sm]: {
      height: 'calc(100vh - 35px - 58px)', // footer and header heights // @TODO: remove calc
      minHeight: 'calc(100vh - 35px - 58px)', // footer and header heights // @TODO: remove calc
      maxHeight: 'calc(100vh - 35px - 58px)', // footer and header heights // @TODO: remove calc
    },
    */
  },
};

export default styles;
