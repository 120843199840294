/*
 * Ryan O'Dowd
 * 2021-12-16
 * © Copyright 2021 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  outerContainer: {
    flex: 1,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'calc(100% - 32px)',
    maxWidth: 500,
    minHeight: 'calc(100vh - 148px)', // @TODO: dynamic, not hardcoded
    padding: 16,
    paddingTop: 32,
    margin: 'auto',

    [Globals.mediaQueries.sm]: {
      minHeight: 'calc(100vh - 184px)', // @TODO: dynamic, not hardcoded
    },
  },
  exhibitorMetadata: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  exhibitorName: {
    color: Globals.colors.primary,
    fontSize: 24,
    fontWeight: '600',
    margin: 0,
    marginBottom: 16,
  },
  link: {
    color: Globals.colors.primary,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  formWrapper: {
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  formField: {
    flex: 1,
    margin: 4,
    marginLeft: 0,
    // @TODO: text in these fields should be Globals.colors.primaryExtraDark to match the rest of the text on the landing page, but for some reason this doesn't work with mui/material: color: 'red',
  },
  thanksText: {
    color: Globals.colors.primary,
    marginBottom: 32,
  },
  oakwoodAd: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default styles;
