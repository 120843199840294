/*
* Ryan O'Dowd
* 2021-04-09
* © Copyright 2021 Oakwood Software Consulting, Inc.  All Rights Reserved.
*/
import {
  firebase,
} from '../../Globals';
import importedUtilities from '../../importedUtilities';
import {
  store,
} from '../../Store';

function schedulePeriodicReminders() {
  // @TODO: impelment
}

function scheduleAndSaveNoteReminders(remindersSnapshot, notes, allTidbits, personTidbits, people) {
  const reminders = [];
  remindersSnapshot?.forEach((doc) => {
    const reminder = doc.data();
    reminders.push({...reminder, key: doc.id});
  });
  // @TODO: save specific field reminders as well
}

// @TODO: most of this can be abstracted with the native code
function uploadPhoto(personKey, file, fileSize, onUploadStart, onUploadEnd) {
  onUploadStart();
  const globalReduxStore = store.getState();
  const imagePath = `/${globalReduxStore.user.uid}/${personKey}.jpg`;
  const imageRef = firebase.storage().ref(imagePath);
  imageRef.put(file, {contentType: 'image/jpeg'}).then(async (storageTask) => {
    // @TODO: display uploading progress

    // store link in firestore
    const photoUrl = await firebase.storage().ref(imagePath).getDownloadURL(); // @TODO: why does this have to be a public url? this should be available to user only
    importedUtilities.updatePerson(personKey, {
      photoUrl,
    });
    onUploadEnd();
  }).catch((error) => {
    onUploadEnd();
    firebase.crashlytics().log('media upload error');
    firebase.crashlytics().recordError(error);
  });
}

function syncSubscriptionInfo(dispatchSetSubscriptionInfo) {
  // @TODO: implement
}

function logout() {
  // @TODO: implement
}

function configureBackgroundGeolocation() {
  // @TODO: implement
}

function subscribeToNotifications() {
  // @TODO: implement
  return () => {};
}

function subscribeToLabelsCallback() {
  // @TODO: implement
}

function setLabelAssignment() {
  // @TODO: implement
}

export default {
  configureBackgroundGeolocation,
  logout,
  scheduleAndSaveNoteReminders,
  schedulePeriodicReminders,
  setLabelAssignment,
  subscribeToLabelsCallback,
  subscribeToNotifications,
  syncSubscriptionInfo,
  uploadPhoto,
};
