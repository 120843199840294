/**
 * Ryan O'Dowd
 * 2021-07-05
 * © Copyright 2021 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import React from 'react';
import {
  SUBSCRIPTION_ROUTE,
} from '../../actions';
import oakGlobalObject from '../../common/utilities/oakGlobalObject';

// @TODO: images can be added to a lot of these (animated gifs) to make it easier to understand (host these on tibbits.me, though, so that the bundle size of the app doesn't bloat...and make sure there are two sets--one for android, and one for ios
const i18n = (navigation, isAnonymousUser, Linking, Platform, View, PText, SpanText, styles) => oakGlobalObject({
  sections: [
    {
      title: 'Getting Started',
      faqs: [
        {
          question: 'The basics',
          answer: (
            <View>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>People</SpanText> can be viewed/added from the <SpanText style={styles.bold}>Home</SpanText> screen.  Tapping on a <SpanText style={styles.bold}>person</SpanText> takes you to the <SpanText style={styles.bold}>Details</SpanText> screen where you can add <SpanText style={styles.bold}>notes</SpanText> and assign <SpanText style={styles.bold}>labels</SpanText>.  Tapping a <SpanText style={styles.bold}>note</SpanText> takes you to the <SpanText style={styles.bold}>Edit Note</SpanText> screen where you can update the <SpanText style={styles.bold}>note</SpanText> and add/update a <SpanText style={styles.bold}>reminder</SpanText> for that <SpanText style={styles.bold}>note</SpanText>{".  That's the quick pitch--for more info, please see the topics below."}</PText>
            </View>
          ),
        },
        {
          question: 'Terminology', // @TODO: this could/should probably be combined with the "what are X?" questions?
          answer: (
            <View>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Person: </SpanText>{"Your people are what show up on the Home screen after assigning them a name, notes, labels, and/or tidbits.  Usually it's best to limit each entry to a single name, but sometimes you may want to add a married couple or a family as a single entry depending on how you use Tibbits."}</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Label: </SpanText>{'Labels are a great tool for organizing your people by location.  Did you meet someone at the gym?  That is a good use for a label.  Next time you go to the gym, pull up Tibbits and filter by "gym" to remember who you met last time.  Labels also can be used for other category designations.  For example, maybe you play in a recreational softball league.  Labels can be used to keep track of who is on what team.  In that case, "softball" and "blue team" might be good labels to assign to someone.  That way, you could filter all of your people by "softball" or by individual team.'}</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Note: </SpanText>Notes are the most basic form of information to keep on people.  We recommend keeping notes short so they can be reviewed quickly, but they can be short, long, or anywhere in between.  Reminders can be set for individual notes.</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Pin: </SpanText>{"People can be sorted alphabetically or by the date they were added or modified.  But sometimes, there are one or a few people you'd like to be at the top of the list no matter what--maybe a temporary designation for a handful of people you're trying extra hard to remember, or maybe it's a spouse, close friend, or coworker that you're wanting to access frequently.  In any case, people can be pinned so that they always stay on the top of the list on the Home screen."}</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Tidbit: </SpanText>{"Notes are great for storing general information, but sometimes there are certain things you'd like to remember about all of your people--for example, birthday, occupation, anniversary, website, phone number, or a photo of their business card.  This is where tidbits are super useful.  Once you've created a tidbit, you can enter a value for each person individually and no longer need to keep a note for that information.  Please see the tidbit section below for more examples."}</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Reminder: </SpanText>Reminders can be set for notes and some tidbits.  When triggered, a push notification is sent to alert you of the reminder.</PText>
              {/* @TODO:
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Name Tag: </SpanText>You can create notes about yourself and share that information with other Tibbits users via name tags.  You can create multiple name tags with different names, photos, and notes for different types of contacts (for example, having one for business use and one for personal use may be helpful.)</PText>
              // @TODO: event
              */}
            </View>
          ),
          // @TODO: images
        },
        {
          question: 'Screens',
          answer: (
            <View>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Home: </SpanText>This is the main screen that lists all of your people.</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Details: </SpanText>After tapping on a person, this is the screen containing all of the notes for a person.</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Archived People: </SpanText>Similar to Home screen except this list of people includes only those you have archived.  People can be deleted permanently or restored from here.</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Reminders: </SpanText>A list of all reminders for all notes and tidbits.  See which reminders are enabled and upcoming and manage settings from here.</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Labels: </SpanText>A list of all labels.  Labels can be added, renamed, and deleted on this screen, and tapping on a label will take you to the Home screen with the label preselected so that you see only people with the selected label.</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Tidbits: </SpanText>Manage tidbits for your account here.  Tidbits can be added, renamed, reassigned a datatype, and deleted.</PText>
              {/* @TODO:
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Name Tags: </SpanText>{"Create new name tags or edit existing ones.  This is also where you can scan other users' name tags to add to your Tibbits account.  After scanning a name tag, it will live here until you have added it to your people list (or merged it into an existing user.)"}</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Events: </SpanText>Tibbits is great for networking events, conferences, and other events.  If you are attending an event that has partnered with Tibbits, this is where you can view upcoming sessions, speakers, locations, and notification for the event.</PText>
              */}
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Feedback Chat: </SpanText>Have a question, a feature request, or other feedback?  Messages can be sent and received here.</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Settings: </SpanText>Your Tibbits Premium subscription, notification preferences, and light/dark mode can be managed on this screen.</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>{"What's New: "}</SpanText>Learn about recent updates to Tibbits.  New features and enhancements will be listed here.</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Help: </SpanText>{"This is the page you're currently on.  Find information about common features and learn tips and tricks to make the most out of your experience with Tibbits."}</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>About: </SpanText>The about page provides some background information about our company and links to rate and share with friends.</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Edit Note: </SpanText>Accessible by tapping on a note on the Details screen or a reminder on the Reminders screen, this is where you can modify the body of a note and create or modify a reminder for it.</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Side Drawer: </SpanText>The main menu accessible via the button in the top left on the Home screen.</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Manage Subscription: </SpanText>Sign up for Tibbits Premium or check the status of your existing subscription here.</PText>
              {isAnonymousUser && <PText style={styles.paragraph}><SpanText style={styles.bold}>Link Account: </SpanText>If you signed up with a guest account, your data will not be synced across devices.  To protect your data, you can link your account to an account you own (Apple, Google, Facebook, or your email address).  This will allow you to recover your data if you lose your phone or want to sign in to Tibbits on a new device.</PText>}
            </View>
          ),
          // @TODO: images
        },
      ],
    },
    {
      title: 'People',
      faqs: [
        {
          question: 'How do I add a person?',
          answer: (
            <View>
              <PText style={styles.paragraph}>The add button in the bottom right of the Home screen can be tapped to create a new person.</PText>
            </View>
          ),
          // @TODO: images
        },
        {
          question: "How do I update a person's name?",
          answer: (
            <View>
              <PText style={styles.paragraph}>The name in the header of the Details screen is editable--simply tap it to pull up the keyboard.</PText>
            </View>
          ),
          // @TODO: images
        },
        {
          question: 'How do I change the order of my people?',
          answer: (
            <View>
              <PText style={styles.paragraph}>There are three sorting options available on the Home screen; by default, people are sorted by the time they were last updated in decending order, and you can sort alphabetically or by date created instead.  If you would like a person to show up at the top of the list regardless of sort order, you can pin the person to the top.</PText>
            </View>
          ),
          // @TODO: images
        },
        {
          question: 'How do I pin a person?',
          answer: (
            <View>
              <PText style={styles.paragraph}>People can be pinned in two ways; swiping left on a person on the Home screen will show a pin icon that can be tapped, and there is a pin option in the overflow menu on the Details screen.</PText>
            </View>
          ),
          // @TODO: images
        },
        {
          question: 'How do I delete a person?',
          answer: (
            <View>
              <PText style={styles.paragraph}>Deleting a person is a two-step process.  To remove a person from your list, you can archive the person by swiping left on the name card on the Home screen and tapping the archive button or by selecting the archive option in the overflow menu on the Details screen.  Once a person has been archived, you can delete the person permanently on the Archived People screen.</PText>
            </View>
          ),
          // @TODO: images
        },
        {
          question: 'How do I view archived people?',
          answer: (
            <View>
              <PText style={styles.paragraph}>{'Archived people can be viewed by selecting the "Archived people" option in the side drawer.'}</PText>
            </View>
          ),
          // @TODO: images
        },
        {
          question: 'How do I find a specific person?',
          answer: (
            <View>
              <PText style={styles.paragraph}>The searchbar on the Home screen can be used to find people by name, notes, or labels.  Additionally, labels on the Home screen can be tapped to filter all people to show only people with that label.</PText>
            </View>
          ),
          // @TODO: images
        },
      ],
    },
    {
      title: 'Notes',
      faqs: [
        {
          question: 'What are notes?',
          answer: (
            <View>
              <PText style={styles.paragraph}>Notes are text fields for you to keep track of things you want to remember about someone.</PText>
            </View>
          ),
          // @TODO: images
        },
        {
          question: 'How do I add a note?',
          answer: (
            <View>
              <PText style={styles.paragraph}>Notes can be added on the Details screen by tapping the text box at the bottom of the screen.</PText>
            </View>
          ),
          // @TODO: images
        },
        {
          question: 'How do I view/find notes?',
          answer: (
            <View>
              <PText style={styles.paragraph}>Notes can be searched on the Home screen.  Previews of a few recent notes will show on the cards on the Home screen, and tapping on a person will take you to the Details screen where all notes for that person are displayed.</PText>
            </View>
          ),
          // @TODO: images
        },
        {
          question: 'How do I edit a note?',
          answer: (
            <View>
              <PText style={styles.paragraph}>Tapping a note on the Details screen will take you to the Edit Note screen.  Here you can update the note and add/modify a reminder for it.</PText>
            </View>
          ),
          // @TODO: images
        },
        {
          question: 'How do I change the order of my notes?',
          answer: (
            <View>
              <PText style={styles.paragraph}>Tap and hold the dots to the left of the note and drag to move it up or down the list.</PText>
            </View>
          ),
          // @TODO: images
        },
        {
          question: 'How do I delete a note?',
          answer: (
            <View>
              <PText style={styles.paragraph}>Similar to people, notes can be archived to hide them from view without deleting them permanently.  To archive a note, swipe left and select the archive option, or select the archive option in the overflow menu on the Edit Note screen.  To delete a note, select the delete option on an archived note.</PText>
            </View>
          ),
          // @TODO: images
        },
        {
          question: 'How do I view archived notes?',
          answer: (
            <View>
              <PText style={styles.paragraph}>{'Select the "View archived notes" option from the overflow menu on the Details screen.'}</PText>
            </View>
          ),
          // @TODO: images
        },
      ],
    },
    {
      title: 'Labels',
      faqs: [
        {
          question: 'What are labels?',
          answer: (
            <View>
              <PText style={styles.paragraph}>Labels help you to organize people by common features, such as location (gym, church, work, etc.)  While these can be used for any type of data, we suggest using labels to track locations, groups, teams, or other categories for people.</PText>
            </View>
          ),
          // @TODO: images
        },
        {
          question: 'How do I add a label?',
          answer: (
            <View>
              <PText style={styles.paragraph}>Labels can be created on the Labels screen (accessible via the Side Drawer) or from the Details screen (tap on the blue tags icon.)</PText>
            </View>
          ),
          // @TODO: images
        },
        {
          question: 'How do I view/update my labels?',
          answer: (
            <View>
              <PText style={styles.paragraph}>Labels can be managed on the Labels screen linked to from the Side Drawer.</PText>
            </View>
          ),
          // @TODO: images
        },
        {
          question: 'How do I delete a label?',
          answer: (
            <View>
              <PText style={styles.paragraph}>When on the Labels screen, labels can be swiped left to display a delete button.</PText>
            </View>
          ),
          // @TODO: images
        },
        {
          question: 'How do I assign a label to a person?',
          answer: (
            <View>
              <PText style={styles.paragraph}>At the top of the Details screen, the blue tags can be tapped.  The checked labels will be assigned to the current person.</PText>
              <PText style={styles.paragraph}>After adding a few people with labels, you may notice a quick-selection option available for new people.  When adding a new person, Tibbits will suggest labels based on when they were last used.  Simply tap an outlined label to assign it to the current person.  This is especially useful if you meet multiple people at an event; after assigning the label to the first person, that same label can be added to others with a single tap!</PText>
            </View>
          ),
          // @TODO: images
        },
        {
          question: 'How do I assign a location to a label?',
          answer: (
            <View>
              <PText style={styles.paragraph}>{"Assigning a location to a label allows you to opt in to notifications when you return to that location.  Next time you arrive at the location you've specified, Tibbits will remind you to review all of the people you know from that place."}</PText>
              <PText style={styles.paragraph}>To assign a location to a label, swipe left on the label, and select the pencil icon to edit.  Use your current location or type in a location after enabling reminders for this label.</PText>
            </View>
          ),
          // @TODO: images
        },
        {
          question: 'Why am I not receiving notifications for labels when arriving at a location?',
          answer: (
            <View>
              <PText style={styles.paragraph}>Here are some troubleshooting steps:</PText>
              <PText style={styles.paragraph}>1. Confirm that the location is correct on the Manage Labels screen, and that reminders are enabled for that label.</PText>
              <PText style={styles.paragraph}>{`2. Ensure that Location Services are enabled for Tibbits in your device settings and that the permission to "${!Platform || Platform.OS === 'ios' ? 'Always Allow' : 'Allow all the time'}" is selected.  In order for Tibbits to send a notification when you arrive at a location, it needs to be able to check location data in the background.`}</PText>
              {(!Platform || Platform.OS === 'android') &&
                <PText style={styles.paragraph}>
                  {"3. If the first two steps don't fix the issue, you can follow the steps at "}{Platform ? <SpanText style={styles.link} onPress={() => Linking.openURL('https://dontkillmyapp.com/')}>this site</SpanText> : <a style={styles.link} href='https://dontkillmyapp.com/'>this site</a>} for your specific device.  Some Android manufactures disable location access by default to improve the performance of the battery.  Although Tibbits does not require much battery, the location access still may be limited by your device manufacturer.
                </PText>
              }
            </View>
          ),
          // @TODO: images
        },
        {
          question: 'How does Tibbits use my location data?  Is it private?',
          answer: (
            <View>
              <PText style={styles.paragraph}>{"At Tibbits, we take privacy very seriously; we don't store or use user location data for any purposes other than those explicitly outlined in the application (even anonymously or in aggregate).  We also limit the amount of sensitive data that even makes it to our servers, so real-time location data is compared to your labels "}<SpanText style={styles.bold}>on your device and is never sent off of the device</SpanText> to our servers or anywhere else.</PText>
              <PText style={styles.paragraph}>
                For more information about our privacy policies, please see {Platform ? <SpanText style={styles.link} onPress={() => Linking.openURL('https://tibbits.me/#terms_and_privacy')}>our privacy policy</SpanText> : <a style={styles.link} href='https://tibbits.me/#terms_and_privacy'>our privacy policy</a>}.
              </PText>
            </View>
          ),
        },
      ],
    },
    {
      title: 'Tidbits',
      faqs: [
        {
          question: 'What are tidbits?',
          answer: (
            <View>
              <PText style={styles.paragraph}>You can configure specific fields that show up on the Details page for every person.  Tidbits can be configured from the Side Drawer, and values can be added for individual people on the Details screen.</PText>
              <PText style={styles.paragraph}>Tidbits for all people can be managed from the Tidbits screen.  Values are grouped by tidbit and can be edited from one place.  If the tidbit has a date type, reminders can be set here as well.</PText>
            </View>
          ),
          // @TODO: images
        },
        {
          question: 'How are tidbits different from notes?',
          answer: (
            <View>
              <PText style={styles.paragraph}>{'Both tidbits and notes allow you to organize information that is specific to individual people.  However, tidbits are generally best for information that you would like to have for multiple people.  For example, birthday, anniversary, email address, phone number, and website are all good tidbits because they may apply to many of your people.  Maybe you use Tibbits to remember favorite TV shows of friends and coworkers as an easy conversation starter..."favorite TV show" might be a good tidbit to add so that you can view that at a glance.'}</PText>
            </View>
          ),
          // @TODO: images
        },
        {
          question: 'What are the different types of tidbits, and what can I do with them?',
          answer: (
            <View>
              <PText style={styles.paragraph}>The main purpose of tidbits is to provide a way to organize stardard information.  There are five types of tidbits:</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Text </SpanText>{"(e.g. nickname, occupation, favorite movie): This is the default type.  Store information here that doesn't fall into one of the other categories."}</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Date </SpanText>(e.g. birthday, anniversary): Storing a date value allows you to set reminders for the tidbit in the same way you can set reminders for notes.</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Link </SpanText>(e.g. personal website, social media account): Tidbits stored as links will open in your mobile browser with a single tap.</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Email </SpanText>(e.g. jsmith@example.com, jdoe@example.com): Similar to links, but these will open in your mobile email client.</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Phone </SpanText>(e.g. 1015551234, +9155555123456): Phone tidbits allow you to initiate a phone call directly from within Tibbits.  (You will be asked to confirm after selecting the number before it starts to dial.)</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Photo </SpanText>(e.g. business card): In addition to a profile picture, you can store photos in your tidbits.  This is especially useful for things like managing business cards at networking events as well as many industry-specific tasks (e.g. before/after photos on job sites.)</PText>
            </View>
          ),
        },
      ],
    },
    {
      title: 'Reminders',
      faqs: [
        {
          question: 'What are reminders?',
          answer: (
            <View>
              <PText style={styles.paragraph}>Reminders are push notifications for notes (or tidbits) that you want to remember.  For example, if you have a note that a friend has a 5K coming up, you can set a reminder to alert you the day before to remind you to wish them luck!</PText>
              <PText style={styles.paragraph}>{'There are also optional periodic reminders to prompt you to add new people to Tibbits.  Reminders also can be set on tidbits as long as they are a "date" type.'}</PText>
            </View>
          ),
          // @TODO: images
        },
        {
          question: 'How do I create a reminder?',
          answer: (
            <View>
              <PText style={styles.paragraph}>Reminders can be added to notes on the Edit Note screen (accessible by tapping on a note.)</PText>
              <PText style={styles.paragraph}>{'Reminders can be added to tidbits by tapping a tidbit on the Details screen.  (Note: the tidbit needs to be a "date" type.)'}</PText>
            </View>
          ),
          // @TODO: images
        },
        {
          question: 'How do I view/update my reminders?',
          answer: (
            <View>
              <PText style={styles.paragraph}>{"Reminders can be managed from the Reminders screen accessible in the Side Drawer.  Tapping on a tidbit reminder or a person's name will take you to the Details screen.  Tapping on a note from the Reminders screen or Details screen will take you to the Edit Note screen from which the reminder can be updated as well."}</PText>
            </View>
          ),
          // @TODO: images
        },
        {
          question: 'How do I delete a reminder?',
          answer: (
            <View>
              <PText style={styles.paragraph}>{'Reminders can be deleted by swiping right on the reminder in the Reminders screen or by selecting "Delete reminder" in the menu on the Details screen.'}</PText>
            </View>
          ),
          // @TODO: images
        },
        // @TODO: tips:
        // @TODO: delete old reminders periodically
      ],
    },
    /* @TODO:
    {
      title: 'Name Tags',
      faqs: [
        {
          question: 'What are name tags?',
          answer: (
            <View>
              <PText style={styles.paragraph}>{"Name tags make it easy to share your information with others.  You can use them to store your name, photo, and some notes you'd like to share with others.  When you meet someone new, they can scan a QR code on your device to download the information you'd like to share with them."}</PText>
            </View>
          ),
          // @TODO: images
        },
        {
          question: 'How do I create or modify a name tag?',
          answer: (
            <View>
              <PText style={styles.paragraph}>Name tags can be created and modified from the Name Tags screen (accessible via the side drawer.)  Tapping on a name tag brings you to the Details screen for adding, modifying, or deleting the name and notes.</PText>
            </View>
          ),
          // @TODO: images
        },
        {
          question: 'What are pending name tags?',
          answer: (
            <View>
              <PText style={styles.paragraph}>{"When you scan the QR code of other Tibbits users' name tags, the name tags are saved to your device.  However, before the information is saved to your account, you have the option to confirm which notes will be moved and if you want to create a new person or merge with an existing person."}</PText>
              <PText style={styles.paragraph}>{"Scanning name tags can be especially beneficial at events where you're meeting many people.  No need to worry about typing in everyone's info right away; simply scan their name tags and wait until after the event to organize them however you like."}</PText>
            </View>
          ),
          // @TODO: images
        },
        // @TODO: tips:
        // @TODO: clean up pending name tags periodically
      ],
    },
    {
      title: 'Events',
      faqs: [
        {
          question: 'What are events?',
          answer: (
            <View>
              <PText style={styles.paragraph}>Tibbits partners with networking groups, conferences, and other events.  Not only can you use Tibbits at these venues to keep track of the people you meet, but you can also get notifications about the event and view upcoming sessions, speakers, and locations.</PText>
            </View>
          ),
          // @TODO: images
        },
        {
          question: 'How do I join an event?',
          answer: (
            <View>
              <PText style={styles.paragraph}>Events can be joined from the Events page or the Settings page.  Simply enter the code provided to you by the event organizer to gain access to the event details.</PText>
            </View>
          ),
          // @TODO: images
        },
        // @TODO: benefits of starring sessions: "my calendar" view, reminders are sent 10 minutes before the event starts
        {
          question: 'I noticed a typo in the event schedule.  What should I do?',
          answer: (
            <View>
              <PText style={styles.paragraph}>Event schedules are managed by the event organizers, so if there is a mistake in the schedule or another issue related to the event itself, please reach out to the event organizer.  Other issues can be directed to Tibbits support via Feedback Chat.</PText>
            </View>
          ),
          // @TODO: images
        },
        // @TODO: tips:
        // @TODO: clean up pending name tags periodically
      ],
    },
    */
    {
      title: 'Tibbits Premium',
      faqs: [
        {
          question: 'How do I update my subscription?',
          answer: (
            <View>
              <PText style={styles.paragraph}>
                {'Subscriptions can be managed '}
                {navigation
                  ? (
                    <SpanText style={styles.link} onPress={() => navigation.navigate(SUBSCRIPTION_ROUTE)}>
                      here
                    </SpanText>
                  ) : (
                    'on the Tibbits Premium screen, and are ultimately managed by the Apple App Store and the Google Play Store'
                  )
                }
                .
              </PText>
              {!Platform && (
                <React.Fragment>
                  <PText style={styles.paragraph}>
                    If you use an iPhone, you can follow these links to <a style={styles.link} href='https://support.apple.com/en-us/HT204939'>switch plans</a>, <a style={styles.link} href='https://support.apple.com/en-us/HT202039'>cancel</a>, or <a style={styles.link} href='https://support.apple.com/en-us/HT202039'>request a refund</a>.
                  </PText>
                  <PText style={styles.paragraph}>
                    If you use an Android, you can <a href='https://support.google.com/googleplay/answer/7018481?co=GENIE.Platform%3DAndroid&oco=1'>switch plans or cancel here</a>.
                  </PText>
                </React.Fragment>
              )}
              {Platform &&
                (Platform.OS === 'ios'
                  ? (
                    <PText style={styles.paragraph}>
                      <SpanText style={styles.link} onPress={() => Linking.openURL('https://support.apple.com/en-us/HT204939')}>
                        Switching plans
                      </SpanText>
                      {', '}
                      <SpanText style={styles.link} onPress={() => Linking.openURL('https://support.apple.com/en-us/HT202039')}>
                        canceling
                      </SpanText>
                      {', or '}
                      <SpanText style={styles.link} onPress={() => Linking.openURL('https://support.apple.com/en-us/HT204084')}>
                        requesting a refund
                      </SpanText>
                      {' is handled through Apple.'}{/* @TODO: update wording here */}
                    </PText>
                  ) : (
                    <PText style={styles.paragraph}>
                      <SpanText style={styles.link} onPress={() => Linking.openURL('https://support.google.com/googleplay/answer/7018481?co=GENIE.Platform%3DAndroid&oco=1')}>
                        Switching plans or canceling
                      </SpanText>
                      {' is handled through Google Play.'}{/* @TODO: update wording here */}
                    </PText>
                  )
                )
              }
            </View>
          ),
          // @TODO: images
        },
        {
          question: 'What happens to my data if I cancel my subscription?',
          answer: (
            <View>
              <PText style={styles.paragraph}>Your data will not be deleted, even if you are above the plan limit.  However, you will be unable to create new entries unless you are under the standard plan limit.  You are able to update and delete existing data as much as you would like.</PText>
            </View>
          ),
        },
        {
          question: "I'm under the limit for my plan, but I'm still getting a message saying that I'm over.",
          answer: (
            <View>
              <PText style={styles.paragraph}>You might have too many archived people or notes.  Deleting archived people or notes will allow you to create new entries.</PText>
            </View>
          ),
        },
      ],
    },
    {
      title: 'Miscellaneous',
      faqs: [
        {
          question: 'Can I sync data between devices?',
          answer: (
            <View>
              <PText style={styles.paragraph}>{`${isAnonymousUser ? 'As long as you are not signed in as a guest' : 'Yes'}, data will be synced automatically between all devices you sign into, provided you sign in with the same account on each device.`}</PText>
              {isAnonymousUser && <PText style={styles.paragraph}>To link your guest account to an authentication provider, go to the Link Account screen (accessible via the Side Drawer) and link with Apple, Google, Facebook, or your email address.</PText>}
            </View>
          ),
        },
        {
          question: 'How does the PIN code work?',
          answer: (
            <View>
              <PText style={styles.paragraph}>You can configure a PIN code for your device on the Settings screen.  Adding a PIN number is helpful for keeping your Tibbits data inaccessible to those with access to your phone.</PText>
              <PText style={styles.paragraph}>The PIN code is optional, but if set, it will be required every time the app is opened after being closed for at least 1 minute.  (If the app is force closed, it will be locked immediately.)</PText>
              <PText style={styles.paragraph}>{"If you forget your PIN code, you can sign out and back in.  (This recovery option is available only if you are using an authentication provider; anonymous users can still configure a PIN code, but there's no option to sign out and back in.)"}</PText>
              <PText style={styles.paragraph}>Note: To prevent accidental lockout and because PIN protection is configured per device, the PIN is overriden by signing in to your account, so a new PIN will need to be configured on each sign in.</PText>
            </View>
          ),
        },
      ],
    },
  ],
});

export default i18n;
