/*
 * Ryan O'Dowd
 * 2019-05-28
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga4';
import {
  saveFsiAnalytic,
} from '../../../actions';
import styles from './styles';
import {
  useDispatch,
} from 'react-redux';

const ProjectCard = (props) => {
  const dispatch = useDispatch();

  const handleClick = (linkType, linkTo) => {
    switch (linkType) {
    case 'website': {
      saveFsiAnalytic();
      dispatch(saveFsiAnalytic(props.exhibitor.id, 'website_click'));
      break;
    }
    case 'ios': {
      saveFsiAnalytic();
      dispatch(saveFsiAnalytic(props.exhibitor.id, 'ios_click'));
      break;
    }
    case 'android': {
      saveFsiAnalytic();
      dispatch(saveFsiAnalytic(props.exhibitor.id, 'android_click'));
      break;
    }
    default: {
      break;
    }
    }
    ReactGA.event({
      category: 'Link clicked',
      action: `${linkType} visited`,
    });
    window.location = linkTo;
  };

  return (
    <div style={styles.container}>
      <Card elevation={4}>
        {props.comingSoon && (
          <span style={styles.comingSoonBadge}>
            Free download and
            <br />
            great for students!
          </span>
        )}
        <CardHeader
          title={props.name}
          subheader={props.tagline}
        />
        <CardContent style={styles.cardBody}>
          <img style={styles.image} src={props.imgUrl} alt={props.name} />
          <span style={styles.description}>
            {props.children}
          </span>
        </CardContent>
        <CardActions>
          {(props.url &&
            <Button
              onClick={() => handleClick('website')}
              href={props.url}
              color={props.url ? 'primary' : 'accent'}
            >
              Website
            </Button>
          )}
        </CardActions>
        {(props.iosLink ||
          props.androidLink ||
          props.webLink) &&
          <hr style={styles.hr} />
        }
        <CardActions style={styles.storeButtonsWrapper}>
          {props.iosLink &&
            <span style={styles.linkButton} onClick={() => handleClick('ios', props.iosLink)}>
              <img
                style={styles.storeImage}
                alt='iOS download'
                src='/img/apple_app_store_icon.svg'
              />
            </span>
          }
          {props.androidLink &&
            <span style={styles.linkButton} onClick={() => handleClick('android', props.androidLink)}>
              <img
                style={styles.storeImage}
                alt='Android download'
                src='/img/google_play_store_icon.png'
              />
            </span>
          }
        </CardActions>
      </Card>
    </div>
  );
};

ProjectCard.propTypes = {
  name: PropTypes.string.isRequired,
  tagline: PropTypes.string.isRequired,
  imgUrl: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
  exhibitor: PropTypes.object.isRequired,

  comingSoon: PropTypes.bool,
  iosLink: PropTypes.string,
  androidLink: PropTypes.string,
  webLink: PropTypes.string,
  url: PropTypes.string,
};

export default ProjectCard;
