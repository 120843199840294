/**
 * Ryan O'Dowd
 * 2018-02-05
 * © Copyright 2018 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  DELETE_NOTES_FOR_PERSON,
  SET_ACCOUNT_PROTECTION_ENABLED,
  SET_EVENTS,
  SET_FSI_EXHIBITORS,
  SET_LABELS,
  SET_LAST_NOTES_SYNC_TIMESTAMP,
  SET_NAME_TAGS,
  SET_NAME_TAG_NOTES,
  SET_NOTES,
  SET_NOTIFICATIONS,
  SET_PENDING_PEOPLE,
  SET_PENDING_PEOPLE_NOTES,
  SET_PEOPLE,
  SET_PEOPLE_SORT_METHOD,
  SET_PERIODIC_REMINDER_ENABLED,
  SET_PERSON_TIDBITS,
  SET_REMINDERS,
  SET_REMINDERS_SORT_METHOD,
  SET_SUBSCRIPTION_INFO,
  SET_TIDBITS,
  SET_TIMESTAMP_LAST_SHOWN_PREMIUM_DIALOG,
  SET_USER,
} from './actions';
import {
  chatMessages,
  feedbackChatDraftText,
  fetches,
  oakUser,
  settingsLightDarkMode,
  signInInfo,
  whatsNewLastReadTimestamp,
  whatsNewLastReadVersion,
  whatsNewNotes,
} from './common/reducers';
import Globals from './Globals';
import {
  LOGOUT,
} from './common/actions';
import {
  combineReducers,
} from 'redux';

function user(state = null, action) {
  switch (action.type) {
  case SET_USER:
    return action.user;
  default:
    return state;
  }
}

function people(state = [], action) {
  switch (action.type) {
  case SET_PEOPLE:
    return action.people;
  default:
    return state;
  }
}

function nameTags(state = [], action) {
  switch (action.type) {
  case SET_NAME_TAGS:
    return action.nameTags;
  default:
    return state;
  }
}

function nameTagNotes(state = {}, action) {
  switch (action.type) {
  case SET_NAME_TAG_NOTES: {
    return {...state, [action.nameTagKey]: action.notes};
  }
  default:
    return state;
  }
}

function pendingPeople(state = [], action) {
  switch (action.type) {
  case SET_PENDING_PEOPLE:
    return action.pendingPeople;
  default:
    return state;
  }
}

function pendingPeopleNotes(state = {}, action) {
  switch (action.type) {
  case SET_PENDING_PEOPLE_NOTES: {
    return {...state, [action.pendingPersonKey]: action.notes};
  }
  default:
    return state;
  }
}

// @NOTE: this `notes` reducer used to be `notes` but was an array and not an object, so now redux-persist overwrites the object with an array on rehydrate. rather than invalidate the persisted reducer somehow, it's probably easiest just to have a new reducer
// @TODO: this can be a migration in redux-persist
function notes2(state = {}, action) {
  switch (action.type) {
  case SET_NOTES: {
    return {...state, [action.personKey]: action.notes};
  }
  case DELETE_NOTES_FOR_PERSON: {
    const {[action.personKey]: _, ...rest} = state;
    return rest;
  }
  default:
    return state;
  }
}

function tidbits(state = {}, action) {
  switch (action.type) {
  case SET_TIDBITS: {
    const newState = {};
    action.tidbits.forEach((t) => {
      newState[t.key] = t;
    });
    return newState;
  }
  default:
    return state;
  }
}

function personTidbits(state = {}, action) {
  switch (action.type) {
  case SET_PERSON_TIDBITS: {
    return {...state, [action.personKey]: action.personTidbits};
  }
  default:
    return state;
  }
}

function events(state = [], action) {
  switch (action.type) {
  case SET_EVENTS: {
    // @TODO: set reminders for starred sessions (is this the best place for it?): PlatformUtilities.scheduleAndSaveNoteReminders(qs, globalReduxStore.notes2, globalReduxStore.tidbits, globalReduxStore.personTidbits, globalReduxStore.people, starredSessions); // @TODO: really should just restart the event reminders, but need to do everything together for now because all notifications are canceled at the same time when scheduling
    return action.payload.events;
  }
  default:
    return state;
  }
}

function reminders(state = {}, action) {
  switch (action.type) {
  case SET_REMINDERS: {
    const newReminders = {};
    action.reminders.forEach((reminder) => {
      newReminders[reminder.noteKey || reminder.tidbitKey] = reminder;
    });
    return newReminders;
  }
  default:
    return state;
  }
}

function notifications(state = [], action) { // @TODO: probably could be in common/reducers.js
  switch (action.type) {
  case SET_NOTIFICATIONS:
    return action.notifications;
  default:
    return state;
  }
}

function labels(state = [], action) {
  switch (action.type) {
  case SET_LABELS:
    return action.labels;
  default:
    return state;
  }
}

// @NOTE: nested settings reducer has been flattened to several reducers.
// users will get white screen if a component (mapStateToProps) is assuming
// that a key in initialSettings is in redux; the old store is persisted, so
// changing dailyReminder to periodicReminder for example, will cause .enabled
// to be called on undefined in mapStateToProps...very sneaky bug!! nested
// reducers is probably not a great idea...
function settingsPeriodicReminderIsEnabled(state = true, action) {
  switch (action.type) {
  case SET_PERIODIC_REMINDER_ENABLED:
    return action.isEnabled;
  default:
    return state;
  }
}

function settingsPeopleSortMethod(state = 'dateModified', action) {
  switch (action.type) {
  case SET_PEOPLE_SORT_METHOD:
    return action.sortMethod;
  default:
    return state;
  }
}

function settingsRemindersSortMethod(state = 'upcoming', action) {
  switch (action.type) {
  case SET_REMINDERS_SORT_METHOD:
    return action.sortMethod;
  default:
    return state;
  }
}

function settingsAccountProtectionEnabled(state = false, action) {
  switch (action.type) {
  case SET_ACCOUNT_PROTECTION_ENABLED:
    return action.isEnabled;
  default:
    return state;
  }
}

function lastNotesSyncTimestamps2(state = {}, action) {
  // timestamps keyed by person for the last time we synced notes for the person
  // will improve performance to ignore subscribing to notes collections on open if it hasn't changed since the last time we synced
  // this is really needed only for users with multiple devices
  switch (action.type) {
  case SET_LAST_NOTES_SYNC_TIMESTAMP: {
    return {...state, [action.documentKey]: new Date().getTime()};
  }
  default:
    return state;
  }
}

function subscriptionInfo(state = {}, action) {
  // timestamps keyed by person for the last time we synced notes for the person
  // will improve performance to ignore subscribing to notes collections on open if it hasn't changed since the last time we synced
  // this is really needed only for users with multiple devices
  switch (action.type) {
  case SET_SUBSCRIPTION_INFO: {
    return {
      isActive: action.isActive,
      isGracePeriod: action.isGracePeriod,
      productIdentifier: action.productIdentifier,
      expirationDate: action.expirationDate,
      willRenew: action.willRenew,
      isPromotional: action.isPromotional,
      lastSynced: new Date().getTime(),
    };
  }
  default:
    return state;
  }
}

function timestampLastShownPremiumDialog(state = 0, action) {
  switch (action.type) {
  case SET_TIMESTAMP_LAST_SHOWN_PREMIUM_DIALOG:
    return action.timestamp;
  default:
    return state;
  }
}

function fsiExhibitors(state = [], action) {
  switch (action.type) {
  case SET_FSI_EXHIBITORS:
    return action.payload.fsi_exhibitors;
  default:
    return state;
  }
}

const appReducer = combineReducers({
  chatMessages,
  events,
  feedbackChatDraftText,
  fetches,
  fsiExhibitors,
  labels,
  lastNotesSyncTimestamps2,
  nameTags,
  nameTagNotes,
  notes2,
  notifications,
  oakUser,
  pendingPeople,
  pendingPeopleNotes,
  people,
  personTidbits,
  reminders,
  settingsAccountProtectionEnabled,
  settingsLightDarkMode,
  settingsPeopleSortMethod,
  settingsPeriodicReminderIsEnabled,
  settingsRemindersSortMethod,
  signInInfo,
  subscriptionInfo,
  tidbits,
  timestampLastShownPremiumDialog,
  user,
  whatsNewNotes,
  whatsNewLastReadTimestamp,
  whatsNewLastReadVersion,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    if (Globals.platform === 'web') {
      window.localStorage.removeItem('emailForSignIn');
      window.localStorage.removeItem('firstNameForSignIn');
      window.localStorage.removeItem('lastNameForSignIn');
    }
    /* @TODO:
    firebase.auth().signOut().then(function() {
      // Sign-out successful.
    }).catch(function(error) {
      // An error happened.
    });
    */
    state = {
      whatsNewNotes,
    };
  }

  return appReducer(state, action);
};

export default rootReducer;
