/**
 * Ryan O'Dowd
 * 2021-08-22
 * © Copyright 2021 Oakwood Software Consulting, Inc. All Rights Reserved.
 */

const styles = {
  container: {
    width: 20,
    height: 20,
  },
};

export default styles;
