/**
 * Ryan O'Dowd
 * 2018-03-31
 * © Copyright 2018 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material';
import React, {
  useState,
} from 'react';
import Globals from '../../../../Globals';
import PropTypes from 'prop-types';
import {
  format as dateFnsFormat,
} from 'date-fns';
import styles from './styles';

const Legal = (props) => {
  const [_isOpen, setIsOpen] = useState(props.isOpen);

  const _close = () => {
    setIsOpen(false);
    window.location = window.location.href.split('#')[0];
  };

  return (
    <Dialog
      id='dialog_container'
      open={_isOpen}
      onClose={() => _close()}
      fullScreen={Globals.isMobile}
    >
      <DialogContent style={styles.dialog}>
        <h2>Terms and Conditions</h2>
        <h4 style={styles.minorTitle}>In using this website or any of our mobile applications, you are deemed to have read and agreed to the following terms and conditions:</h4>
        <p>{'The following terminology applies to these Terms and Conditions, Privacy Statement, and Disclaimer Notice, and any or all Agreements: "Client", "You", and "Your" refers to you, the person accessing any of our mobile applications and/or websites and accepting the Company’s terms and conditions. "The Company", "Ourselves", "We", and "Us", refers to our Company. "Party", "Parties", or "Us" refers to both the Client and ourselves, or either the Client or ourselves. All terms refer to the offer, acceptance, and consideration of using any application, in accordance with and subject to, prevailing U.S. Law. Any uses of the above terminology or other words in the singular, plural, capitalization and/or, he/she, or they, are taken as interchangeable and therefore as referring to same.'}</p>

        <h4 style={styles.minorTitle}>Privacy Statement</h4>
        <p>We are committed to protecting your privacy. Only authorized employees within the company use any information collected from individual customers and only on a need-to-know basis. We constantly review our systems and data to ensure the best possible service to our customers. The U.S. government has created specific offenses for unauthorized actions against computer systems and data. We will investigate any such actions with a view to prosecuting and/or taking civil proceedings to recover damages against those responsible.</p>
        <p>{`Account deletion and removal of your personal data can be requested on the Settings page by tapping on the "Delete account" option in the menu in the top right of the screen.  If you have any questions about data/account deletion, please contact ${Globals.supportEmail}.`}</p>

        <h4 style={styles.minorTitle}>Confidentiality</h4>
        <p>Any given information concerning the Client and their respective Client Records may be passed to third parties. However, Client records are regarded as confidential and therefore will not be divulged to any third party, other than if legally required to do so to the appropriate authorities, or per deals stemming from the usage of the product. Clients have the right to request sight of and copies of any and all Client Records we keep, on the proviso that we are given reasonable notice of such a request.</p>
        <p><strong>We will not sell, share, or rent your personal information to any third party or use your email address or phone number for unsolicited mail. All emails and text messages sent by the Company will be in connection with the provision of agreed services and products.</strong></p>

        <h2>Disclaimer Notice</h2>
        <h4 style={styles.minorTitle}>Exclusions and Limitations</h4>
        <p>{'The information in our applications and websites is provided on an "as-is" basis. To the fullest extent permitted by law, this Company:'}</p>
        <ul>
          <li>
            {"Excludes all representations and warranties relating to any application and/or website and its contents or which is or may be provided by any affiliates or any other third party, including in relation to any inaccuracies or omissions in this application and/or website and/or the Company's literature; and"}
          </li>
          <li>
            Excludes all liability for damages arising out of or in connection with your use of any of our applications and/or websites. This includes, without limitation, direct loss, loss of business or profits (whether or not the loss of such profits was foreseeable, arose in the normal course of things, or you have advised this Company of the possibility of such potential loss), damage caused to your computer, computer software, systems and programs and the data thereon, or any other direct or indirect, consequential, and incidental damages.
          </li>
        </ul>
        <p>This Company does not however exclude liability for death or personal injury caused by its negligence. The above exclusions and limitations apply only to the extent permitted by law. None of your statutory rights as a consumer are affected.</p>

        <h4 style={styles.minorTitle}>Termination of Agreements and Refunds Policy</h4>
        <p>Both the Client and ourselves have the right to terminate any Services Agreement for any reason, including the ending of services that are already underway. No refunds shall be offered, where a Service is deemed to have begun and is, for all intents and purposes, underway. Any monies that have been paid to us which constitute payment in respect of the provision of unused Services, shall be refunded.</p>

        <h4 style={styles.minorTitle}>Availability</h4>
        <p>Unless otherwise stated, the services featured in any application and/or website are only available within the United States, or in relation to postings from the United States. All advertising is intended solely for the United States market. You are solely responsible for evaluating the fitness for a particular purpose of any downloads, programs, and text available through this application and/or website. Redistribution or republication of any part of this application or website or their content is prohibited, including such by framing or other similar or any other means, without the express written consent of the Company. The Company does not warrant that the service from this application or website will be uninterrupted, timely, or error free, although it is provided to the best ability. By using this service you thereby indemnify this Company, its employees, agents, and affiliates against any loss or damage, in whatever manner, howsoever caused.</p>

        <h4 style={styles.minorTitle}>Log Files</h4>
        <p>We use IP addresses to analyze trends, administer the application and website, and gather broad demographic information for aggregate use. IP addresses are not linked to personally-identifiable information. Additionally, for systems administration, detecting usage patterns, and troubleshooting purposes, our web servers automatically log standard access information including browser type, access times, URL requested, and referral URL. This information is not shared with third parties and is used only within this Company on a need-to-know basis. Any individually-identifiable information related to this data will never be used in any way different to that stated above without your explicit permission.</p>

        <h4 style={styles.minorTitle}>Cookies</h4>
        <p>Like most interactive websites, our websites use cookies to enable us to retrieve user details for each visit. Cookies are used in some areas of our website to enable the functionality of this area and ease of use for those people visiting. Some of our affiliate partners also may use cookies.</p>

        <h4 style={styles.minorTitle}>Links from this Application and/or Website</h4>
        <p>{"We do not monitor or review the content of other parties' websites which are linked to from this application and/or website. Opinions expressed or material appearing on such websites are not necessarily shared or endorsed by us, and we should not be regarded as the publisher of such opinions or material. Please be aware that we are not responsible for the privacy practices, or content, of these sites. We encourage our users to be aware when they leave our applications and/or websites and to read the privacy statements of these sites. You should evaluate the security and trustworthiness of any other site connected to this site or accessed through this site yourself, before disclosing any personal information to them. This Company will not accept any responsibility for any loss or damage in whatever manner, howsoever caused, resulting from your disclosure to third parties of personal information."}</p>

        <h4 style={styles.minorTitle}>Copyright Notice</h4>
        <p>{"Copyright and other relevant intellectual property rights exist on all text relating to the Company's services and the full content of this application and/or website."}</p>

        <h4 style={styles.minorTitle}>Communication</h4>
        <p>Please fill out <a href='https://forms.gle/SrVxQ4KJwQRgpTBP8'>this form</a> to contact us.</p>

        <h4 style={styles.minorTitle}>Force Majeure</h4>
        <p>Neither party shall be liable to the other for any failure to perform any obligation under any Agreement which is due to an event beyond the control of such party including but not limited to any Act of God, terrorism, war, Political insurgence, insurrection, riot, civil unrest, act of civil or military authority, uprising, earthquake, flood or any other natural or man-made eventuality outside of our control, which causes the termination of an agreement or contract entered into, nor which could have been reasonably foreseen. Any Party affected by such event shall forthwith inform the other Party of the same and shall use all reasonable endeavors to comply with the terms and conditions of any Agreement contained herein.</p>

        <h4 style={styles.minorTitle}>Waiver</h4>
        <p>Failure of either Party to insist upon strict performance of any provision of this or any Agreement or the failure of either Party to exercise any right or remedy to which it, he/she, or they are entitled hereunder shall not constitute a waiver thereof and shall not cause a diminution of the obligations under this or any Agreement. No waiver of any of the provisions of this or any Agreement shall be effective unless it is expressly stated to be such and signed by both Parties.</p>

        <h4 style={styles.minorTitle}>General</h4>
        <p>The laws of the U.S. govern these terms and conditions. By accessing this application and/or website and using our services/buying our products, you consent to these terms and conditions and to the exclusive jurisdiction of the U.S. courts in all disputes arising out of such access. If any of these terms are deemed invalid or unenforceable for any reason (including, but not limited to, the exclusions and limitations set out above), then the invalid or unenforceable provision will be severed from these terms and the remaining terms will continue to apply. Failure of the Company to enforce any of the provisions set out in these Terms and Conditions and any Agreement, or failure to exercise any option to terminate, shall not be construed as waiver of such provisions and shall not affect the validity of these Terms and Conditions or of any Agreement or any part thereof, or the right thereafter to enforce each and every provision.</p>

        <h4 style={styles.minorTitle}>Notification of Changes</h4>
        <p>The Company reserves the right to change these conditions from time to time as it sees fit, and your continued use of any application and/or website will signify your acceptance of any adjustment to these terms. You are therefore advised to re-read this statement on a regular basis.</p>

        <h4 style={styles.minorTitle}>Location Data</h4>
        <p>{"The user's location will be captured only if the user has given permission to the application.  This information is not shared with any third party and is used only in connection with the application.  Currently, there are no features that require user location data to be sent to our servers; all location-tracking data stays on the device.  Though the applciation may be tracking a user's location in the background (if given permission from the user), it is comparing that location data to label locations on the device, and no device-location data ever leaves the device."}</p>

        <h4 style={styles.minorTitle}>iOS Users</h4>
        <p>Please refer to <a href='https://www.apple.com/legal/internet-services/itunes/dev/stdeula/'>{"Apple's Standard License Agreement"}</a> for terms of subscriptions through Apple.</p>

        <p style={styles.bold}>These terms and conditions form part of the Agreement between the Client and ourselves. Your accessing of any of our applications and/or websites and/or undertaking of a booking or Agreement indicates your understanding of, agreement to, and acceptance of the Disclaimer Notice and the full Terms and Conditions contained herein. Your statutory Consumer Rights are unaffected.</p>
        <p style={styles.copyright}>
          {'\u00A9'} {dateFnsFormat(new Date(), 'yyyy')} Oakwood Software Consulting, Inc.  All Rights Reserved.
        </p>
      </DialogContent>
      <DialogActions>
        <Button
          key='okay'
          color='primary'
          onClick={() => _close()}
        >
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Legal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default Legal;
