/*
 * Ryan O'Dowd
 * 2021-03-06
 * © Copyright 2021 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */

const styles = {
  links: {
    display: 'flex',
    width: 279, // provide default width so there's no resizing after image loading
  },
  appStoreLink: {
    display: 'flex',
    alignSelf: 'center',
    width: 135,
    marginRight: 5,
  },
  playStoreLink: {
    display: 'flex',
    alignSelf: 'center',
    width: 135,
    marginLeft: 5,
  },
};

export default styles;
