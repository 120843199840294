/**
 * Ryan O'Dowd
 * 2020-11-29
 * © Copyright 2020 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React, {
  useState,
} from 'react';
import AppleIcon from '@mui/icons-material/Apple';
import CustomAuthForm from './CustomAuthForm';
import EmailIcon from '@mui/icons-material/Email';
import GoogleIcon from '@mui/icons-material/Google';
import {
  Link,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  firebase,
} from '../../../Globals';
import styles from './styles';
// @TODO: when linking, no photoURL, and no update to name

const OakAuth = (props) => {
  const [_errorMessage, setErrorMessage] = useState('');
  const [_emailAuthIsOpen, setEmailAuthIsOpen] = useState(window.location.pathname === '/finish-email-sign-in'); // @TODO: abstract this url to globals (and replace other instances of it as well)

  // @TODO: style this component...add logo and stuff to the dialog to make it really pretty
  return (
    <Dialog
      style={styles.container}
      open={props.isOpen}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      onClose={() => props.onClose()}
    >
      <DialogTitle id='alert-dialog-title'>
        Sign in
      </DialogTitle>
      <DialogContent style={styles.innerContainer}>
        {!props.skipDescription &&
          <div style={styles.description}>
            <span style={styles.descriptionLine}>Are you managing a fundraising campaign through Wrinkly?  Welcome back!  Please sign in below.</span>{/* @TODO: dynamic for tibbits, too */}
            <span style={styles.descriptionLine}>If you would like to start a campaign with Wrinkly, please <Link to='/fundraising#contact'>contact us</Link> to establish your account.</span>{/* @TODO: dynamic for tibbits too */}
          </div>
        }
        <div style={styles.signInOptionsWrapper}>
          <span
            style={styles.providerButton}
            onClick={() => {
              const provider = new firebase.auth.GoogleAuthProvider();
              firebase.auth().signInWithPopup(provider).then((result) => props.onClose());
            }}
          >
            <GoogleIcon style={styles.buttonIcon}/> Sign in with Google
          </span>
          <span
            style={styles.providerButton}
            onClick={() => {
              const provider = new firebase.auth.OAuthProvider('apple.com');
              provider.addScope('email');
              provider.addScope('name');
              firebase.auth().signInWithPopup(provider).then((result) => props.onClose());
            }}
          >
            <AppleIcon style={styles.buttonIcon}/> Sign in with Apple{/* @TODO: implement */}
          </span>
          {/* @TODO:
          <span
            color='primary'
            variant='contained'
            autoFocus={false}
            onClick={() => {
              const provider = new firebase.auth.FacebookAuthProvider();
              provider.addScope('public_profile');
              provider.addScope('email');
              // @TODO: if on mobile, use redirect instead of popup: https://firebase.google.com/docs/auth/web/facebook-signin
              firebase.auth().signInWithPopup(provider).then((result) => props.onClose()); // @TODO: this is incredibly slow...at least on localhost
            }}
          >
            Facebook
          </span>
          */}
          <span
            style={styles.providerButton}
            onClick={() => setEmailAuthIsOpen(true)}
          >
            <EmailIcon style={styles.buttonIcon}/> Sign in with Email
          </span>
        </div>
        {_emailAuthIsOpen &&
          <CustomAuthForm
            onCancel={() => {
              setEmailAuthIsOpen(false);
              props.onClose();
            }}
          />
        }
        <div style={styles.lowerContentWrapper}>
          <div style={styles.formContainer}>
            {!!_errorMessage &&
              <p style={styles.errorText}>
                {_errorMessage}
              </p>
            }
          </div>
        </div>
        {!_emailAuthIsOpen &&
          <div key='disclaimer' style={{...styles.innerContainer, ...styles.disclaimerWrapper}}>
            <p style={styles.disclaimerText}>
              We respect your privacy.  Linking an account is used for authentication purposes and not for remarketing of any kind.
            </p>
            <a
              style={styles.disclaimerText}
              href='https://oakwoodsc.com/#terms_and_privacy'
            >
              {'Privacy & Terms of Service'}
            </a>
          </div>
        }
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'
          onClick={() => props.onClose()}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

OakAuth.propTypes = {
  appName: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,

  skipDescription: PropTypes.bool,
};

export default OakAuth;
