/*
 * Ryan O'Dowd
 * 2022-04-18
 * © Copyright 2022 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../../Globals';

const styles = {
  container: {
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    justifyContent: 'flex-start',
    textAlign: 'left',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  formField: {
    flex: 1,
    margin: 4,
    marginLeft: 0,
    // @TODO: text in these fields should be Globals.colors.primaryExtraDark to match the rest of the text on the landing page, but for some reason this doesn't work with mui/material: color: 'red',
  },
  thanksText: {
    color: Globals.colors.primary,
  },
};

export default styles;
