/*
 * Ryan O'Dowd
 * 2021-06-05
 *
 * © Copyright 2022 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */

// https://stackoverflow.com/questions/6511542/force-javascript-exception-error-when-reading-an-undefined-object-property
const oakGlobalObject = (obj) => {
  try {
    if (document && document.documentMode) {
      // this is Internet Explorer, and it doesn't support Proxy, and Proxy can't be pollyfilled by Babel
      return obj;
    }

    const handler = {
      get(target, key) {
        if (key in target || key === '$$typeof') { // @TODO: confused why the $$typeof check was needed all of a sudden?
          return target[key];
        }

        throw new Error(`Property '${key}' is not defined on this object`);
      },
      set(target, key, value, receiver) {
        throw new Error('This object is immutable');
      },
    };
    return new Proxy(obj, handler);
  } catch (error) {
    // probably native and not web
    return obj;
  }
};

export default oakGlobalObject;
