/*
 * Ryan O'Dowd
 * 2023-04-07
 * © Copyright 2023 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Footer from '../../common/components/OakFooter';
import Header from '../../components/Header';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const WebPageStub = (props) => {
  return (
    <div style={styles.outerContainer}>
      <Header />
      <div style={styles.container}>
        {props.children}
      </div>
      <Footer />
    </div>
  );
};

WebPageStub.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default WebPageStub;
