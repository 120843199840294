/*
 * Ryan O'Dowd
 * 2019-05-14
 * © Copyright 2020 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../../Globals';

const styles = {
  outerContainer: { // @TODO: abstract with home
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    flex: 1,
    width: '100%',
    minHeight: 'calc(100vh - 56px - 35px - 114px)', // @TODO: header and footer heights; check mobile; don't hardcode; no idea what the 114px is
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 1000,
    margin: 'auto',
    marginBottom: 64,
  },
  tutorialStep: {
    marginBottom: 64,
  },
  sectionHeader: {
    margin: 50,
  },
  image: {
    width: 310,
    height: 309,
    objectFit: 'cover',
    objectPosition: 'top',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  imageFade: {
    display: 'block',
    position: 'relative',
    backgroundImage: `linear-gradient(
      rgba(255, 255, 255, 0.0) 0%,
      rgba(255, 255, 255, 0.0) 70%,
      rgba(255, 255, 255, 0.7) 80%,
      rgba(255, 255, 255, 0.9) 90%,
      rgba(255, 255, 255, 1.0) 100%
    )`,
    marginTop: -150,
    height: 150,
    width: '100%',
  },
  bigHr: {
    border: `1px solid ${Globals.colors.accent}`,
    width: '75%',
    maxWidth: 350,
  },
  smallHr: {
    border: `1px solid ${Globals.colors.accent}`,
    width: '35%',
    maxWidth: 270,
  },
  dowloadLinks: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 64,
  },
  contactWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingLeft: 8,
    paddingRight: 8,
  },
  contactWrapperLeft: {
    display: 'flex',
    justifyContent: 'center',
  },
  contactText: {
    textAlign: 'center',
  },
  contactDowloadLinks: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    marginTop: 32,
    marginBottom: 32,
  },
};

export default styles;
