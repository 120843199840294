/*
* Ryan O'Dowd
* 2019-06-08
* © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
*/
import PlatformGlobals from './PlatformGlobals';
import React from 'react';
import oakGlobalObject from './common/utilities/oakGlobalObject';

export const platform = PlatformGlobals.platform;
export const channelIds = PlatformGlobals.channelIds;

let webRootUrl = '';
let FIREBASE_CONFIG = {
  apiKey: 'AIzaSyD_8ScohVExZPVInwOIa4O7PQu7Wzxj0IA',
  authDomain: 'tibbits-debug.firebaseapp.com',
  databaseURL: 'https://tibbits-debug.firebaseio.com',
  projectId: 'tibbits-debug',
  storageBucket: 'tibbits-debug.appspot.com',
  messagingSenderId: '561397179831',
  appId: '1:561397179831:web:6431d35c5b544db740b432',
  measurementId: 'G-SSGJ5Y15DP',
};
let IOS_CLIENT_ID = '';
let WEB_CLIENT_ID = '';
let BUNDLE_ID = '';
let APPLE_REDIRECT_URI = 'https://tibbits-debug.firebaseapp.com/__/auth/handler';
let PLACES_API_KEY = 'AIzaSyCqvo0cEGcf4Z0NgvBFv_cfz2uYwgUGRAY';
if (PlatformGlobals.env === 'local') {
  webRootUrl = 'http://192.168.1.165:3003';
  IOS_CLIENT_ID = '561397179831-u0e0igtfbcb684mtdbsg3jvt2gnlhinn.apps.googleusercontent.com';
  WEB_CLIENT_ID = '561397179831-m1gjdfsvqdkcit843n4t9ilepljh2mvr.apps.googleusercontent.com';
  BUNDLE_ID = 'com.oakwoodsc.tibbits.dev';
} else if (PlatformGlobals.env === 'dev') {
  webRootUrl = 'https://dev.tibbits.me';
  IOS_CLIENT_ID = '561397179831-u0e0igtfbcb684mtdbsg3jvt2gnlhinn.apps.googleusercontent.com';
  WEB_CLIENT_ID = '561397179831-m1gjdfsvqdkcit843n4t9ilepljh2mvr.apps.googleusercontent.com';
  BUNDLE_ID = 'com.oakwoodsc.tibbits.dev';
} else if (PlatformGlobals.env === 'prod') {
  webRootUrl = 'https://tibbits.me';
  FIREBASE_CONFIG = {
    apiKey: 'AIzaSyCWR_zvCCThg5ZDzkFI_lURngGzx7wVGt0',
    authDomain: 'names-765ec.firebaseapp.com',
    databaseURL: 'https://names-765ec.firebaseio.com',
    projectId: 'names-765ec',
    storageBucket: 'names-765ec.appspot.com',
    messagingSenderId: '851458489988',
    appId: '1:851458489988:web:d79f55464c6747cce3ccd1',
    measurementId: 'G-TRF7MY3Q3E',
  };
  IOS_CLIENT_ID = '851458489988-3sbr0k28hervb2s7ge03ul1p9s6tbcil.apps.googleusercontent.com';
  WEB_CLIENT_ID = '851458489988-3dtn0l366uo2v9ut3a8gp9kdnkqd63bh.apps.googleusercontent.com';
  BUNDLE_ID = 'com.oakwoodsc.tibbits';
  APPLE_REDIRECT_URI = 'https://names-765ec.firebaseapp.com/__/auth/handler';
  PLACES_API_KEY = 'AIzaSyC_2NtDcUBffPCZpiNcVoP765BsK5_bV7o';
}

let apiUrl = 'http://localhost:5020'; // local env for web only
if (PlatformGlobals.platform === 'android') {
  apiUrl = 'http://10.0.2.2:5020';
}
if (PlatformGlobals.platform === 'ios') {
  apiUrl = 'http://192.168.1.166:5020'; // @TODO: home
  // @TODO: apiUrl = 'http://10.0.1.103:5020'; // @TODO: work
}
if (PlatformGlobals.env === 'prod') {
  apiUrl = 'https://api.tibbits.me';
}

export const PERIODIC_REMINDER_HOURS_OFFSET = 72;

export const firebase = PlatformGlobals.importedFirebase;

const Globals = {
  env: PlatformGlobals.env,
  platform: PlatformGlobals.platform,
  appVersionNumber: PlatformGlobals.appVersionNumber,
  appName: 'tibbits',
  apiUrl,

  webRootUrl,

  colors: oakGlobalObject({
    primary: '#01579b',
    primaryDark: '#0d47a1',
    primaryExtraDark: '#0d2791', // @TODO: update
    primaryLight: '#03A9F4',
    primaryExtraLight: '#a3d9F4', // @TODO: update
    accent: '#366974',
    accentDark: '#0e3f4a',
    accentLight: '#76c0b8',
    alert: '#FF4242',
    alertLight: '#FF9292',
    alertDark: '#cF2222',
    white: '#ffffff',
    black: '#000000',
    dark: '#222222',
    gray: '#757575',
    lightGray: '#cccccc',
    disabled: '#bbbbbb',
    bgLight: '#EEEEEE',
    bgDark: '#1b1b1b',

    subscriptionStatus: oakGlobalObject({
      active: '#0a0',
      freeTrial: '#aa0',
      canceled: '#a00',
      premiumGold: '#d4af37',
    }),

    lightTheme: oakGlobalObject({
      text: '#212121',
      invertedText: '#ffffff',
      background: '#ffffff',
      appBackground: '#eeeeee',
      primaryText: '#01579b', // @TODO: update
      accentText: '#366974', // @TODO: update
      sectionHeaderBackground: '#0d47a1dd',
      searchBarWrapperBackground: '#01579b',
    }),
    darkTheme: oakGlobalObject({
      text: '#EEEEEE',
      invertedText: '#212121',
      background: '#242424',
      appBackground: '#1b1b1b',
      primaryText: '#03A9F4',
      accentText: '#89a7ae',
      sectionHeaderBackground: '#0d2791dd',
      searchBarWrapperBackground: '#0d47a1',
    }),
  }),

  themePrimary: {
    50: '#e1ebf3',
    100: '#b3cde1',
    200: '#80abcd',
    300: '#4d89b9',
    400: '#2770aa',
    500: '#01579b',
    600: '#014f93',
    700: '#014689',
    800: '#013c7f',
    900: '#002c6d',
    A100: '#9cbbff',
    A200: '#6998ff',
    A400: '#3674ff',
    A700: '#1d63ff',
    contrastDefaultColor: 'light',
  },
  themeAccent: {
    A100: '#b8cace',
    A200: '#89a7ae',
    A400: '#366974',
    A700: '#0e3f4a',
    contrastDefaultColor: 'light',
  },
  themeError: {
    50: '#ffeaea',
    100: '#ffcbcb',
    200: '#ffa9a9',
    300: '#ff8686',
    400: '#ff6c6c',
    500: '#ff5252',
    600: '#ff4b4b',
    700: '#ff4141',
    800: '#ff3838',
    900: '#ff2828',
    A100: '#ffffff',
    A200: '#ffffff',
    A400: '#ffd5d5',
    A700: '#ffbcbc',
    contrastDefaultColor: 'dark',
  },

  fontFamilies: {
    primary: platform === 'web' ? 'Lato' : 'Lato-Regular',
    secondary: platform === 'web' ? 'Montserrat' : 'Montserrat-Bold',
  },

  labels: {
    maxLength: 32,
  },

  appStoreUrl: 'https://itunes.apple.com/us/app/tibbits/id1332548860?mt=8&ls=1',
  playStoreUrl: 'https://play.google.com/store/apps/details?id=com.oakwoodsc.tibbits',
  iosClientId: IOS_CLIENT_ID,
  webClientId: WEB_CLIENT_ID,
  bundleId: BUNDLE_ID,
  appleRedirectUri: APPLE_REDIRECT_URI,
  placesApiKey: PLACES_API_KEY,

  isMobile: window.innerWidth <= 960,
  mediaQueries: oakGlobalObject({
    xs: '@media (max-width: 600px)',
    sm: '@media (max-width: 960px)',
    md: '@media (max-width: 1280px)',
    lg: '@media (max-width: 1920px)',
    // xl > 1920
  }),

  FIREBASE_DATE_FORMAT: 'yyyy-MM-dd',
  FIREBASE_TIME_FORMAT: 'HH:mm:ss',

  revenueCatApiKey: platform === 'ios' ? 'appl_vlcTHkDRLGzDvIlBGXEROjjaCWH' : 'goog_hEeojSkmevpXNTnDEMnGKSdyAap',
  profilePictureSize: 1000,
  tidbitPictureSize: 500,

  appContext: React.createContext(),

  labelLocationRadiusMeters: 200,

  supportedLocales: ['en'],

  supportEmail: 'support@tibbits.me',

  apps: {
    tibbits: {
      name: 'Tibbits',
      ios: {
        storeUrl: 'https://itunes.apple.com/us/app/id1332548860?mt=8&ls=1',
      },
      android: {
        storeUrl: 'https://play.google.com/store/apps/details?id=com.oakwoodsc.tibbits',
      },
      websiteUrl: 'https://tibbits.oakwoodsc.com',
    },
    wrinkly: {
      name: 'Wrinkly',
      ios: {
        storeUrl: 'https://itunes.apple.com/us/app/id1615885499?mt=8&ls=1',
      },
      android: {
        storeUrl: 'https://play.google.com/store/apps/details?id=com.oakwoodsc.wrinkly',
      },
      websiteUrl: 'https://wrink.ly',
    },
  },

  fontScales: {
    small: 0.8,
    medium: 1.0,
    large: 1.4,
    extraLarge: 1.8,
  },
};

if (!firebase.apps.length) { // @TODO: should this be for web only? i think so...
  // make sure app is initialized exactly once (instead of every time Globals is imported?)
  firebase.initializeApp(FIREBASE_CONFIG);
}
class Firestore {
  setUser(userId) {
    this.userDoc = firebase.firestore().doc(`environments/prod/users/${userId}`);
    this.peopleCollection = this.userDoc.collection('people');
    this.labelsCollection = this.userDoc.collection('labels');
    this.tidbitsCollection = this.userDoc.collection('tidbits');
    this.remindersCollection = this.userDoc.collection('reminders');
    this.notificationsCollection = this.userDoc.collection('notifications');
    this.nameTagsCollection = this.userDoc.collection('nameTags');
    this.pendingPeopleCollection = this.userDoc.collection('pendingPeople');
    this.userEventsCollection = this.userDoc.collection('events');

    this.whatsNewCollection = firebase.firestore().collection('whatsNew');
    this.eventsCollection = firebase.firestore().collection('events');
  }
}
export const firestore = new Firestore();
export default Globals;
