/*
 * Ryan O'Dowd
 * 2023-04-07
 * © Copyright 2023 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  outerContainer: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    flex: 1,
    width: '100%',
    minHeight: 'calc(100vh - 88px - 48px - 44px)', // @TODO: header and footer heights; check mobile; don't hardcode
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    // @TODO: marginBottom: 64,
    textAlign: 'left',
    color: Globals.colors.white,
  },
};

export default styles;
