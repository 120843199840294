/*
 * Ryan O'Dowd
 * 2020-12-31
 * © Copyright 2019 NursingABC, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  container: {
    margin: 2,
    color: Globals.colors.white,
    backgroundColor: Globals.colors.accent,
  },
};

export default styles;
