/*
 * Ryan O'Dowd
 * 2021-01-03
 * © Copyright 2020 NursingABC, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const USER_IMAGE_HEIGHT = 64;

const styles = {
  dialog: {
    textAlign: 'left',
  },
  firstHeader: {
    marginTop: 0,
  },
  headerText: {
    color: Globals.colors.primary,
  },
  imageTextWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'space-between',
    marginBottom: 12,
  },
  leftBorder: {
    borderLeft: `4px solid ${Globals.colors.primary}`,
  },
  rightBorder: {
    borderRight: `4px solid ${Globals.colors.primary}`,
  },
  logo: {
    width: 100,
    height: 100,
    alignSelf: 'center',
    marginRight: 16,
    marginLeft: 16,
  },
  userImageWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  developerText: {
    display: 'flex',
    alignItems: 'center',
  },
  userImage: {
    width: USER_IMAGE_HEIGHT,
    height: USER_IMAGE_HEIGHT,
    borderRadius: USER_IMAGE_HEIGHT / 2,
    overflow: 'hidden',
    margin: 8,
  },
  otherInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
};

export default styles;
