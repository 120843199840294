/**
 * Ryan O'Dowd
 * 2020-12-29
 * © Copyright 2020 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import Globals, {
  firebase,
} from '../../../../Globals';
import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
// @TODO: import firebase from 'firebase/compat/app';
import styles from './styles';

const unknownError = 'An unknown error occurred; please try again.';
const networkError = 'Network error; please ensure you are connected to the internet and try again.';
const invalidEmailError = 'Invalid email address';
const alreadyUsedEmailError = 'Email address already in use; please try again with the authentication provider you used to create your account.'; // @TODO: this should be automatic instead of an error message

const CustomAuthForm = (props) => {
  const [_emailText, setEmailText] = useState('');
  // @TODO: const [_firstNameText, setFirstNameText] = useState(props.signInInfo ? props.signInInfo.firstName : '');
  // @TODO: const [_lastNameText, setLastNameText] = useState(props.signInInfo ? props.signInInfo.lastName : '');
  const [_linkSentSuccessfully, setLinkSentSuccessfully] = useState(false);
  const [_emailTextFieldError, setEmailTextFieldError] = useState(null);

  useEffect(() => {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      const email = window.localStorage.getItem('emailForSignIn') || window.prompt('Please confirm your email address.'); // eslint-disable-line no-alert
      firebase.auth().signInWithEmailLink(email, window.location.href).then((result) => {
        // @TODO: const firstName = window.localStorage.getItem('firstNameForSignIn') || window.prompt('Please confirm your first name.'); // eslint-disable-line no-alert
        // @TODO: const lastName = window.localStorage.getItem('lastNameForSignIn') || window.prompt('Please confirm your last name.'); // eslint-disable-line no-alert
        firebase.analytics().logEvent('login', { // @TODO: abstract with other calls to robots
          // @TODO: displayName: `${firstName} ${lastName}`,
          email,
          authType: 'email',
        });
        window.localStorage.removeItem('emailForSignIn');
        // @TODO: window.localStorage.removeItem('firstNameForSignIn');
        // @TODO: window.localStorage.removeItem('lastNameForSignIn');
        props.onCancel();
        // redirect to after successful log in
        window.history.replaceState({}, null, '/campaigns'); // @TODO: location.state.from (from useLocation().state) // @TODO: doesn't open to correct page // @TODO: this is wrinkly only...make work for tibbits too
      }).catch((error) => {
        console.error(error); // eslint-disable-line no-console
        // @TODO: better error handing
        // @TODO: error.message should be safe to show user?
      });

      props.onCancel();
    }
  }, [props]);

  const onLoginPress = () => {
    // @TODO: if (!_firstNameText || !_lastNameText || !_emailText) {
    if (!_emailText) {
      // @TODO: prettier implementation than alert
      setEmailTextFieldError('Please enter email address.');
      return;
    }

    const actionCodeSettings = {
      url: `${Globals.webUrl}/finish-email-sign-in`, // @TODO: enum
      handleCodeInApp: true, // must always be true for sendSignInLinkToEmail
      iOS: {
        bundleId: Globals.bundleId,
      },
      android: {
        packageName: Globals.bundleId,
        installApp: true,
      },
    };

    firebase.auth().sendSignInLinkToEmail(_emailText, actionCodeSettings).then(() => {
      window.localStorage.setItem('emailForSignIn', _emailText);
      // @TODO: window.localStorage.setItem('firstNameForSignIn', _firstNameText);
      // @TODO: window.localStorage.setItem('lastNameForSignIn', _lastNameText);
      setLinkSentSuccessfully(true);
    }).catch((error) => {
      let errorMessage = '';
      switch (error.code) {
      case 'auth/network-request-failed': {
        errorMessage = networkError;
        break;
      }
      case 'auth/account-exists-with-different-credential': {
        errorMessage = alreadyUsedEmailError;
        break;
      }
      case 'auth/invalid-email': {
        errorMessage = invalidEmailError;
        break;
      }
      case 'auth/argument-error':
      case 'auth/operation-not-allowed':
      case 'auth/missing-android-pkg-name':
      case 'auth/missing-continue-uri':
      case 'auth/missing-ios-bundle-id':
      case 'auth/invalid-continue-uri':
      case 'auth/unauthorized-continue-uri':
      default:
        // user doesn't care about these errors
        errorMessage = unknownError;
        // @TODO: set up error handling for oakwood...this is a big deal if the error is here
        console.error(error); // eslint-disable-line no-console
      }
      // @TODO: implement
      console.error(errorMessage); // eslint-disable-line no-console
    });
  };

  return (
    <Dialog
      open={true}
      style={styles.container}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      // @TODO: fullScreen={Globals.isMobile}
      onClose={() => props.onCancel()}
    >
      <DialogTitle id='alert-dialog-title'>
        Sign in with email address
      </DialogTitle>
      <DialogContent>
        {_linkSentSuccessfully
          ? (
            `Email sent to ${_emailText}.  You will be signed in automatically upon opening the link in your email inbox in this browser.` // @TODO: better text
          ) : (
            <React.Fragment>
              {/* @TODO: better text here...abstract with mobile */}
              {"Please enter email below, and we'll send a sign-in link to your inbox."}
              <div style={styles.nameInputWrapper}>
                {/* @TODO:
                <TextField
                  style={styles.textInput}
                  onChange={(e) => setFirstNameText(e.target.value)}
                  value={_firstNameText}
                  placeholder='First name'
                  variant='standard'
                />
                <TextField
                  style={styles.textInput}
                  onChange={(e) => setLastNameText: e.target.value)}
                  value={_lastNameText}
                  placeholder='Last name'
                  variant='standard'
                />
                */}
                <TextField
                  style={styles.textInput}
                  error={!!_emailTextFieldError}
                  required={true}
                  onChange={(e) => {
                    setEmailTextFieldError(null);
                    setEmailText(e.target.value);
                  }}
                  value={_emailText}
                  placeholder='Email address'
                  variant='standard'
                  helperText={_emailTextFieldError}
                />
              </div>
            </React.Fragment>
          )
        }
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'
          variant='text'
          onClick={() => props.onCancel()}
        >
          Back
        </Button>
        <Button
          color='primary'
          variant='contained'
          disabled={_linkSentSuccessfully}
          onClick={() => onLoginPress()}
        >
          Send Login Link
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CustomAuthForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  // @TODO: signInInfo: PropTypes.object.isRequired,
};

export default CustomAuthForm;
