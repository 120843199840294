/*
 * Ryan O'Dowd
 * 2021-12-16
 * © Copyright 2021 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    maxWidth: 1000,
    padding: 16,
    marginBottom: 64,
    backgroundColor: Globals.colors.bgLight,
  },
  dialog: {
    textAlign: 'left',
  },
  eventMetadata: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    marginBottom: 16,
  },
  eventLocation: {
    color: Globals.colors.primary,
    fontSize: 14,
    margin: 0,
  },
  eventDates: {
    fontSize: 12,
    color: Globals.colors.gray,
  },
  sectionHeader: {
    color: Globals.colors.primary,
    fontSize: 22,
    marginBottom: 8,
  },
  sessions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
  },
  session: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textAlign: 'left',
    width: '95%', // @TODO: why doesn't 100% work?
    backgroundColor: Globals.colors.white,
    marginBottom: 8,
    padding: 8,
  },
  sessionName: {
    fontWeight: '800',
    fontSize: 20,
    color: Globals.colors.primary,
  },
  row: {
    marginBottom: 8,
  },
  sessionLocation: {
    fontSize: 14,
    color: Globals.colors.accent,
  },
  speakerName: {
    fontSize: 14,
    color: Globals.colors.primaryLight,
    marginLeft: 8,
  },
  sessionTimes: {
    fontSize: 14,
    color: Globals.colors.gray,
  },
};

export default styles;
